import { Component, EventEmitter, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FileUploader } from "ng2-file-upload";
import { Subject } from "rxjs/index";
import { DocumentService } from "../../services/document.service";
import { ToastrService } from "ngx-toastr";
import { takeUntil } from "rxjs/internal/operators";
import { IdBadgeService } from "../../services/id-badge.service";
import { QuestionModalService } from 'src/app/services/question-modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var FB;

@Component({
  selector: 'app-id-badges-modal',
  templateUrl: './id-badges-modal.component.html',
  styleUrls: ['./id-badges-modal.component.css']
})
export class IdBadgesModalComponent implements OnInit, OnDestroy {
  @Input() detailJob:any;
  textDir: string = 'ltr';
  modal1 = false;
  modal2 = false;
  modal3 = false;
  uploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    allowedFileType: ['pdf', 'image']

  });
   uploader_img: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    allowedFileType: ['image']

  });
  uploader_img_back: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    allowedFileType: ['image']

  });
  loader = false;
  file_error: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  badges: any;
  id_number: any;
  id_number_url: any;

  id_number_2: any;

  idBadge:any;
  certificate:any;
  certId: any;
  nationalId:any;
  fbtoken: any = { 'token': '' };
info:any;
userInfo:any;
userData:any;
  constructor(private renderer: Renderer2, private documentService: DocumentService, private toastr: ToastrService,
    private badgeService: IdBadgeService,private questionModalService: QuestionModalService,
    private activeModal:NgbActiveModal) {
      if(localStorage.getItem('aydoLang')) {
     
        if(localStorage.getItem('aydoLang') == 'ar') {
          this.textDir = 'rtl'
        }
        else {
          this.textDir = 'ltr'
        }
      }
    this.renderer.addClass(document.body, 'skills');
  }

  ngOnInit() {
    this.userInfo = localStorage.getItem('aydo-app-user');
    this.userData = JSON.parse(this.userInfo);
    (window as any).fbAsyncInit = function () {

      FB.init({
        appId: '720665841709645',
        cookie: true,
        xfbml: true,
        version: 'v4.0'
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    this.modal1 = true;
    this.getIdBadges();
  }

  fbConnect() {
    // FB.login();

    FB.login((response) => {
      if (response.authResponse) {
        // console.log(response);
        this.fbtoken.token = response.authResponse.accessToken;
        this.badgeService.fbConnect(this.fbtoken).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          data => {
            this.toastr.success('Connected');
            this.getIdBadges();
          },
          error => {
            this.toastr.error(error.error.errors);
          }
        );
      }
      else {
        this.toastr.error('Can not connect');
      }
    });
  }
  disconnectConnection(field: any) {
    if (field === 'fb') {
       this.info = {
        disconnect_facebook: true
      }
    }
    if (field ==='link') {
      this.info = {
        disconnect_linkedin: true
      }
    }
    if (field ==='twitter') {
      this.info = {
        disconnect_twitter: true
      }
    }
    this.badgeService.removeAccount(this.info).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toastr.success('Disconnected');
        this.getIdBadges();
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }
  disconnectLinked() {

  }
  getIdBadges() {
    this.loader = true;
    this.badgeService.getIdBadges().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.badges = res;
      if (this.badges.emirates_id_image != null) {
        this.id_number = this.badges.emirates_id_image;
        this.id_number_url = this.id_number.url;
      }
      if (this.badges.emirates_id_image_back != null) {
        this.id_number_2 = this.badges.emirates_id_image_back;
      }
      if (this.badges.police_certificate != null) {
        this.certId = this.badges.police_certificate;
      }
      this.loader = false;
    }
    );
  }
  removeIdImage(type) {
    if(type == 'id_number'){
      this.id_number = null;
    }
    if(type == 'id_number_2'){
      this.id_number_2 = null;
    }
  }
  connectTwitter() {
    // this.badgeService.twitterConnect().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any) => {
    //   console.log(res);
    // })
    window.open('https://stg-api.aydo.app/api/profile_verifications/twitter_web?user_id='
      + this.userData.id , '_blank');
  }
  frontImage() {
    window.open(this.id_number_url, '_blank');
  }
  applyVerf() {
    const idNumber = {
      emirates_id_image: this.id_number.id,
      emirates_id_image_back: this.id_number_2.id
    };
    this.badgeService.putIdNumber(idNumber).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.toastr.success('Success');
      this.modal1 = true;
      this.modal2 = false;
      this.getIdBadges();
      this.sendService();

    },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  applyCert() {
    const idNumber = {
      police_certificate_id: this.certId.id
    };
    this.badgeService.policeCert(idNumber).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.toastr.success('Success');
      this.modal1 = true;
      this.modal3 = false;
      this.getIdBadges();
      this.sendService();

    },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }
  sendService(): void {
    if(this.detailJob == 'detail-job') {
     this.activeModal.dismiss('Cross click');

    }
  
    
  }
  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    if (file.type == 'application/pdf' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
    this.loader = true;
    this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.loader = false;
        this.certId = res;
      }, error => {
        this.toastr.error(error.error.errors);
      });
    }
    else {
      this.loader = false;
      this.toastr.error('Please Upload Only Document in pdf and image format');

    }
  }
  public onFileSelectedNationalImg(event: EventEmitter<File[]>) {
    const file: File = event[0];
    if (file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
    this.loader = true;
    this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.loader = false;
        this.id_number = res;
      }, error => {
        this.toastr.error(error.error.errors);
      });
    }
    else {
      this.loader = false;
      this.toastr.error('Please Upload Only Image');

    }
  }
  public onFileSelectedNationalBackImg(event: EventEmitter<File[]>) {
    const file: File = event[0];
    if (file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
    this.loader = true;
    this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.loader = false;
        this.id_number_2 = res;
      }, error => {
        this.toastr.error(error.error.errors);
      });
    }
    else {
      this.loader = false;
      this.toastr.error('Please Upload Only Image');

    }
  }
  idFunc() {
    this.modal1 = false;
    this.modal2 = true;
  }

  policeCheck() {
    this.modal1 = false;
    this.modal3 = true;
  }

  backList() {
    this.modal2 = false;
    this.modal1 = true;
  }

  backListPolice() {
    this.modal1 = true;
    this.modal3 = false;
  }
  linkdIn() {
    window.open('https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86je0nhtjzxvjx&redirect_uri=https://aydo.app/verification&state=eMgDO0HJHqgw0BSlw3LR&scope=r_liteprofile r_emailaddress', '_blank');
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'skills');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
