import { Component, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs/index';
import { takeUntil } from 'rxjs/internal/operators';
import { WalletService } from '../../services/wallet.service';
import { ToastrService } from 'ngx-toastr';
import { JobService } from "../../services/job.service";
import { PaymentModalService } from "../../services/payment-modal.service";
import { NgbActiveModal, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import * as braintree from 'braintree-web';
import * as paypal from 'braintree-web';
import { StripeModalComponent } from '../stripe-modal/stripe-modal.component';
declare var gtag

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    windowClass:'job-modal-questions'
  };
  modal1 = false;
  modal2 = false;
  modal3 = false;
  modal4 = false;
  modal5 = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  methodList: any[] = [];
  loader: any;
  p_name: any;
  p_email: any;
  method: any;
  getMethod: any;
  method_id: any;
  selectMethod: any;
  paymentType: any;
  @Input() fromDetail: any;
  clientToken: any;
  braintreeIsReady: boolean;
  dropIninstance: any;
  withDrawalDetail:any;
  textDir: string = 'ltr';
  currency = 'USD';
  getCurrency:any;
  selectedCurrency:any;
  paytabs_token_amount:any;
  userData:any;
  user_info:any;
  constructor(private renderer: Renderer2, private walletService: WalletService, private toastr: ToastrService,
    private jobService: JobService, private paymentModalService: PaymentModalService, public activeModal: NgbActiveModal,
    private modalService:NgbModal) {
      if(localStorage.getItem('aydoLang')) {
     
        if(localStorage.getItem('aydoLang') == 'ar') {
          this.textDir = 'rtl'
        }
        else {
          this.textDir = 'ltr'
        }
      }
    this.renderer.addClass(document.body, 'payment-method');
    this.paymentModalService.getMessageCard().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      if (data) {
        this.modal2 = false;
        this.methodList = [];
         this.ngOnInit();
      }
    })
  }

  ngOnInit() {
    this.modal1 = true;
    this.get_payment_methods('', '');
    this.getRequest();
    this.user_info = localStorage.getItem('aydo-app-user');
    this.userData = JSON.parse(this.user_info);
    if(this.userData.currency) {
      this.selectedCurrency = this.userData.currency;
      this.checkCurrency();
     
      
    }

  }
  checkCurrency() {
    this.jobService.getAmmountLimit(this.selectedCurrency).subscribe((data:any) => {
     this.paytabs_token_amount = Math.floor(data.paytabs_token_amount);
    })
  }
  getRequest() {
    this.loader = true;
    this.walletService.getWithDrawalRequest().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.withDrawalDetail = data;
      this.loader = false;
    }
    );
  }
  payTabs() {
  
          this.walletService.PayTabs().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            data => {
              const url = data['payment_url'];
              window.location.href = url;
            }, error => {
              this.toastr.error(error.error.errors);
            }
          );
  }
  get_payment_methods(page, time) {
    this.loader = true;
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.walletService.getPaymentMethods(page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.methodList = this.methodList.concat(data['payment_methods']);
      this.loader = false;

    }, error => {
      this.toastr.error(error.error.errors);
    }
    );
  }

  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + 10 + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.get_payment_methods(this.currentPage, this.timeStamp);
      }
    }
  }

  addPaymentMethod() {
    this.p_name = '';
    this.p_email = '';
    this.method = '';
    this.modal1 = false;
    this.modal2 = true;
  }

  openModal3(method) {
   if(method == 'credit') {

   }

  }
  cancelModal(content) {
    this.modalService.open(content,this.ngbModalOptions);
  }
  openModalStripe() {
    this.modalService.open(StripeModalComponent,this.ngbModalOptions);
  }
  savePayPal() {
    const info = {
      method_type: this.method,
      paypal_name: this.p_name,
      paypal_email: this.p_email
    };
    this.walletService.savePayPal(info).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toastr.success('Account has been added');
        this.modal3 = false;
        this.modal1 = true;
        this.methodList = [];
        this.get_payment_methods('', '');
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );

  }

  getSingleMethod(item) {
    this.modal1 = false;
    this.modal4 = true;
    this.getMethod = item;
  }

  backList() {
    this.modal4 = false;
    this.modal1 = true;
  }

  editMethod(item) {
    this.modal4 = false;
    this.modal5 = true;
    this.p_name = item.paypal_name;
    this.p_email = item.paypal_email;
    this.method = item.method_type;
    this.method_id = item.id;
  }

  delMethod(item) {
    this.method_id = item.id;
    this.walletService.deleteMethod(this.method_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.modal4 = false;
        this.modal1 = true;
        this.methodList = [];
        this.get_payment_methods('', '');
        this.toastr.success('Account has been deleted');
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  updatePayPal() {
    const info = {
      method_type: this.method,
      paypal_name: this.p_name,
      paypal_email: this.p_email
    };

    this.walletService.updatePayPal(info, this.method_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toastr.success('Account has been updated');
        this.modal5 = false;
        this.modal1 = true;
        this.methodList = [];
        this.get_payment_methods('', '');
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  pay() {
    gtag('event', 'accept_job', {
      'event_category' : 'Job',
      'event_label' : 'job_accept_job'
    });
    if (this.selectMethod.method_type == 'paypal') {
      this.paymentType = 2;
    }
    if (this.selectMethod.method_type == 'credit') {
      this.paymentType = 1;
    }
    if (this.selectMethod == 'wallet') {
      this.paymentType = 0;
    }
    const assignJob = {
      job_id: this.fromDetail.jobID,
      offer_id: this.fromDetail.offer_id,
      payment_type: this.paymentType,
      payment_id: this.selectMethod.id
    };
    
    this.jobService.assignJob(assignJob).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toastr.success('Job has been assigned');
        this.sendService();
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );

  }

  sendService(): void {
    this.paymentModalService.sendMessage('success');
    this.activeModal.dismiss('Cross click');
  }

  select_method(list) {
    this.selectMethod = list;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'payment-method');
  }
}
