import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  textDir: string = 'ltr';
  constructor(private renderer: Renderer2,
    public translate: TranslateService,private router:Router) {
      translate.addLangs(['en', 'ar']);
      if(localStorage.getItem('aydoLang')) {
        translate.setDefaultLang(localStorage.getItem('aydoLang'));
        // translate.use(localStorage.getItem('aydoLang'));
        if(localStorage.getItem('aydoLang') == 'ar') {
          this.textDir = 'rtl'
        }
        else {
          this.textDir = 'ltr'
        }
      }
      else {
      translate.setDefaultLang('en');
        //  translate.use('en');
         localStorage.setItem("aydoLang","en");
      }
  
      // const browserLang = translate.getBrowserLang();
      // translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
       localStorage.setItem('aydoLang',event.lang);
       if(event.lang == 'ar') {
        this.textDir = 'rtl'
      }
      else {
        this.textDir = 'ltr'
      }
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      let currentUrl = this.router.url;
      this.router.navigate([currentUrl]);
      });
    this.renderer.addClass(document.body, 'dashboard');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'dashboard');
  }
}
