import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild, EventEmitter, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs/index";
import {takeUntil} from "rxjs/internal/operators";
import {ChatService} from "../../../../services/chat.service";
import {ActionCableService, Channel} from 'angular2-actioncable';
import {Subscription} from "rxjs";
import { FileUploader } from 'ng2-file-upload';
import { DocumentService } from 'src/app/services/document.service';
import { environment } from 'src/environments/environment';
import { QuestionModalService } from 'src/app/services/question-modal.service';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.css']
})
export class MessageDetailComponent implements OnInit, AfterViewChecked,OnDestroy {
  templ_1 = false;
  templ_2 = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  convId: any;
  messages: any[] = [];
  newMessage:any;
  detailMessage: any;
  userInfo: any;
  localUserId: any;
  message: any;
  channel: Channel;
  subscription: Subscription;
  userDetail: any;
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  loader = false;
  uploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment'

  });
  avatar_id:any;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  disableScrollDown = false;
  wsUrl = environment.wssUrl;
  lang:any;
  constructor(private route: ActivatedRoute, private routerR: Router, private chatService: ChatService,
              private cableService: ActionCableService,private documentService: DocumentService,private questionModalService:QuestionModalService) {
                this.questionModalService.getMessage().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
                  this.convId = data;
                      this.getAllMessages('','');
       this.getDetailMessage();
       this.templ_1 = false;
       this.templ_2 = true;
       localStorage.removeItem('chatId');
      //    this.sendService();
                });
    // this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
    //   (param: any) => {
    //     this.convId = param['id'];
    //     this.getAllMessages('','');
    //     this.getDetailMessage();
    //     this.sendService();
        
    //   }
    // );
  }

  ngOnInit() {
    if(localStorage.getItem('aydoLang')) {
      this.lang = localStorage.getItem('aydoLang');
       
     }
    this.convId = localStorage.getItem('chatId');
    if(this.convId) {
     this.templ_2 = true;
     this.getAllMessages('','');
     this.getDetailMessage();

    }
    if(!this.convId) {
      this.templ_1 = true;
    }
    this.userInfo = localStorage.getItem('aydo-app-user');
    const user_info = JSON.parse(this.userInfo);
    this.localUserId = user_info.id;
    this.userDetail = localStorage.getItem('Authorization');
    this.channel = this.cableService
    .cable(this.wsUrl +'?token=' + this.userDetail)
      .channel('ConversationChannel');
    this.subscription = this.channel.received().subscribe(message => {
      // this.questionModalService.sendMessage(message);
      this.newMessage = message;
      // this.getAllMessages();
      if(this.convId) {
      if(this.convId == message.conversation_id) {
        this.messages.splice(0,0,message);
      }
      
      // this.getDetailMessage();
      this.disableScrollDown = false;
    }

    });
  }
  sendService(): void {
    this.questionModalService.sendMessage(this.convId);
  }
  sendMessageChat() {
    
if(this.message != null) {
    if(this.message.trim() !== '')
    {
      const message = {
      body: this.message,
      action: 'receive',
      conversation_id: this.convId,
      attachment_id:this.avatar_id
    };
      this.channel.send(message);
    this.message = '';
    this.avatar_id = '';
    }
    }
  

  
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

   onScroll(event) {

    const element = this.myScrollContainer.nativeElement
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight
    const startingScrollHeight = event.target.scrollHeight;

    if(event.target.scrollTop < 100) {
      if (this.moreAvailable === true && !this.loader) {
        this.loader = true;
        setTimeout(() => {
          const newScrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
          
           this.myScrollContainer.nativeElement.scrollTo(0, newScrollHeight - startingScrollHeight);
          //  element.scrollTop = element.clientHeight - 500;
          this.currentPage++;
          this.getAllMessages(this.currentPage,this.timeStamp);

        }, 4000);
      }
    }
    if (this.disableScrollDown && atBottom) {
      this.disableScrollDown = false;
    } else {
      this.disableScrollDown = true;
    }
  }


  private scrollToBottom(): void {
    if (this.disableScrollDown) {
      return;
    }
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

  getAllMessages(page, time) {
    this.loader = true;
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.chatService.getAllMessages(page,time,this.convId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.pagination = data['page_info'];
        this.currentPage = this.pagination.page;
        this.timeStamp = this.pagination.timestamp;
        this.total = new Array(this.pagination.total_pages);
        this.totalPages = this.pagination.total_pages;
        this.moreAvailable = this.pagination.more_available;
       
        if(this.currentPage === 1) {
          this.messages = data['messages'];
        }
        if(this.currentPage > 1) {
          // this.messages = this.messages.concat(data['messages']);
          this.messages = this.messages.concat(data['messages']);
        }
    
        this.loader = false;
    
      }
    );
  }

  getDetailMessage() {
    this.chatService.getDetailMessage(this.convId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.detailMessage = data;
      }
    );
  }
  public onFileSelected(event: EventEmitter<File[]>) {
    this.loader = true;
    const file: File = event[0];
    if (file.type === 'image/jpg' || file.type === 'image/jpeg') {
      this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          this.loader = false;
          this.avatar_id = res['id'];
          this.message = '';
          const message = {
            body: this.message,
            action: 'receive',
            conversation_id: this.convId,
            attachment_id:this.avatar_id
          };
            this.channel.send(message);
          this.message = '';
          this.avatar_id = '';

        }, error => {
          console.log('changePicture error', error);
        });
      }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    localStorage.removeItem('chatId');
  }
}
