import {Component, EventEmitter, Injectable, OnDestroy, OnInit, ViewChild, TemplateRef, Renderer2, ChangeDetectorRef, AfterContentChecked, AfterViewInit} from '@angular/core';
import {CategoryModalComponent} from "../category-modal/category-modal.component";
import {NgbModal, NgbModalOptions, NgbTimeAdapter, NgbTimeStruct, ModalDismissReasons} from "@ng-bootstrap/ng-bootstrap";
import {CategoryModalService} from "../../../services/category-modal.service";
import {Subject} from "rxjs/index";
import {takeUntil} from "rxjs/internal/operators";
import {LocationModalComponent} from "../../location-modal/location-modal.component";
import {LocationService} from "../../../services/location.service";
import {FileUploader} from "ng2-file-upload";
import {DocumentService} from "../../../services/document.service";
import {JobService} from "../../../services/job.service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import { IdBadgeService } from 'src/app/services/id-badge.service';
import { IdBadgesModalComponent } from '../../id-badges-modal/id-badges-modal.component';
declare var gtag
// @Injectable()
// export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

//   fromModel(value: string): NgbTimeStruct {
//     if (!value) {
//       return null;
//     }
//     const split = value.split(':');
//     return {
//       hour: parseInt(split[0], 10),
//       minute: parseInt(split[1], 10),
//       second: parseInt(split[2], 10)
//     };
//   }

//   toModel(time: NgbTimeStruct): string {
//     if (!time) {
//       return null;
//     }
//     return `${this.pad(time.hour)}:${this.pad(time.minute)}:${this.pad(time.second)}`;
//   }

//   private pad(i: number): string {
//     return i < 10 ? `0${i}` : `${i}`;
//   }
// }

@Component({
  selector: 'app-post-job',
  templateUrl: './post-job.component.html',
  styleUrls: ['./post-job.component.css']
})
export class PostJobComponent implements OnInit, OnDestroy,AfterContentChecked,AfterViewInit {
  @ViewChild("content") modalContent: TemplateRef<any>;
  @ViewChild("aModal") modalContent_2: TemplateRef<any>;

  closeResult:any;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  category: any;
  selectService: any;
  get_category: any;
  get_service: any;
  job_type = 0;
  job_title: any;
  description: any;
  location: any;
  address: any;
  budget: any;
  datePicker: any;
  minDate: any;
  maxDate:any;
  time:any;
  serviceId: any;
  docArray: any[] = [];
  videoArray: any[] = [];
  imageArray: any[] = [];
  job_status = 1;
  checkCategoryStatus: any;
  docUploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['pdf', 'image']

  });
  videoUploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedMimeType: ['video/mp4']

  });
  imgUploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['image']

  });
  loader = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  selectJobType = false;
  budgetLimit = false;
  currentDate:any;
  badges:any;
  emirates_id_status:any;
  userData:any;
  user_info:any;
  getJobData:any;
  post_job:any;
  currency = 'USD';
  getCurrency:any;
  selectedCurrency:any;
  min_job_budget:any;
  max_job_budget:any;
  partial_payment:any;
  aydoerId:any;
  isPrivate:boolean = false
  constructor(private modalService: NgbModal, private categoryService: CategoryModalService, private locationService: LocationService,
              private documentService: DocumentService, private jobService: JobService, private toastr: ToastrService,
              private router: Router,private renderer: Renderer2,private badgeService:IdBadgeService,private route:ActivatedRoute,
              private cdr: ChangeDetectorRef) {
                this.renderer.addClass(document.body, 'job-modal-questions');
    this.minDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
    this.maxDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 2,
      day: new Date().getDate()
    };
    this.categoryService.getCategory().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.category = data.category.name;
      this.selectService = data.service.name;
      this.serviceId = data.service.id;
      this.get_category = data.category;
      this.selectJobType = data.service.online_enabled;

    });
    this.locationService.getLocation().pipe(takeUntil(this.ngUnsubscribe)).subscribe(location => {
      this.location = location;
      this.address = location.address;
    });
    this.aydoerId = this.route.snapshot.paramMap.get('aydoer');
  
  }

  ngOnInit() {

    this.currentDate = new Date();
    this.getIdBadges();
    this.user_info = localStorage.getItem('aydo-app-user');
    this.userData = JSON.parse(this.user_info);
    if(this.userData.currency) {
      this.selectedCurrency = this.userData.currency;
      this.checkCurrency();
      if(this.userData.currency != this.currency) {
        this.getCurrency = this.userData.currency;
      }
      
    }
    this.getJobData = JSON.parse(localStorage.getItem('aydoJobInfo'));
    if(this.getJobData) {
      this.category = this.getJobData.category.name;
      this.selectService = this.getJobData.service.name;
      this.serviceId = this.getJobData.service.id;
      this.get_category = this.getJobData.category;
      this.selectJobType = this.getJobData.service.online_enabled;
      this.location = this.getJobData.location;
      this.address = this.getJobData.address;
      this.job_title = this.getJobData.title;
      this.description = this.getJobData.description;
      const dateFormatr = new Date(this.getJobData.schedule);
      const date = dateFormatr.getDate();
      const month = dateFormatr.getMonth() + 1;
      const year = dateFormatr.getFullYear();
      this.datePicker = {
        year: year,
        month: month,
        day: date
      };
      const hour = dateFormatr.getHours();
      const minutes = dateFormatr.getMinutes();
      const seconds = dateFormatr.getSeconds();
      this.time = {
        hour: hour,
        minute: minutes,
        second:seconds
      };
      this.budget = this.getJobData.budget;

    }
  }
  ngAfterContentChecked(): void {
   
     this.cdr.detectChanges();
    
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if(this.aydoerId) {
        this.quoteModal(this.modalContent_2);
       }
    }, 500);
   }
  getIdBadges() {
    this.badgeService.getIdBadges().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.badges = res;
      this.emirates_id_status = this.badges['emirates_id_status'];
    }
    );
  }
  checkCurrency() {
    this.jobService.getAmmountLimit(this.selectedCurrency).subscribe((data:any) => {
     this.min_job_budget = Math.floor(data.min_job_budget);
     this.max_job_budget = Math.floor(data.max_job_budget);
    })
  }
checkLimit() {
  if(this.budget < this.min_job_budget || this.budget > this.max_job_budget) {
    this.budgetLimit = true;
  }
  else {
    this.budgetLimit = false;
  }
}
  open(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(CategoryModalComponent, this.ngbModalOptions);
    this.checkCategoryStatus = false;
    this.sendService();
  }

  serviceOpen(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(CategoryModalComponent, this.ngbModalOptions);
    if (this.category || this.selectService) {
      this.checkCategoryStatus = true;
      this.sendService();
    }
    else {
      this.checkCategoryStatus = false;
      this.sendService();
    }
  }

  sendService(): void {
    const checkStatus = {
      category: this.get_category,
      status: this.checkCategoryStatus
    };
    this.categoryService.sendStatus(checkStatus);
  }

  openLocation(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(LocationModalComponent, this.ngbModalOptions);
  }

  draftJob() {
    this.job_status = 0;
    this.postJob();
  }

  public onDocSelected(event: EventEmitter<File[]>) {
  }

  public onVideoSelected(event: EventEmitter<File[]>) {
  }

  public onImageSelected(event: EventEmitter<File[]>) {
  }

  async postJob() {



    gtag('event', 'post', {
      'event_category' : 'Job',
      'event_label' : 'job_post'
    });
    this.loader = true;
    for (const docupload of this.docUploader.queue) {
      const file: any = docupload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.docArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }
    for (const videoUpload of this.videoUploader.queue) {
      const file: any = videoUpload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.videoArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }
    for (const imgUpload of this.imgUploader.queue) {
      const file: any = imgUpload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.imageArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }

   if(this.datePicker) {
    var dateTimeFormat = new Date(this.datePicker.year, this.datePicker.month - 1, this.datePicker.day,
      this.time.hour,this.time.minute,this.time.second);
    }
    if (this.job_type === 1) {
      this.location = null;
    }
    const getDate = new Date(dateTimeFormat);
    var endDate = new Date(getDate).getTime();
    var startingDate = new Date(this.currentDate).getTime();
    
     var diffMs = endDate - startingDate;
    let diffHrs = diffMs / 3600000; 

  

      if(diffHrs <= 3) {
        this.toastr.error("Please select three hours later of current time");
        this.loader = false;
      }
      
     
      else {
        
        if(!dateTimeFormat) {
          this.post_job = {
            title: this.job_title,
            description: this.description,
            job_type: this.job_type,
            budget: this.budget,
            service_id: this.serviceId,
            location: this.location,
            image_ids: this.imageArray,
            video_ids: this.videoArray,
            doc_ids: this.docArray,
            status: this.job_status,
            currency:this.selectedCurrency,
        allow_partial_payment:this.partial_payment,
        aydoer_id:this.aydoerId,
      is_private:this.isPrivate

        }
      }
      if(dateTimeFormat) {
       this.post_job = {
      title: this.job_title,
      description: this.description,
      job_type: this.job_type,
      schedule: dateTimeFormat.toISOString(),
      budget: this.budget,
      service_id: this.serviceId,
      location: this.location,
      image_ids: this.imageArray,
      video_ids: this.videoArray,
      doc_ids: this.docArray,
      status: this.job_status,
      currency:this.selectedCurrency,
      allow_partial_payment:this.partial_payment,
      aydoer_id:this.aydoerId,
      is_private:this.isPrivate

    };
  }
    this.jobService.postJob(this.post_job).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
      this.loader = false;
      if(this.job_status == 0) {
        this.toastr.success('Job has been drafted');
        setTimeout(() => {
          this.router.navigate(['/my-jobs']);
        }, 1000);
      }
      if(this.job_status == 1) {
      this.cancelModal(this.modalContent);
      }
      

      }, error => {
        if(error.error.code == 3) {
          this.router.navigate(['/edit-profile']);
          this.loader = false;
        }
        if(error.error.code == 4) {
          this.modalService.open(IdBadgesModalComponent,this.ngbModalOptions);
          this.loader = false;
        }
        if(error.error.code == 7) {
          this.router.navigate(['/edit-profile'])
        }
        this.toastr.error(error.error.errors);
      });
  }
    
  }

quoteType(type:any) {
if(type == 1) {
  this.isPrivate = true;
  this.modalService.dismissAll('Cross click');
}
else {
  this.isPrivate = false;
  this.modalService.dismissAll('Cross click');
}
}
  cancelModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
     
    }, (reason) => {
      setTimeout(() => {
        this.router.navigate(['/my-jobs']);
      }, 1000);
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  quoteModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
     
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'job-modal-questions');
    localStorage.removeItem('aydoJobInfo');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
