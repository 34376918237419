import { Observable, throwError, empty } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler,HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly router: Router,
        private toastr: ToastrService
    ) {
    }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
            localStorage.removeItem('Authorization');
            localStorage.removeItem('aydo-app-user');
            this.router.navigate(['']);
            this.toastr.error(err.error.errors, '401', {progressBar: true});
            return throwError(err);
        } else {
          return throwError(err);
        }
      })
    );
  }

}