import { Component, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs/index';
import { takeUntil } from 'rxjs/internal/operators';
import { WalletService } from '../../services/wallet.service';
import { ToastrService } from 'ngx-toastr';
import { JobService } from "../../services/job.service";
import { PaymentModalService } from "../../services/payment-modal.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { QuestionModalService } from 'src/app/services/question-modal.service';

@Component({
  selector: 'app-wallet-method',
  templateUrl: './wallet-method.component.html',
  styleUrls: ['./wallet-method.component.css']
})
export class WalletMethodComponent implements OnInit {
  modal1 = false;
  modal2 = false;
  modal3 = false;
  modal4 = false;
  modal5 = false;
  modal6 = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  methodList: any[] = [];
  loader: any;
  p_name: any;
  p_email: any;
  method: any;
  getMethod: any;
  method_id: any;
  selectMethod: any;
  paymentType: any;
  acc_title: any;
  acc_name: any;
  acc_number: any;
  acc_iban: any;
  acc_swift_code: any;
  textDir: string = 'ltr';

  constructor(private renderer: Renderer2, private walletService: WalletService, private toastr: ToastrService,
    private jobService: JobService, private paymentModalService: PaymentModalService, public activeModal: NgbActiveModal,
    private questionModalService:QuestionModalService) {
      if(localStorage.getItem('aydoLang')) {
     
        if(localStorage.getItem('aydoLang') == 'ar') {
          this.textDir = 'rtl'
        }
        else {
          this.textDir = 'ltr'
        }
      }
    this.renderer.addClass(document.body, 'payment-method');
  }

  ngOnInit() {
    this.modal1 = true;
    this.get_withdrawl_methods('', '');
    // this.submitMethod();
  }
  get_withdrawl_methods(page, time) {
    this.loader = true;
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.walletService.getWithDrawalMethods(page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.methodList = this.methodList.concat(data['withdrawal_methods']);
      this.loader = false;

    }, error => {
      this.toastr.error(error.error.errors);
    }
    );
  }
  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + 10 + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.get_withdrawl_methods(this.currentPage, this.timeStamp);
      }
    }
  }
  addPaymentMethod() {
    this.acc_title = '';
    this.acc_name = '';
    this.acc_number = '';
    this.acc_iban = '';
    this.acc_swift_code = '';
    // this.method = '';
    this.modal1 = false;
    this.modal2 = true;
  }
  openModal3(method) {
    this.method = method;
    if (this.method == 'paypal') {
      this.modal2 = false;
      this.modal3 = true;
    }
    else if (this.method == 'bank') {
      this.modal2 = false;
      this.modal6 = true;
    }
  }
  getSingleMethod(item) {
    this.modal1 = false;
    this.modal4 = true;
    this.getMethod = item;
  }

  backList() {
    this.modal4 = false;
    this.modal1 = true;
  }
  delMethod(item) {
    this.method_id = item.id;
    this.walletService.deleteWithDrawalMethod(this.method_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.modal4 = false;
        this.modal1 = true;
        this.methodList = [];
        this.get_withdrawl_methods('', '');
        this.toastr.success('Account has been deleted');
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }
  editMethod(item) {
    this.modal4 = false;
    this.modal5 = true;
    this.acc_name = item.bank_name,
    this.acc_number = item.account_number,
    this.acc_iban = item.iban,
    this.acc_swift_code = item.swift_code,
    this.acc_title = item.account_title
    this.method = item.method_type;
    this.method_id = item.id;
  }
  updateAccount() {
    const info = {
      method_type: this.method,
      bank_name: this.acc_name,
      account_number: this.acc_number,
      iban: this.acc_iban,
      swift_code: this.acc_swift_code,
      account_title: this.acc_title
    };

    this.walletService.updateAccount(info, this.method_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toastr.success('Account has been updated');
        this.modal5 = false;
        this.modal1 = true;
        this.methodList = [];
        this.get_withdrawl_methods('', '');
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }
  saveAccount(f) {
    const methodType = 'bank';
    const info = {
      method_type: this.method,
      bank_name: this.acc_name,
      account_number: this.acc_number,
      iban: this.acc_iban,
      swift_code: this.acc_swift_code,
      account_title: this.acc_title
    }
    this.walletService.saveAccountMethod(info).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toastr.success('Account has been added');
        this.modal6 = false;
        this.modal1 = true;
        this.methodList = [];
        this.get_withdrawl_methods('', '');
        
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }
  closeModal() {
    this.activeModal.dismiss("Cross Click");
    this.sendService();
  }
  sendService(): void {
    this.questionModalService.sendMessage('success');
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'payment-method');
  }
}
