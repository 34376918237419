import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactComponent } from 'src/app/dashboard/contact/contact.component';
import { SigninComponent } from 'src/app/auth/signin/signin.component';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.css']
})
export class TermsAndConditionComponent implements OnInit, OnDestroy {
  toggleNavbar: any;
  user_info:any;
  constructor(private renderer: Renderer2,private modalService: NgbModal) {
    this.renderer.addClass(document.body, 'policy');
  }

  ngOnInit() {
    this.user_info = localStorage.getItem('aydo-app-user');
  }
  openContactModal() {
    const modalRef = this.modalService.open(ContactComponent);
  }
  signInModal() {
    const modalRef = this.modalService.open(SigninComponent);
    modalRef.componentInstance.signin = 'signin';
  }
  getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      window.open( 
        "https://apps.apple.com/pk/app/aydo/id1548434919", "_blank");
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      window.open( 
        "https://apps.apple.com/pk/app/aydo/id1548434919", "_blank");
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      // os = 'Windows';
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    } else if (/Android/.test(userAgent)) {
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    } else  {
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    }
  
  }
  signUpModal() {
    const modalRef = this.modalService.open(SigninComponent);
    modalRef.componentInstance.signup = 'signup';
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'policy');
  }

}
