import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-blogs-list',
  templateUrl: './blogs-list.component.html',
  styleUrls: ['./blogs-list.component.css']
})
export class BlogsListComponent implements OnInit,OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  newsList:any[] = [];
  constructor(private renderer: Renderer2,private sharedService:SharedService) {
    this.renderer.addClass(document.body, 'blogs');
  } 

  ngOnInit() {
    this.getBlogs();
  }
  getBlogs() {
    this.sharedService.getAydoBlog().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        
        this.pagination = data['page_info'];
        this.currentPage = this.pagination.page;
        this.timeStamp = this.pagination.timestamp;
        this.total = new Array(this.pagination.total_pages);
        this.totalPages = this.pagination.total_pages;
        this.newsList = data['news'];
      }
    )
  }
  navigate(page, time) {
    this.sharedService.listBlogs(page, time)
      .subscribe(
        data => {
          this.newsList = data['news'];
          this.pagination = data['page_info'];
          this.total = new Array(this.pagination.total_pages);
          this.totalPages = this.pagination.total_pages;
          this.currentPage = this.pagination.page;
          this.timeStamp = this.pagination.timestamp;
        }
      );
  }

  nextPrev(page, time) {
    if (page === 0 || page === this.pagination.total_pages + 1) {
      return;
    }
    this.navigate(page, time);
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'blogs');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
