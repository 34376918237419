import { Component, OnInit, HostListener, Renderer2, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WalletService } from 'src/app/services/wallet.service';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {
  @Input() requestStatus:any;
  private ngUnsubscribe: Subject<any> = new Subject();
  status:any;
  filterStatus = 0;
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  pagination1: any;
  total1;
  totalPages1: any;
  currentPage1: any;
  timeStamp1: any;
  moreAvailable1: boolean;
  loader = false;
  transactionsList: any[] = [];
  withdrawalList:any[] = [];
  noTransactions = false;
  noWithDrawalList = false;
  withDrawalDetail:any;
  modal_1 = false;
  modal_2 = false;
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  getFromDate = '';
  getToDate = '';
  textDir: string = 'ltr';
  lang:any;
  constructor(private walletService:WalletService,private renderer: Renderer2,private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.renderer.addClass(document.body, 'portfolio');
    if(localStorage.getItem('aydoLang')) {
     
      if(localStorage.getItem('aydoLang') == 'ar') {
        this.textDir = 'rtl'
      }
      else {
        this.textDir = 'ltr'
      }
    }
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getToday();
   }

  ngOnInit() {
    if(localStorage.getItem('aydoLang')) {
      this.lang = localStorage.getItem('aydoLang');
       
     }
    this.getTransactions('', '','','');
    this.getWIthDrawalList('','');
    this.getRequest();
    this.modal_1 = true;
    if(this.requestStatus == 1) {
      this.status = 1;
    }
    else {
      this.status = 0;
    }

  }
  filterBox() {
    this.modal_1 = false;
    this.modal_2 = true;
  }
  getRequest() {
    this.loader = true;
    this.walletService.getWithDrawalRequest().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.withDrawalDetail = data;
      this.loader = false;
    }
    );
  }
  getTransactions(page, time, dateFrom,dateTo) {
    this.walletService.getTransactionHistory(page, time,dateFrom,dateTo)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.transactionsList = this.transactionsList.concat(data['transactions']);
      this.loader = false;
      if (this.transactionsList.length === 0) {
        this.noTransactions = true;
      }
      if (this.transactionsList.length > 0) {
        this.noTransactions = false;
      }

    });
  }
  getWIthDrawalList(page, time) {
    this.walletService.getWithDrawalList(page, time)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.pagination1 = data['page_info'];
      this.currentPage1 = this.pagination1.page;
      this.timeStamp1 = this.pagination1.timestamp;
      this.total1 = new Array(this.pagination1.total_pages);
      this.totalPages1 = this.pagination1.total_pages;
      this.moreAvailable1 = this.pagination1.more_available;
      this.withdrawalList = this.withdrawalList.concat(data['withdrawal_requests']);
      this.loader = false;
      if (this.withdrawalList.length === 0) {
        this.noWithDrawalList = true;
      }
      if (this.withdrawalList.length > 0) {
        this.noWithDrawalList = false;
      }

    });
  }
  // sendStatus() {
  //   this.transactionsList = [];
  //   this.getTransactions('', '');
  // }
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight +10+ event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.getTransactions(this.currentPage, this.timeStamp,this.getFromDate,this.getToDate);
      }
    }
  }
  navigateList(event) {
    if (event.target.offsetHeight +10 + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable1 === true) {
        this.currentPage1++;
        this.getWIthDrawalList(this.currentPage1, this.timeStamp1);
      }
    }
  }
  applyFilter() {
    if(this.filterStatus == 1) {
    const fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day + 1);
    const ToDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day + 1);
    this.getFromDate = fromDate.toISOString();
    this.getToDate = ToDate.toISOString();
    this.transactionsList = [];
    this.getTransactions('', '',fromDate.toISOString(),ToDate.toISOString());
    this.modal_1 = true;
    this.modal_2 = false;
    this.status = 0;
    }
    else {
      this.transactionsList = [];
    this.getTransactions('', '','','');
    this.fromDate = null;
    this.toDate = null;
    this.getFromDate = '';
    this.getToDate = '';
    this.modal_1 = true;
    this.modal_2 = false;
    this.status = 0;
    }
  }
  backList() {
    this.modal_1 = true;
    this.modal_2 = false;
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'portfolio');
  }

}
