import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) {
  }
  getDashboardService() {
    return this.http.get(this.baseUrl + 'users/dashboard', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

}
