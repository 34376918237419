import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-support-detail',
  templateUrl: './support-detail.component.html',
  styleUrls: ['./support-detail.component.css']
})
export class SupportDetailComponent implements OnInit {
  catId:any;
  private ngUnsubscribe: Subject<any> = new Subject();
  faqsDetail:any;
  query:any;
  constructor(private router:ActivatedRoute,private sharedService:SharedService,private renderer: Renderer2) { 
   this.router.params.subscribe(params => this.catId = params['id']);
   this.renderer.addClass(document.body, 'support-center');

  }

  ngOnInit() {
    this.getSupportDetail();
  }
  getSupportDetail() {
    let params = {
      query:this.query
    }
   this.sharedService.getFaqsCategoryDetail(this.catId,params).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
     (data:any) => {
       this.faqsDetail = data;
     }
   )
  }
  SearchData() {
    let query = this.query
    this.faqsDetail = '';
    setTimeout(() => {
      if(query == this.query) {
      this.getSupportDetail();
      }

    }, 2000);
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'support-center');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
