import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {MapsAPILoader, MouseEvent} from "@agm/core";
import {LocationService} from "../../services/location.service";
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.css']
})
export class LocationModalComponent implements OnInit {
  latitude = 25.276987;
  longitude = 55.296249;
  zoom = 10;
  address: string;
  area: any;
  country: any;
  city: any;
  location: any;
  private geoCoder;
  url:'assets/images/map-pin.png';
  @ViewChild('search')
  public searchElementRef: ElementRef;
  textDir: string = 'ltr';

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone, private locationService: LocationService,private toaster:ToastrService,
              public activeModal: NgbActiveModal) {
                if(localStorage.getItem('aydoLang')) {
                 
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                  }
                  else {
                    this.textDir = 'ltr'
                  }
                }
  }

  ngOnInit() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude);

        });
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);

      });
    }
  }


  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({'location': {lat: latitude, lng: longitude}}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          for (const components of results[0].address_components) {
            for (const type of components.types) {
              if (type === 'locality' || type === 'administrative_area_level_2') {
                this.city = components.long_name;
              }
              if (type === 'sublocality' || type === 'sublocality_level_1') {
                this.area = components.long_name;
              }
              if (type === 'country') {
                this.country = components.long_name;
              }
            }
          }
          this.location = {
            lat: this.latitude,
            lng: this.longitude,
            address: this.address,
            area: this.area,
            city: this.city,
            country: this.country
          };
          this.sendLocation();
          this.toaster.success('Location Saved');

        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  sendLocation(): void {
    // this.activeModal.close("cross click");
    this.locationService.sendLocation(this.location);
  }
}
