import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getMyConv(page,time,query) {
    let p = '';
    if (page != null) {
      p = p + 'page=' + page.toString() + '&';
    }
    if (time != null) {
      p = p + 'timestamp=' + time.toString() + '&';
    }
    if (query != null) {
      p = p + 'query=' + query.toString();
    }
    let url = '';
    if (p.length > 0) {
      url = this.baseUrl + 'conversations?limit=100&' + p ;
    } else {
      url = this.baseUrl + 'conversations?limit=100';
    }
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getAllMessages(page:any,timestamp:any,id: any) {
    return this.http.get(this.baseUrl + 'conversations/' + id + '/messages' + '?page=' + page + '&timestamp=' + timestamp,  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  getDetailMessage(id: any) {
    return this.http.get(this.baseUrl + 'conversations/' + id , {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
}
