import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appMenu]'
})
export class MenuDirective {
  @HostBinding('class.active') isOpen = false;
  @HostListener('click') toggleOpen($event){
  this.isOpen = !this.isOpen;
  }
  constructor() { }

}
