import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class IdBadgeService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getIdBadges() {
    return this.http.get(this.baseUrl + 'profile_verifications/id_badges', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  putIdNumber(id: any) {
    return this.http.put(this.baseUrl + 'profile_verifications/id_verification', id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  policeCert(id: any) {
    return this.http.put(this.baseUrl + 'profile_verifications/police_certification', id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  fbConnect(token: any) {
    return this.http.put(this.baseUrl + 'profile_verifications/connect_facebook', token, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  linkedInConnect(token: any) {
    return this.http.put(this.baseUrl + 'profile_verifications/connect_linkedin', token, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  twitterConnect() {
    return this.http.get(this.baseUrl + 'profile_verifications/twitter_web', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  removeAccount(field: any) {
    return this.http.put(this.baseUrl + 'profile_verifications/remove_social_accounts', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
}
