import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthLoginService} from "../../auth/auth.service";
import {ToastrService} from "ngx-toastr";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/internal/operators";
import { SecretQuestionsComponent } from '../secret-questions/secret-questions.component';

@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.css']
})
export class UpdateEmailComponent implements OnInit, OnDestroy {
  modal1 = false;
  modal2 = false;
  modal3 = false;
  securities: any;
  question1: any;
  answer1: any;
  question2: any;
  answer2: any;
  a1: any;
  a2: any;
  q1_obj = {};
  q2_obj = {};
  sercret_answers: any[] = [];
  user_info: any;
  user_data: any;
  countries: any;
  selectCountry: any;
  email: any;
  code: any;
  updateUser: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  textDir: string = 'ltr';
  constructor(private authService: AuthLoginService, private toastr: ToastrService, public activeModal: NgbActiveModal,
    private modalService: NgbModal) {
      if(localStorage.getItem('aydoLang')) {
     
        if(localStorage.getItem('aydoLang') == 'ar') {
          this.textDir = 'rtl'
        }
        else {
          this.textDir = 'ltr'
        }
      }
  }

  ngOnInit() {
    this.fetchSecret();
    
    this.user_info = localStorage.getItem('aydo-app-user');
    this.user_data = JSON.parse(this.user_info);
  }

  fetchSecret() {
    this.authService.fetchSecrets().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.modal1 = true;
        this.securities = data;
        this.question1 = this.securities.q1.body;
        this.answer1 = this.securities.q1_answer;
        this.question2 = this.securities.q2.body;
        this.answer2 = this.securities.q2_answer;
      },
      error => {
        this.activeModal.dismiss('Cross click');
       if(error.status === 422) {
        
        this.modalService.open(SecretQuestionsComponent, this.ngbModalOptions);
       }
      });
  }

  generatePin() {
    const getPin = {
      email: this.email
    };
    this.authService.getEmailPin(getPin).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.modal2 = false;
      this.modal3 = true;
      this.toastr.success(data.message);
    }, error => {
      this.toastr.error(error.error.errors);
    });
  }

  verify() {
    const updateEmail = {
      email: this.email,
      secrets: this.sercret_answers,
      code: this.code
    };
    this.authService.updateEmail(updateEmail).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.toastr.success(data.message);
        this.updateUser = JSON.stringify(data);
        localStorage.setItem('aydo-app-user', this.updateUser);
        this.modal3 = false;
        this.activeModal.dismiss('Cross click');
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );


  }

  onAnswer1() {
    this.q1_obj['q_num'] = 1;
    this.q1_obj['answer'] = this.a1;
    this.sercret_answers.push(this.q1_obj);
  }

  onAnswer2() {
    this.q2_obj['q_num'] = 2;
    this.q2_obj['answer'] = this.a2;
    this.sercret_answers.push(this.q2_obj);
  }

  checkAnswers() {
    this.modal1 = false;
    this.modal2 = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
