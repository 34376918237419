import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class JobService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getServices() {
    return this.http.get(this.baseUrl + 'services', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  getAppSettings() {
    return this.http.get(this.baseUrl + 'app_settings', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  getSkills() {
    return this.http.get(this.baseUrl + 'skills' + '?limit=100',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

        })
      });
  }

  getCatServices(catID: any) {
    return this.http.get(this.baseUrl + 'services' + '?category_id=' + catID, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  getAmmountLimit(currency: any) {
    return this.http.get(this.baseUrl + 'app_settings/amount_limits' + '?currency=' + currency, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  getCategories() {
    if(localStorage.getItem('aydoLang')) {
    return this.http.get(this.baseUrl + 'categories', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'locale': localStorage.getItem('aydoLang')

        
      })
    });
  }
  else {
    return this.http.get(this.baseUrl + 'categories', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'locale': 'en'

        
      })
    });
  }
  }

  getAllServices() {
    if(localStorage.getItem('aydoLang')) {
      return this.http.get(this.baseUrl + 'services', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': localStorage.getItem('aydoLang')
  
          
        })
      });
    }
    else {
      return this.http.get(this.baseUrl + 'services', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': 'en'
  
          
        })
      });
    }

  }
  getAydoers(query:any) {
    for(let key in query) {
      if(query[key] == null || query[key] == undefined || query[key] == '') {
        delete query[key];
      }
    }
    if(localStorage.getItem('aydoLang') && localStorage.getItem('Authorization')) {
      return this.http.get(this.baseUrl + 'aydoers', {params:query,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': localStorage.getItem('aydoLang'),
          'Authorization': localStorage.getItem('Authorization'), 
  
          
        })
      });
    }
    else {
      return this.http.get(this.baseUrl + 'aydoers', {params:query,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': 'en'
  
          
        })
      });
    }

  }

  postJob(fields: any) {
    return this.http.post(this.baseUrl + 'jobs', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  updateJob(fields: any, id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + id, fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  partialPay(fields: any, id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + id +'/partial_payment', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
        'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getMyJobs(page, time, query) {
    return this.http.get(this.baseUrl + 'jobs/my_jobs' + '?page=' + page + '&timestamp=' + time + '&job_state=' + query, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  getDashboardJobs(page, time, query) {
    let p = '';
    if (page != null) {
      p = p + 'page=' + page.toString() + '&';
    }
    if (time != null) {
      p = p + 'timestamp=' + time.toString() + '&';
    }
    if (query != null) {
      p = p + 'query=' + query.toString();
    }
    let url = '';
    if (p.length > 0) {
      url = this.baseUrl + 'jobs?' + p;
    } else {
      url = this.baseUrl + 'jobs';
    }
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang'),
        'lang': localStorage.getItem('aydoLang')
      })
    });
  }

  getDisputes(page, time, status) {
    return this.http.get(this.baseUrl + 'disputes' + '?page=' + page + '&timestamp=' + time + '&status=' + status, {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  getDisputeDetail(id) {
    return this.http.get(this.baseUrl + 'disputes/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  getAllJobs(page, time, lat, lng, distance, sort_by, done_before, done_client, presence, query) {
    let p = '';
   
    if (page != null) {
      p = p + 'page=' + page.toString() + '&';
    }
    if (time != null) {
      p = p + 'timestamp=' + time.toString() + '&';
    }
    if (lat != null) {
      p = p + 'lat=' + lat.toString() + '&';
    }
    if (lng != null) {
      p = p + 'lng=' + lng.toString() + '&';
    }
    if (distance != null && distance != 0) {
      p = p + 'distance=' + distance.toString() + '&';
    }
    if (sort_by != null) {
      p = p + 'sort_by=' + sort_by.toString() + '&';
    }
    if (done_before != null) {
      p = p + 'job_done=' + done_before.toString() + '&';
    }
    if (done_client != null) {
      p = p + 'satisfied_clients=' + done_client.toString() + '&';
    }
    
    if (presence != null) {
      p = p + 'job_type=' + presence.toString() + '&';
    }
    if (query != null) {
      p = p + 'query=' + query.toString() + '&';
    }
    let url = '';
    if (p.length > 0) {
      url = this.baseUrl + 'jobs?' + p;
    } else {
      url = this.baseUrl + 'jobs';
    }
    const token = localStorage.getItem('Authorization');
    if (token != null) {
      return this.http.get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang'),

        })
      });
    }
    if (token === null) {
      return this.http.get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': 'en'

        })
      });
    }
  }

  getDetailJob(id: any) {
    const p = localStorage.getItem('Authorization');
    if (p != null) {
      return this.http.get(this.baseUrl + 'jobs/' + id, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang'),

        })
      });
    }
    if (p === null) {
      return this.http.get(this.baseUrl + 'jobs/' + id, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': 'en'

        })
      });
    }
  }

  getQuestions(id, page, time) {
    const p = localStorage.getItem('Authorization');
    if (p != null) {
      return this.http.get(this.baseUrl + 'jobs/' + id + '/questions' + '?page=' + page + '&timestamp=' + time, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang'),

        })
      });
    }
    if (p === null) {
      return this.http.get(this.baseUrl + 'jobs/' + id + '/questions' + '?page=' + page + '&timestamp=' + time, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': 'en'

        })
      });
    }
  }

  getOffers(id, page, time) {
    const p = localStorage.getItem('Authorization');
    if (p != null) {
      return this.http.get(this.baseUrl + 'jobs/' + id + '/offers' + '?page=' + page + '&timestamp=' + time, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang'),

        })
      });
    }
    if (p === null) {
      return this.http.get(this.baseUrl + 'jobs/' + id + '/offers' + '?page=' + page + '&timestamp=' + time, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': 'en'

        })
      });
    }
  }

  postQuestion(fields: any, id: any) {
    return this.http.post(this.baseUrl + 'jobs/' + id + '/questions', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  postReply(fields: any, id: any, qId: any) {
    return this.http.post(this.baseUrl + 'jobs/' + id + '/questions/' + qId + '/replies', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  updateQuestion(fields: any, id: any, qId: any) {
    return this.http.put(this.baseUrl + 'jobs/' + id + '/questions/' + qId, fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  updateReply(fields: any, id: any, qId: any,rId:any) {
    return this.http.put(this.baseUrl + 'jobs/' + id + '/questions/' + qId+'/replies/'+rId, fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  updateOfferReply(fields: any, id: any, offerId: any,rId:any) {
    return this.http.put(this.baseUrl + 'jobs/' + id + '/offers/' + offerId+'/replies/'+rId, fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  deleteQuestion(id: any, qId: any) {
    return this.http.delete(this.baseUrl + 'jobs/' + id + '/questions/' + qId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  deleteReply(id: any, qId: any,rId:any) {
    return this.http.delete(this.baseUrl + 'jobs/' + id + '/questions/' + qId +'/replies/'+rId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  postReport(fields: any) {
    return this.http.post(this.baseUrl + 'reports', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  postOffer(fields: any, id: any) {
    return this.http.post(this.baseUrl + 'jobs/' + id + '/offers', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  updateOffer(fields: any, id: any, offer_id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + id + '/offers/' + offer_id, fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  postOfferReply(fields: any, id: any, offerId: any) {
    return this.http.post(this.baseUrl + 'jobs/' + id + '/offers/' + offerId + '/replies', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  assignJob(fields: any) {
    return this.http.post(this.baseUrl + 'job_assignments', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  acceptJob(id: any) {
    return this.http.put(this.baseUrl + 'job_assignments/' + id + '/accept', '', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  declineJob(id: any) {
    return this.http.put(this.baseUrl + 'job_assignments/' + id + '/decline', '', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  completeJob(id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + id + '/mark_complete', '', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  payReleased(id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + id + '/release_payment', '', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  postDisputes(fields: any) {
    return this.http.post(this.baseUrl + 'disputes', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  postReview(fields: any, user_id: any) {
    return this.http.post(this.baseUrl + 'users/' + user_id + '/reviews', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  deleteJob(id: any) {
    return this.http.delete(this.baseUrl + 'jobs/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  deldispute(id: any) {
    return this.http.delete(this.baseUrl + 'disputes/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  cancelJob(id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + id + '/cancel', '', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  favJob(id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + id + '/mark_favourite', '', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  postResponse(fields: any, id: any) {
    return this.http.post(this.baseUrl + 'disputes/' + id + '/responses', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  disputeAdmin(id: any) {
    return this.http.put(this.baseUrl + 'disputes/' + id + '/escalate_to_admin', '', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  getResponses(id: any) {
    return this.http.get(this.baseUrl + 'disputes/' + id + '/responses', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  getAlerts(page, time) {
    return this.http.get(this.baseUrl + 'job_alerts' + '?page=' + page + '&timestamp=' + time, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  postAlert(fields: any) {
    return this.http.post(this.baseUrl + 'job_alerts', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  updateAlert(fields: any, id: any) {
    return this.http.put(this.baseUrl + 'job_alerts/' + id, fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }

  delAlert(id: any) {
    return this.http.delete(this.baseUrl + 'job_alerts/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
}
