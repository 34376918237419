import {Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject} from "rxjs/index";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {JobService} from "../../services/job.service";
import {takeUntil} from "rxjs/internal/operators";

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.css']
})
export class ReportModalComponent implements OnInit, OnDestroy {
  @Input() repoInfo: any;
  content: any;
  modal1 = false;
  modal2 = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  textDir: string = 'ltr';

  constructor(private jobService: JobService, private toaster: ToastrService, public activeModal: NgbActiveModal,
              private renderer: Renderer2) {
                if(localStorage.getItem('aydoLang')) {
     
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                  }
                  else {
                    this.textDir = 'ltr'
                  }
                }
    this.renderer.addClass(document.body, 'job-modal-questions');
  }

  ngOnInit() {
    this.modal1 = true;
  }

  postReport() {
    const q = {
      content: this.content,
      resource_id: this.repoInfo.q_id,
      report_type: this.repoInfo.resourceType
    };
    this.jobService.postReport(q).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.modal1 = false;
        this.modal2 = true;
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'job-modal-questions');
  }
}
