import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blogs-detail',
  templateUrl: './blogs-detail.component.html',
  styleUrls: ['./blogs-detail.component.css']
})
export class BlogsDetailComponent implements OnInit,OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  newsList:any[] = [];
  blogId:any;
  blogInfo:any;
  constructor(private renderer: Renderer2,private sharedService:SharedService,private route:ActivatedRoute) {
    this.renderer.addClass(document.body, 'blogs');
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      params => this.blogId = params['id']
    )

    
  } 

  ngOnInit() {
    this.getBlogDetail();
  }
  getBlogDetail() {
    this.sharedService.getBlogDetail(this.blogId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.blogInfo = data;
      }
    )
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'blogs');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
