import {Component, OnDestroy, OnInit} from '@angular/core';
import {JobService} from "../../../services/job.service";
import {takeUntil} from "rxjs/internal/operators";
import {Subject} from "rxjs/index";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CategoryModalService} from "../../../services/category-modal.service";

@Component({
  selector: 'app-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.css']
})
export class CategoryModalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  services: any[] = [];
  selectedCategory: any;
  categories: any;
  loader = false;
  showModal = false;
  serviceId: any;
  category: any;
  checkStatus: any;
  notAvaliable = false;
  textDir: string = 'ltr';
  status:boolean;
  constructor(private jobService: JobService, public activeModal: NgbActiveModal, private categoryService: CategoryModalService) {
    if(localStorage.getItem('aydoLang')) {
     
      if(localStorage.getItem('aydoLang') == 'ar') {
        this.textDir = 'rtl'
      }
      else {
        this.textDir = 'ltr'
      }
    }
    this.categoryService.getStatus().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      console.log(data);

      if (data.status === true && data.category !== undefined) {
        this.status = data.status;
        this.getCat(data.category);
      }
      if(data.status == false) {
        
        this.get_services();
      }
    });
  }

  ngOnInit() {
    this.get_categories();

    // this.get_services();
  }

  get_services() {
    this.jobService.getServices().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.services = data;
        if (this.services.length === 0) {
          this.notAvaliable = true;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getCategory() {
    this.category = this.selectedCategory.category;
    this.serviceId = this.selectedCategory;
    this.sendService();
    this.activeModal.dismiss('Cross click');
  }
  otherTask() {
    for(const item of this.services) {
      if(item.category.is_custom) {
        this.category = item.category;
        this.serviceId = item;
        this.sendService();
        this.activeModal.dismiss('Cross click');
      }
    }
  }
  get_categories() {
    this.loader = true;
    this.jobService.getCategories().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.categories = data;
        this.loader = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  getCat(item: any) {
    this.showModal = true;
    this.loader = true;
    this.category = item;
    this.jobService.getCatServices(item.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.services = data;
        this.loader = false;
        if (this.services.length === 0) {
          this.notAvaliable = true;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  sendService(): void {
    const sendService = {
      category: this.category,
      service: this.serviceId
    };
    this.categoryService.sendCategory(sendService);
    this.activeModal.dismiss('Cross click');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
