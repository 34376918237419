import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) {
  }

  upload(file) {
    const formData: any = new FormData();
    formData.append('doc', file);
    return this.http.post('https://stg-api.aydo.app/media_upload', formData,
      {
        headers: new HttpHeaders({
          'ApiKey': '099280a3038f6abd',
          'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
        })
      });
  }
}
