export class User {
  location?: any;
  first_name?: any;
  last_name?: any;
  avatar?: any;
  profile = new Profile();
  email_confirmed?: boolean;
  aydoer_rating: any;
  poster_rating: any;
  aydoer_reviews_count: any;
  poster_reviews_count: any;
  assignment_rate: any;
  created_at: Date;
  completed_jobs_count: any;
  posted_jobs_count: any;
  profile_completion: any;
  is_favorite: any;
  last_seen:any;
}

export class Profile {
  dob?: any;
  gender?: any;
  nationality?: any;
  tagline?: any;
  bio?: any;
  make_money?: any;
  post_jobs?: any;
  transportation?: any[];
  languages?: any[];
  cv?: any;
  trade_license_expiry?:any;
  company_registered?:any;
  company_name?:any;
  trn?:any;
  trade_license_number?:any;
  company_profile?:any;
  trn_certificate?:any;
  license_certificate?:any;
}
