import {Component, OnDestroy, OnInit, HostListener} from '@angular/core';
import {Router} from "@angular/router";
import {AuthLoginService} from "../../auth/auth.service";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {SecretQuestionsComponent} from "../secret-questions/secret-questions.component";
import {UpdatePhoneComponent} from "../update-phone/update-phone.component";
import {UpdateEmailComponent} from "../update-email/update-email.component";
import {ChangePasswordComponent} from "../change-password/change-password.component";
import {JobNotificationModalComponent} from "../notification-settings/job-notification-modal/job-notification-modal.component";
import {PaymentNotificationModalComponent} from "../notification-settings/payment-notification-modal/payment-notification-modal.component";
import {DisputeNotificationModalComponent} from "../notification-settings/dispute-notification-modal/dispute-notification-modal.component";
import {PolicyNotificationModalComponent} from "../notification-settings/policy-notification-modal/policy-notification-modal.component";
import {GeneralNotificationModalComponent} from "../notification-settings/general-notification-modal/general-notification-modal.component";
import {takeUntil} from "rxjs/internal/operators";
import {Subject} from "rxjs/index";
import {NotificationService} from "../notification-settings/notification.service";
import {PortfolioComponent} from "../portfolio/portfolio.component";
import {SkillsComponent} from "../skills/skills.component";
import {PaymentMethodComponent} from "../payment-method/payment-method.component";
import {FavouriteModalComponent} from "../favourite-modal/favourite-modal.component";
import {IdBadgesModalComponent} from "../id-badges-modal/id-badges-modal.component";
import { NotificationListService } from 'src/app/services/notification.service';
import { SigninComponent } from 'src/app/auth/signin/signin.component';
import { WalletMethodComponent } from '../wallet-method/wallet-method.component';
import { RequestWithDrawalComponent } from '../request-with-drawal/request-with-drawal.component';
import { TransactionHistoryComponent } from '../transaction-history/transaction-history.component';
import { DisputesModalComponent } from '../disputes-modal/disputes-modal.component';
import * as firebase from 'firebase';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  toggleNavbar: any;
  loader:any;
  notificationList: any[] = [];
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  data: any;
  user_info: any;
  jemail: boolean;
  jpush: boolean;
  jsms: boolean;
  pemail: boolean;
  ppush: boolean;
  psms: boolean;
  demail: boolean;
  dpush: boolean;
  dsms: boolean;
  poemail: boolean;
  popush: boolean;
  posms: boolean;
  gemail: boolean;
  gpush: boolean;
  gsms: boolean;
  settings: any;
  currentDate:any;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  count:boolean;
  getLang:any;
  private ngUnsubscribe: Subject<any> = new Subject();
  messaging = firebase.messaging();
  currentLang:any;
  lang:any;
  messageCount:any;
  constructor(private authService: AuthLoginService, private router: Router, private modalService: NgbModal,
              private notificationService: NotificationService,private notificationListService:NotificationListService,
              public translate: TranslateService) {
            this.currentLang = localStorage.getItem('aydoLang');
    this.notificationService.getJob().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    if(localStorage.getItem('aydoLang')) {
      this.lang = localStorage.getItem('aydoLang');
       
     }
    const currentDate = new Date().getDay();
    

    this.user_info = localStorage.getItem('aydo-app-user');
    if(this.user_info) {
    this.data = JSON.parse(this.user_info);
    this.messageCount = this.data.unread_messages.length;
    }
    if (this.user_info) {
      this.getJobs();
    }
    if (this.user_info) {
      this.getNotificationCount();
    }
  }
  getNotificationCount() {
    this.notificationListService.getNotificationCount().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if(res['unread_count'] > 0) {
        this.count = true;
      }
      else {
        this.count = false;
      }
    })
  }
  fetchList() {
    this.notificationList = [];
    this.getList('','');
  }
  getList(page,time) {
    this.loader = true;
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.notificationListService.getNotifications(page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.loader = false;
        this.pagination = data['page_info'];
        this.currentPage = this.pagination.page;
        this.timeStamp = this.pagination.timestamp;
        this.total = new Array(this.pagination.total_pages);
        this.totalPages = this.pagination.total_pages;
        this.moreAvailable = this.pagination.more_available;
        this.notificationList = this.notificationList.concat(data['notifications']);

      }
    );
  }
  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + 10 + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.getList(this.currentPage, this.timeStamp);
      }
    }
  }
  onLogout() {
    this.authService.logOut().subscribe(
      data => {
        localStorage.removeItem('Authorization');
        localStorage.removeItem('aydo-app-user');
        localStorage.removeItem('aydoJobInfo')

        this.router.navigate(['/']);
        
          this.messaging.getToken()
          .then(t => this.messaging.deleteToken(t) )
          .then(r => console.log(r))
          .catch(e => console.error(e));  
          
      },
      error => {
        console.log(error);

      }
    );
  }
  signInModal() {
    const modalRef = this.modalService.open(SigninComponent);
    modalRef.componentInstance.signin = 'signin';
  }
  getJobs() {
    this.authService.getNotificatinSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.settings = data;
        for (const setting of this.settings) {
          if (setting.notification_category === 0) {
            this.jemail = setting.email;
            this.jpush = setting.push;
            this.jsms = setting.sms;
          }
          if (setting.notification_category === 1) {
            this.pemail = setting.email;
            this.ppush = setting.push;
            this.psms = setting.sms;
          }
          if (setting.notification_category === 2) {
            this.demail = setting.email;
            this.dpush = setting.push;
            this.dsms = setting.sms;
          }
          if (setting.notification_category === 3) {
            this.poemail = setting.email;
            this.popush = setting.push;
            this.posms = setting.sms;
          }
          if (setting.notification_category === 4) {
            this.gemail = setting.email;
            this.gpush = setting.push;
            this.gsms = setting.sms;
          }
        }
      },
      error => {
        console.log(error);

      }
    );
  }
  openWithdrawal() {
    const modalRef = this.modalService.open(WalletMethodComponent, this.ngbModalOptions);
  }
  openRequestWithdrawal() {
    const modalRef = this.modalService.open(RequestWithDrawalComponent, this.ngbModalOptions);
  }

  secret_modal() {

    const modalRef = this.modalService.open(SecretQuestionsComponent, this.ngbModalOptions);
  }

  phone_modal() {
    const modalRef = this.modalService.open(UpdatePhoneComponent, this.ngbModalOptions);
  }

  email_modal() {
    const modalRef = this.modalService.open(UpdateEmailComponent, this.ngbModalOptions);
  }

  change_password_modal() {
    const modalRef = this.modalService.open(ChangePasswordComponent, this.ngbModalOptions);
  }

  openModalJob() {
    this.modalService.open(JobNotificationModalComponent, this.ngbModalOptions);
  }

  openModalPayment() {
    this.modalService.open(PaymentNotificationModalComponent, this.ngbModalOptions);
  }

  openModalDispute() {
    this.modalService.open(DisputeNotificationModalComponent, this.ngbModalOptions);
  }

  openModalPolicy() {
    this.modalService.open(PolicyNotificationModalComponent, this.ngbModalOptions);
  }

  openModalGeneral() {
    this.modalService.open(GeneralNotificationModalComponent, this.ngbModalOptions);
  }

  open_portfolio() {
    this.modalService.open(PortfolioComponent, this.ngbModalOptions);
  }

  open_skills() {
    this.modalService.open(SkillsComponent, this.ngbModalOptions);
  }

  openPaymentModal() {
    this.modalService.open(PaymentMethodComponent, this.ngbModalOptions);
  }

  openFavModal() {
    this.modalService.open(FavouriteModalComponent, this.ngbModalOptions);
  }

  openIdBadgeModal() {
    this.modalService.open(IdBadgesModalComponent, this.ngbModalOptions);
  }
  openTransactionHistory() {
    this.modalService.open(TransactionHistoryComponent,this.ngbModalOptions);
  }
  notificationData(item) {
  this.isReadNotification(item.id);
  if((item.notification_type >=1 && item.notification_type <= 8) || (item.notification_type >= 18 && item.notification_type <= 21) 
    || (item.notification_type >= 24 && item.notification_type <= 27) ||
    item.notification_type >= 35 && item.notification_type <= 38) {
  this.router.navigate([`/detail/${item.resource_id}`]);
  }
  else if((item.notification_type >=12 && item.notification_type <= 16) || item.notification_type == 22) {
    const modalRef = this.modalService.open(DisputesModalComponent,this.ngbModalOptions);
    modalRef.componentInstance.disputeNotification = item.resource_id;
  }
  else if(item.notification_type == 9) {
    this.modalService.open(TransactionHistoryComponent,this.ngbModalOptions);
  }
  else if(item.notification_type == 10) {
  this.router.navigate([`/profile/${item.resource_id}`]); 
  }
  else if(item.notification_type == 11 || item.notification_type == 28 || item.notification_type == 34) {
    this.modalService.open(SkillsComponent,this.ngbModalOptions);
  }
  else if(item.notification_type == 29 || item.notification_type == 33 || item.notification_type == 32) {
    const modalRef= this.modalService.open(TransactionHistoryComponent,this.ngbModalOptions);
    modalRef.componentInstance.requestStatus = '1';
  }
  else if(item.notification_type == 39){
  this.router.navigate([`/ad`]);

  }
  }
  isReadNotification(id) {
    this.notificationListService.isReadNotification(id).subscribe(data => this.getNotificationCount());
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
