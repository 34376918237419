import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  portfolioSave(fields: any) {
    return this.http.post(this.baseUrl + 'portfolios', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  portfolioUpdate(fields: any, field: any) {
    return this.http.put(this.baseUrl + 'portfolios/' + field, fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  portfolioOrder(fields: any) {
    return this.http.put(this.baseUrl + 'portfolios/update_order', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getPortfolio(page, time) {
    return this.http.get(this.baseUrl + 'portfolios' + '?page=' + page + '&timestamp=' + time,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
        })
      });
  }

  getSinglePortfolio(field: any) {
    return this.http.get(this.baseUrl + 'portfolios/' + field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  delPortfolio(id: any) {
    return this.http.delete(this.baseUrl + 'portfolios/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

}
