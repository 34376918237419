import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthLoginService } from 'src/app/auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { JobService } from 'src/app/services/job.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
interface MailChimpResponse {
  result: string;
  msg: string;
}
@Component({
  selector: 'app-mailchimp-form',
  templateUrl: './mailchimp-form.component.html',
  styleUrls: ['./mailchimp-form.component.css']
})
export class MailchimpFormComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  countries: any[] = [];
  selectCountry: any;
  selectNationality: any;
  categories: any[] = [];
  selectCategory: any
  f_name: any;
  l_name: any;
  email: any;
  phone: any;
  digitLength:any;
  country_code:any;
  pp:any;
  mailChimpEndpoint = 'https://app.us4.list-manage.com/subscribe/post-json?u=9fdfd7e1275bb0660fd3869e1&amp;id=e30941499f&';
  constructor(private authService: AuthLoginService, private jobService: JobService, private renderer: Renderer2,
    private http: HttpClient,private toastr: ToastrService) {
    this.renderer.addClass(document.body, 'mail-chimp');
  }

  ngOnInit() {
    this.fetchCountries();
    this.get_categories();
    this.selectCountry = {id: 224,
      name: "UNITED ARAB EMIRATES",
      country_code: "971",
      iso_code: "AE"}
      if (this.selectCountry) {
        this.country_code = this.selectCountry.country_code;
        this.digitLength = 12 - this.country_code.toString().length;
      }

  }
  fetchCountries() {
    this.authService.getCountries().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res: any) => {
        this.countries = res;
      },
      error => {
        console.log(error);
      }
    );
  }
  get_categories() {
    this.jobService.getAllServices().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.categories = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  checkLength() {
    
    if(this.phone != null) {
    this.phone = null;
    }
    if (this.selectCountry) {
      this.country_code = this.selectCountry.country_code;
      this.digitLength = 12 - this.country_code.toString().length;
    }
  }
  submit(f: any) {
    
    let country = this.selectCountry.name;
    let nationality = this.selectNationality.name;
    let category = this.selectCategory.name;
    const mailChimpUrl = this.mailChimpEndpoint + 'EMAIL=' + this.email + '&LNAME=' + this.l_name + '&FNAME=' + this.f_name +
      '&COUNTRY=' + country + '&PHONE=' +this.country_code+'-'+this.phone + '&NATIONAL=' + nationality + '&STYPE=' + category;
    this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
      if (response.result && response.result !== 'error') {
        this.toastr.success('Success');
        f.resetForm();
      }
      else {

      }
    }, error => {
      console.error(error);

    });
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'mail-chimp');
  }
}
