import {Component, EventEmitter, OnDestroy, OnInit, Renderer2, NgZone, HostListener, Input} from '@angular/core';
import {Subject} from "rxjs/index";
import {ProfileService} from "../../services/profile.service";
import {takeUntil} from "rxjs/internal/operators";
import {User} from "../user-profile";
import {SkillsService} from "../../services/skills.service";
import {FileUploader} from "ng2-file-upload";
import {DocumentService} from "../../services/document.service";
import {ToastrService} from "ngx-toastr";
import {AuthLoginService} from "../../auth/auth.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {queue} from "rxjs/internal/scheduler/queue";
import { QuestionModalService } from 'src/app/services/question-modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit, OnDestroy {
  @Input() detailJob:any;
  modal1 = false;
  modal2 = false;
  modal3 = false;
  data = new User();
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  uploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    allowedFileType: ['pdf','image']

  });
  docuploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['pdf', 'image']

  });
  introuploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['video', 'image']

  });
  certuploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['pdf', 'image']

  });
  skillsList: any[] = [];
  cv_id: any;
  loader = false;
  file_error: any;
  cvType: any;
  edit_cv = false;
  showCv = false;
  services: any;
  selectService: any;
  docArray: any[] = [];
  introArray: any[] = [];
  certArray: any[] = [];
  experience: any;
  isCertified: boolean;
  skillId: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  textDir: string = 'ltr';

  constructor(private renderer: Renderer2, private profileService: ProfileService, private skillsService: SkillsService,
              private documentService: DocumentService, private toastr: ToastrService, private authService: AuthLoginService,
              private ngZone: NgZone, private questionModalService: QuestionModalService,private activeModal:NgbActiveModal,
              private translate:TranslateService) {
                if(localStorage.getItem('aydoLang')) {
     
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                  }
                  else {
                    this.textDir = 'ltr'
                  }
                }
    this.renderer.addClass(document.body, 'skills');
  }

  ngOnInit() {
    this.modal1 = true;
    this.get_profile();
    this.get_skills(this.currentPage, this.timeStamp);
    this.get_services();
  }

  get_skills(page, time) {
    this.loader = true;
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.skillsService.getSkills(page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.pagination = data['page_info'];
        this.currentPage = this.pagination.page;
        this.timeStamp = this.pagination.timestamp;
        this.total = new Array(this.pagination.total_pages);
        this.totalPages = this.pagination.total_pages;
        this.moreAvailable = this.pagination.more_available;
        this.skillsList = this.skillsList.concat(data['skills']);
        this.loader = false;

      }, error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.get_skills(this.currentPage, this.timeStamp);
      }
    }
  }

  get_profile() {
    this.profileService.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        if (data.profile.cv !== null) {
          this.cvType = data.profile.cv.content_type;
          this.showCv = true;
        }
        this.data = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  postCv() {
    const post_cv = {
      cv_id: this.cv_id
    };
    this.profileService.saveProfile(post_cv).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        const updateUser = JSON.stringify(data);
        localStorage.setItem('aydo-app-user', updateUser);
        if (data.profile.cv !== null) {
          this.cvType = data.profile.cv.content_type;
          this.showCv = true;
          this.edit_cv = false;
        }
        this.data = data;
        
        this.translate.get('cv_uploaded_successfully').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  editCv() {
    this.edit_cv = true;
  }

  delCv() {
    const post_cv = {
      cv_id: -1
    };
    this.profileService.saveProfile(post_cv).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        const updateUser = JSON.stringify(data);
        localStorage.setItem('aydo-app-user', updateUser);
        this.data = data;
        this.showCv = false;
        this.translate.get('done').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  delSkill(id) {
    this.skillId = id;
    this.skillsService.deleteSkill(this.skillId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.skillsList = [];
        this.get_skills('', '');
        this.translate.get('done').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.sendService();
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  editSkill(id) {
    this.modal1 = false;
    this.modal3 = true;
    this.skillId = id;
    this.skillsService.getSkill(this.skillId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.selectService = data.service;
        this.docuploader.queue = data.skill_docs;
        this.introuploader.queue = data.intro_docs;
        this.experience = data.experience;
        this.isCertified = data.is_certified;
        this.certuploader.queue = data.certification_docs;
        for (const docUploader of data.skill_docs) {
          const docId = docUploader.id;
          this.docArray.push(docId);
        }
        for (const introUploader of data.intro_docs) {
          const docId = introUploader.id;
          this.introArray.push(docId);
        }
        for (const certUploader of data.certification_docs) {
          const docId = certUploader.id;
          this.certArray.push(docId);
        }
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  newSkill() {
    this.modal1 = false;
    this.modal2 = true;
    this.docArray.length = 0;
    this.introArray.length = 0;
    this.certArray.length = 0;
    this.docuploader.queue.length = 0;
    this.introuploader.queue.length = 0;
    this.certuploader.queue.length = 0;
    this.selectService = null;
    this.isCertified = null;
    this.experience = null;
  }

  get_services() {
    this.authService.getServices().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.services = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    if (file.type == 'application/pdf' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
    this.loader = true;
      this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          this.loader = false;
          this.cv_id = res['id'];
          this.file_error = '';
          this.postCv();
        }, error => {
          console.log('changePicture error', error);
        });
    } else {
      this.loader = false;
      this.file_error = 'Please Upload Only Document in pdf and image format';
    }
  }

  public onDocSelected(event: EventEmitter<File[]>) {
  }

  public onIntroSelected(event: EventEmitter<File[]>) {
  }

  public onCertSelected(event: EventEmitter<File[]>) {
  }

  removeDoc(value) {
    const index = this.docuploader.queue.indexOf(value.id);
    const index2 = this.docArray.indexOf(value.id);
    this.docuploader.queue.splice(index, 1);
    this.docArray.splice(index2, 1);
  }

  removeIntro(value) {
    const index = this.introuploader.queue.indexOf(value.id);
    const index2 = this.introArray.indexOf(value.id);
    this.introuploader.queue.splice(index, 1);
    this.introArray.splice(index2, 1);
  }

  removeCert(value) {
    const index = this.certuploader.queue.indexOf(value.id);
    const index2 = this.certArray.indexOf(value.id);
    this.certuploader.queue.splice(index, 1);
    this.certArray.splice(index2, 1);
  }

  async saveSkill() {
    this.loader = true;
    if (this.isCertified === false) {
      this.certuploader.queue.length = 0;
    }
    for (const docupload of this.docuploader.queue) {
      const file: any = docupload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.docArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }
    for (const introUpload of this.introuploader.queue) {
      const file: any = introUpload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.introArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }
    for (const certUpload of this.certuploader.queue) {
      const file: any = certUpload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.certArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }

    const serviceId = this.selectService.id;

    const createSkill = {
      service_id: serviceId,
      is_certified: this.isCertified,
      experience: this.experience,
      skill_docs_ids: this.docArray,
      intro_docs_ids: this.introArray,
      certification_docs_ids: this.certArray
    };
    this.skillsService.saveSkills(createSkill).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader = false;
        this.translate.get('done').subscribe((res: string) => {
          this.toastr.success(res);
        });
        if(this.detailJob == 'detail-job') {
         this.activeModal.close('Cross Click');
         this.sendService();

        }
        else {
         this.sendService();
          this.modal2 = false;
          this.modal1 = true;
          this.docArray.length = 0;
          this.introArray.length = 0;
          this.certArray.length = 0;
          this.skillsList = [];
          this.get_skills('', '');
        }
       

      }, error => {
        this.toastr.error(error.error.errors);
        this.loader = false;
      });
  }
  sendService(): void {
    this.questionModalService.sendMessage('success');
    // this.activeModal.dismiss('Cross click');
  }
  async updateSkill() {
    this.loader = true;
    if (this.isCertified === false) {
      this.certuploader.queue.length = 0;
      this.certArray.length = 0;
    }
    for (const docupload of this.docuploader.queue) {
      if (docupload.file) {
        const file: any = docupload.file.rawFile;
        await new Promise((resolve, reject) => {
          this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.docArray.push(res.id);
              resolve();
            }, error => {
              console.log('changePicture error', error);
            });
        });
      }
    }
    for (const introUpload of this.introuploader.queue) {
      if (introUpload.file) {
        const file: any = introUpload.file.rawFile;
        await new Promise((resolve, reject) => {
          this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.introArray.push(res.id);
              resolve();
            }, error => {
              console.log('changePicture error', error);
            });
        });
      }
    }
    for (const certUpload of this.certuploader.queue) {
      if (certUpload.file) {
        const file: any = certUpload.file.rawFile;
        await new Promise((resolve, reject) => {
          this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.certArray.push(res.id);
              resolve();
            }, error => {
              console.log('changePicture error', error);
            });
        });
      }
    }

    const serviceId = this.selectService.id;

    const updateSkill = {
      service_id: serviceId,
      is_certified: this.isCertified,
      experience: this.experience,
      skill_docs_ids: this.docArray,
      intro_docs_ids: this.introArray,
      certification_docs_ids: this.certArray
    };
    this.skillsService.updateSkill(updateSkill, this.skillId).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader = false;
        this.translate.get('done').subscribe((res: string) => {
          this.toastr.success(res);
        });
        if(this.detailJob == 'detail-job') {
          this.activeModal.close('Cross Click');
          this.sendService();
 
         }
         else {
          this.sendService();
           this.modal3 = false;
           this.modal1 = true;
           this.docArray.length = 0;
           this.introArray.length = 0;
           this.certArray.length = 0;
           this.skillsList = [];
           this.get_skills('', '');
         }
      }, error => {
        this.toastr.error(error.error.errors);
      });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'skills');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
