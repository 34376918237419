import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PaymentModalService } from 'src/app/services/payment-modal.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-stripe-modal',
  templateUrl: './stripe-modal.component.html',
  styleUrls: ['./stripe-modal.component.css']
})
export class StripeModalComponent implements OnInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;

  stripe;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  loader:boolean = false;
  client_secret:any;
  constructor(private cd: ChangeDetectorRef,
    private stripeService: AngularStripeService,private walletService:WalletService,public activeModal: NgbActiveModal,private toastr: ToastrService,
    private dataSendService:PaymentModalService,private modalService:NgbModal) { }
    ngAfterViewInit() {
      this.loader = true;
      this.walletService.getPublicKey().subscribe((data: any) => {
        let publicKey = data.publishable_key;
        this.client_secret = data.client_secret;
        this.stripeService.setPublishableKey(publicKey).then(
          stripe => {
            this.stripe = stripe;
            const elements = stripe.elements();
            this.card = elements.create('card');
            this.card.mount(this.cardInfo.nativeElement);
            this.card.addEventListener('change', this.cardHandler);
            this.loader = false;
          });
  
  
      },
        error => {
          this.toastr.error(error.error.errors);
          this.activeModal.close('Cross Click');
        }
      )
    }
  ngOnInit() {
  }
  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }
  onSubmit() {
    this.loader = true;
    this.stripe.confirmCardSetup(this.client_secret,{
      payment_method: {
      type: 'card',
      card: this.card,
      }
    }).then(result => {
      const data = {
        payment_gateway_response: {
          payment_method_id: result.setupIntent.payment_method
        }
      }
      this.walletService.creatPaymentMethod(data).subscribe((data: any) => {
        this.toastr.success('Card has been added');
        this.activeModal.close('Cross Click');
        this.dataSendService.sendMessageCard('Success');
  
      },
        error => {
          this.loader = false;
          this.toastr.error(error.error.errors);
        })
    }).catch(e => {
      this.loader = false;
      this.toastr.error(e);
    })
  
  
  }
}
