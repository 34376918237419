import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotificationListService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getNotifications(page, time) {
    return this.http.get(this.baseUrl + 'notifications' + '?page=' + page + '&timestamp=' + time,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
        })
      });
  }
  getNotificationCount() {
    return this.http.get(this.baseUrl + 'notifications/unread_count',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
        })
      });
  }
  isReadNotification(id) {
    return this.http.put(`${this.baseUrl}notifications/${id}/mark_read` ,'',
    {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  pushNotificationToken(data) {
  return this.http.post(this.baseUrl + 'register_device',data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    })
  }
}
