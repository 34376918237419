import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationCancel } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { IdBadgeService } from 'src/app/services/id-badge.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IdBadgesModalComponent } from '../id-badges-modal/id-badges-modal.component';
import { PaymentMethodComponent } from '../payment-method/payment-method.component';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {
code: any = {'token': ''};
loader = false;
ngbModalOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false
};
  constructor(private activatedRoute:ActivatedRoute,private router:Router,private badgeService: IdBadgeService,
    private toastr: ToastrService,private modalService: NgbModal) {
    
    this.activatedRoute.queryParams.subscribe(params => {
      
     
     
        this.code.token = params['code'];
        this.connectLinkedIn();

      
    });
    
   }

  ngOnInit() {
  }
  connectLinkedIn() {
    this.loader = true;
    this.badgeService.linkedInConnect(this.code).subscribe(
      data => {
        this.loader = false;
        this.toastr.success('Connected');
        this.openIdBadgeModal();
       
      },
      error => {
        this.loader = false;
        this.toastr.error(error.error.errors);
      }
    );
  }
  openIdBadgeModal() {
    this.modalService.open(IdBadgesModalComponent, this.ngbModalOptions);
  }
  // paymentMethod() {
  //   this.modalService.open(PaymentMethodComponent,this.ngbModalOptions);
  // }

}
