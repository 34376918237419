import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }
  getFaqsCategory() {
    
    return this.http.get(this.baseUrl + 'faq_categories/only_categories',  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
  getFaqsCategoryDetail(id:any,query:any) {
    for(let key in query) {
      if(query[key] == null || query[key] == undefined || query[key] == '') {
        delete query[key];
      }
    }
    return this.http.get(`${this.baseUrl}faq_categories/${id}`, {params:query,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
  getAydoBlog() {
    return this.http.get(this.baseUrl + 'news', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
  getBlogDetail(id:any) {
    return this.http.get(this.baseUrl + 'news/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
  listBlogs(page,time) {
    return this.http.get(this.baseUrl + 'news' + '?page=' + page + '&timestamp=' + time, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
  contactUs(fields:any) {
    return this.http.post(this.baseUrl + 'contact_us',fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
}
