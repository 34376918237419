import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit,OnDestroy {

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'contact-modal');
   }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'contact-modal');
  }
}
