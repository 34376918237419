import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthLoginService} from "../../auth/auth.service";
import {LanguagesService} from "../../services/languages.service";
import {Subject} from "rxjs/index";
import {takeUntil} from "rxjs/internal/operators";

@Component({
  selector: 'app-languages-modal',
  templateUrl: './languages-modal.component.html',
  styleUrls: ['./languages-modal.component.css']
})
export class LanguagesModalComponent implements OnInit, OnDestroy {
  textDir: string = 'ltr';
  userLanguages: any;
  checkedList = [];
  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(private authService: AuthLoginService, private languageService: LanguagesService) {
    if(localStorage.getItem('aydoLang')) {
     
      if(localStorage.getItem('aydoLang') == 'ar') {
        this.textDir = 'rtl'
      }
      else {
        this.textDir = 'ltr'
      }
    }
    this.languageService.getLanguage().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.checkedList = data;
    });
  }

  ngOnInit() {
    this.getLanguages();
  }

  getLanguages() {
    this.authService.getLanguages().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      res => {
        this.userLanguages = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  toggleClicked(item) {
    const index = this.checkedList.indexOf(item.name);
    if (index === -1) {
      this.checkedList.push(item.name);
      this.sendLanguage();
    } else {
      this.checkedList.splice(index, 1);
      this.sendLanguage();
    }
  }

  sendLanguage(): void {
    this.languageService.sendLanguage(this.checkedList);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
