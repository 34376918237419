import {Component, EventEmitter, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/index';
import {FileUploader} from "ng2-file-upload";
import {takeUntil} from "rxjs/internal/operators";
import {ToastrService} from "ngx-toastr";
import {DocumentService} from "../../../services/document.service";
import {JobService} from "../../../services/job.service";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {QuestionModalService} from "../../../services/question-modal.service";
declare var gtag

@Component({
  selector: 'app-create-dispute-modal',
  templateUrl: './create-dispute-modal.component.html',
  styleUrls: ['./create-dispute-modal.component.css']
})
export class CreateDisputeModalComponent implements OnInit, OnDestroy {
  textDir: string = 'ltr';
  @ViewChild("content") modalContent: TemplateRef<any>;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    windowClass:'job-modal-questions'
  };
  @Input() jobInfo: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  docuploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['pdf', 'image','video']

  });
  docArray: any[] = [];
  loader = false;
  title: any;
  reason: any;
  refrence_id:any;
  enLang:boolean;

  disputeReason = [
    {id: 1, name: 'Service did not meet the expectations', ar_name:'الخدمة لم تلبِ التوقعات'},
    {id: 2, name: 'Technical problem/defective product', ar_name: 'مشكلة فنية / منتج مُعيب'},
    {id: 3, name: 'Lack of respect and business etiquette',ar_name: 'عدم التقيد بآداب واحترام العمل'},
    {id: 4, name: 'Theft or local law violation', ar_name: 'السرقة أو انتهاك القانون المحلي'},
    {id: 5, name: 'aydoer did not meet the target date/time', ar_name: 'لم تُقدم الخدمة بالتاريخ / الوقت المستهدف'},
    {id: 6, name: 'aydoer requested more than the agreed amount', ar_name: 'تم طلب مبلغ مالي أكثر من المبلغ المتفق عليه'},
    {id: 7, name: `aydoer violated aydo's Community Guidelines` ,ar_name: 'انتهاك إرشادات مجتمع آيدو'},
    {id: 8, name: 'aydoer is not licensed as declared', ar_name: 'غير مرخص كما هو معلن في آيدو'},
    {id: 9, name: 'aydoer did not show up, or did not do the requested task', ar_name: 'لم يظهر الشخص، أو لم يقم بالمهمة المطلوبة'},
    {id: 10, name: 'another person showing up for the task, aydoer did not request my approval for that', ar_name: 'ظهور شخص آخر للمهمة ، ولم تُطلب موافقتي على ذلك'},
    // {id: 11, name: 'Poster did not answer my calls on target date nor showed up'},
    // {id: 12, name: `Poster violated aydo's Community Guidelines`}

];
  constructor(private renderer: Renderer2, private toaster: ToastrService, private documentService: DocumentService,
              public activeModal: NgbActiveModal, private jobService: JobService, private questionModalService: QuestionModalService,
              private modalService:NgbModal) {
                if(localStorage.getItem('aydoLang')) {
     
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                    this.enLang = false;
                  }
                  else {
                    this.textDir = 'ltr'
                    this.enLang = true;
                  }
                }
    this.renderer.addClass(document.body, 'job-modal-questions');
  }

  ngOnInit() {
  }

  public onDocSelected(event: EventEmitter<File[]>) {
  }

  async postDispute() {
    gtag('event', 'post', {
      'event_category' : 'Dispute',
      'event_label' : 'dispute_post'
    });
    this.loader = true;
    for (const docupload of this.docuploader.queue) {
      const file: any = docupload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.docArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }
    const q = {
      summary: this.title,
      doc_ids: this.docArray,
      reason: this.reason.name,
      job_id: this.jobInfo.id
    };
    this.jobService.postDisputes(q).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.activeModal.dismiss('Cross Click');
        this.cancelModal(this.modalContent);
        this.loader = false;


        this.refrence_id = data['reference_id'];
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  cancelModal(content) {
  this.modalService.open(content,this.ngbModalOptions).result.then((result) => {
   
  }, (reason) => {
    this.sendService();
  });
}
  

  sendService(): void {
    this.questionModalService.sendMessage('success');
    this.activeModal.dismiss('Cross click');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'job-modal-questions');
  }
}
