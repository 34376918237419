import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryModalService {

  constructor() { }
  private subject = new Subject<any>();
  private checkSubject = new Subject<any>();

  sendCategory(message: any) {
    this.subject.next(message);
  }
  getCategory(): Observable<any> {
    return this.subject.asObservable();
  }
  sendStatus(message: any) {
    this.checkSubject.next(message);
  }
  getStatus(): Observable<any> {
    return this.checkSubject.asObservable();
  }
}
