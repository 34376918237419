import {Component, OnInit} from '@angular/core';
import {AuthLoginService} from "../../auth/auth.service";
import {ToastrService} from "ngx-toastr";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-secret-questions',
  templateUrl: './secret-questions.component.html',
  styleUrls: ['./secret-questions.component.css']
})
export class SecretQuestionsComponent implements OnInit {
  modal1 = false;
  modal2 = false;
  modal3 = false;
  modal4 = false;
  questions: any[];
  question1: any;
  answer1: any;
  question2: any;
  answer2: any;
  question3: any;
  answer3: any;
  securities: any;
  email = 'email';
  sms = 'sms';
  code: any;
  hide: boolean = true;
  hide_2: boolean = true;
  hide_3: boolean = true;
  hide_4: boolean = true;
  hide_5: boolean = true;
  hide_6: boolean = true;
  textDir: string = 'ltr';


  constructor(private authService: AuthLoginService, private toastr: ToastrService,private activeModal:NgbActiveModal) {
    if(localStorage.getItem('aydoLang')) {
     
      if(localStorage.getItem('aydoLang') == 'ar') {
        this.textDir = 'rtl'
      }
      else {
        this.textDir = 'ltr'
      }
    }
  }

  ngOnInit() {
    this.getQuestions();
    this.fetchSecret();
  }

  getQuestions() {
    this.authService.getQuestions().subscribe((data: any[]) => {
      this.questions = data;
    }, error => {
      console.log(error);
    });
  }

  fetchSecret() {
    this.authService.fetchSecrets().subscribe((data: any) => {
        this.securities = data;
        if (this.securities.q1.id) {
          this.modal2 = true;
          this.question1 = this.securities.q1;
          this.answer1 = this.securities.q1_answer;
          this.question2 = this.securities.q2;
          this.answer2 = this.securities.q2_answer;
          this.question3 = this.securities.q3;
          this.answer3 = this.securities.q3_answer;
        }
        else {
          this.modal1 = true;
        }
      },
      error => {
        if(error.status === 422) {
          this.modal1 = true;
         }
      });
  }

  getCode(method_code) {
    const method = {
      method: method_code
    };
    this.authService.sendMethod(method).subscribe((data: any) => {
      this.toastr.success(data.message);
      this.modal2 = false;
      this.modal3 = true;
    }, error => {
      this.toastr.error(error.error.errors);
    });
  }
  toggleField(type:any) {
    if(type == 1) {
      this.hide = !this.hide;
    
    }
    if(type == 2) {
      this.hide_2 = !this.hide_2;
    
    }
    if(type == 3) {
      this.hide_3 = !this.hide_3;
    
    }
    if(type == 4) {
      this.hide_4 = !this.hide_4;
    
    }
    if(type == 5) {
      this.hide_5 = !this.hide_5;
    
    }
    if(type == 6) {
      this.hide_6 = !this.hide_6;
    
    }
  }
  saveQuestions() {
    const questions = {
      q1_id: this.question1.id,
      q1_answer: this.answer1,
      q2_id: this.question2.id,
      q2_answer: this.answer2,
      q3_id: this.question3.id,
      q3_answer: this.answer3,
    };
    this.authService.postQuestions(questions).subscribe(data => {
      this.toastr.success('Secret questions has been added');
      this.modal1 = false;
      this.activeModal.dismiss('Cross click');
    }, error => {
      this.toastr.error(error.error.errors);
    });
  }

  updateQuestions() {
    const questions = {
      q1_id: this.question1.id,
      q1_answer: this.answer1,
      q2_id: this.question2.id,
      q2_answer: this.answer2,
      q3_id: this.question3.id,
      q3_answer: this.answer3,
      code: this.code,
    };
    this.authService.updateQuestions(questions).subscribe(data => {
      this.toastr.success('Secret questions has been updated');
      this.modal4 = false;
      this.modal2 = true;
    }, error => {
      this.toastr.error(error.error.errors);
    });
  }

  verify() {
    this.modal3 = false;
    this.modal4 = true;
  }

}
