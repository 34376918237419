import {Component, EventEmitter, Injectable, OnDestroy, OnInit, ViewChild, TemplateRef, Renderer2} from '@angular/core';
import {Subject} from "rxjs/index";
import {NgbModal, NgbModalOptions, NgbTimeAdapter, NgbTimeStruct, ModalDismissReasons} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {JobService} from "../../../services/job.service";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/internal/operators";
import {FileUploader} from "ng2-file-upload";
import {DocumentService} from "../../../services/document.service";
import {LocationService} from "../../../services/location.service";
import {CategoryModalService} from "../../../services/category-modal.service";
import {CategoryModalComponent} from "../category-modal/category-modal.component";
import {LocationModalComponent} from "../../location-modal/location-modal.component";
import { IdBadgeService } from 'src/app/services/id-badge.service';
import { IdBadgesModalComponent } from '../../id-badges-modal/id-badges-modal.component';
declare var gtag

@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.css'],
})
export class EditJobComponent implements OnInit, OnDestroy {
  @ViewChild("content") modalContent: TemplateRef<any>;
  closeResult:any;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  category: any;
  selectService: any;
  get_category: any;
  get_service: any;
  job_type: any;
  job_title: any;
  description: any;
  location: any;
  address: any;
  budget: any;
  datePicker: any;
  minDate: any;
  maxDate:any;
  time: any;
  serviceId: any;
  docArray: any[] = [];
  videoArray: any[] = [];
  imageArray: any[] = [];
  job_ststus = 1;
  checkCategoryStatus: any;
  docUploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['pdf', 'image']

  });
  videoUploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedMimeType: ['video/mp4']

  });
  imgUploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['image']

  });
  private ngUnsubscribe: Subject<any> = new Subject();
  loader = false;
  jobId: any;
  jobDetail: any;
  currentDate:any;
  budgetLimit = false;
  badges:any;
  emirates_id_status:any;
  userData:any;
  user_info:any;
  rePost = false;
  selectJobType = false;
  post_job:any;
  currency = 'USD';
  getCurrency:any;
  selectedCurrency:any;
  min_job_budget:any;
  max_job_budget:any;
  partial_payment:any;
  constructor(private route: ActivatedRoute, private jobService: JobService, private toaster: ToastrService,
              private modalService: NgbModal, private categoryService: CategoryModalService, private locationService: LocationService,
              private documentService: DocumentService, private router: Router,private badgeService:IdBadgeService,
              private renderer: Renderer2) {
                this.renderer.addClass(document.body, 'job-modal-questions');

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      params => {
        if(params['new'] == 'new') {
          this.rePost = true;
        }
        this.jobId = params['id']
      }
    );
    this.minDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
    this.maxDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 2,
      day: new Date().getDate()
    };
    this.categoryService.getCategory().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.category = data.category.name;
      this.selectService = data.service.name;
      this.serviceId = data.service.id;
      this.get_category = data.category;
      this.selectJobType = data.service.online_enabled;

    });
    this.locationService.getLocation().pipe(takeUntil(this.ngUnsubscribe)).subscribe(location => {
      this.location = location;
      this.address = location.address;
    });
  }

  ngOnInit() {
    this.getDetail();
    this.getIdBadges();
    this.user_info = localStorage.getItem('aydo-app-user');
    this.userData = JSON.parse(this.user_info);
    if(this.rePost) {
      this.selectedCurrency = this.userData.currency;
      this.checkCurrency();
      if(this.userData.currency != this.currency) {
        this.getCurrency = this.userData.currency;
      }
      
    }
    this.currentDate = new Date();
  }
  checkCurrency() {
    this.jobService.getAmmountLimit(this.selectedCurrency).subscribe((data:any) => {
     this.min_job_budget = Math.floor(data.min_job_budget);
     this.max_job_budget = Math.floor(data.max_job_budget);
    })
  }
  checkLimit() {
    if(this.budget < this.min_job_budget || this.budget > this.max_job_budget) {
      this.budgetLimit = true;
    }
    else {
      this.budgetLimit = false;
    }
  }
  getIdBadges() {
    this.badgeService.getIdBadges().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.badges = res;
      this.emirates_id_status = this.badges['emirates_id_status'];
    }
    );
  }
  getDetail() {
    this.loader = true;
    this.jobService.getDetailJob(this.jobId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.jobDetail = data;
        this.selectedCurrency = this.jobDetail.currency;
        this.partial_payment = this.jobDetail.allow_partial_payment;
        if(this.selectedCurrency) {
          if(this.jobDetail.currency != this.currency) {
            this.getCurrency = this.userData.currency;
          }
         this.checkCurrency();

        }
        if(this.jobDetail.service) {
        this.category = this.jobDetail.service.category.name;
        this.selectService = this.jobDetail.service.name;
        this.serviceId = this.jobDetail.service.id;
        this.get_category = this.jobDetail.service.category;
        }
        this.job_type = this.jobDetail.job_type;
        this.job_title = this.jobDetail.title;
        this.description = this.jobDetail.description;
        if (this.jobDetail.location !== null) {
          this.location = this.jobDetail.location;
          this.address = this.jobDetail.location.address;
        }
        this.budget = this.jobDetail.budget;
         this.job_ststus = this.jobDetail.status;
        if(this.jobDetail.schedule) {
        const dateFormatr = new Date(this.jobDetail.schedule);
        const date = dateFormatr.getDate();
        const month = dateFormatr.getMonth() + 1;
        const year = dateFormatr.getFullYear();
        this.datePicker = {
          year: year,
          month: month,
          day: date
        };
        const hour = dateFormatr.getHours();
        const minutes = dateFormatr.getMinutes();
        const seconds = dateFormatr.getSeconds();
        this.time = {
          hour: hour,
          minute: minutes,
          second:seconds
        };
      }
        this.docUploader.queue = this.jobDetail.docs;
        for (const docUploader of this.jobDetail.docs) {
          const docId = docUploader.id;
          this.docArray.push(docId);
        }
        this.imgUploader.queue = this.jobDetail.images;
        for (const imgUploader of this.jobDetail.images) {
          const docId = imgUploader.id;
          this.imageArray.push(docId);
        }
        this.videoUploader.queue = this.jobDetail.videos;
        for (const videoUploader of this.jobDetail.videos) {
          const docId = videoUploader.id;
          this.videoArray.push(docId);
        }
        this.loader = false;
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }

  removeDoc(value) {
    const index = this.docUploader.queue.indexOf(value.id);
    const index2 = this.docArray.indexOf(value.id);
    this.docUploader.queue.splice(index, 1);
    this.docArray.splice(index2, 1);
  }

  removeIntro(value) {
    const index = this.videoUploader.queue.indexOf(value.id);
    const index2 = this.videoArray.indexOf(value.id);
    this.videoUploader.queue.splice(index, 1);
    this.videoArray.splice(index2, 1);
  }

  removeCert(value) {
    const index = this.imgUploader.queue.indexOf(value.id);
    const index2 = this.imageArray.indexOf(value.id);
    this.imgUploader.queue.splice(index, 1);
    this.imageArray.splice(index2, 1);
  }

  open(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(CategoryModalComponent, this.ngbModalOptions);
    this.checkCategoryStatus = false;
    this.sendService();
  }

  serviceOpen(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(CategoryModalComponent, this.ngbModalOptions);
    if (this.category || this.selectService) {
      this.checkCategoryStatus = true;
      this.sendService();
    }
  }

  sendService(): void {
    const checkStatus = {
      category: this.get_category,
      status: this.checkCategoryStatus
    };
    this.categoryService.sendStatus(checkStatus);
  }

  openLocation(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(LocationModalComponent, this.ngbModalOptions);
  }

  draftJob() {
    this.job_ststus = 0;
    if(!this.rePost) {
    this.updateJob(0);
    }
    if(this.rePost) {
      this.createJob();
    }
  }

  public onDocSelected(event: EventEmitter<File[]>) {
  }

  public onVideoSelected(event: EventEmitter<File[]>) {
  }

  public onImageSelected(event: EventEmitter<File[]>) {
  }

  async updateJob(s:any) {
    gtag('event', 'update', {
      'event_category' : 'Job',
      'event_label' : 'job_update'
    });
    if(s == 1) {
      this.job_ststus = 1
    }
    else {
     this.job_ststus = 0;
    }
    this.loader = true;
    for (const docupload of this.docUploader.queue) {
      if (docupload.file) {
        const file: any = docupload.file.rawFile;
        await new Promise((resolve, reject) => {
          this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.docArray.push(res.id);
              resolve();
            }, error => {
              console.log('changePicture error', error);
            });
        });
      }
    }
    for (const videoUpload of this.videoUploader.queue) {
      if (videoUpload.file) {
        const file: any = videoUpload.file.rawFile;
        await new Promise((resolve, reject) => {
          this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.videoArray.push(res.id);
              resolve();
            }, error => {
              console.log('changePicture error', error);
            });
        });
      }
    }
    for (const imgUpload of this.imgUploader.queue) {
      if (imgUpload.file) {
        const file: any = imgUpload.file.rawFile;
        await new Promise((resolve, reject) => {
          this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.imageArray.push(res.id);
              resolve();
            }, error => {
              console.log('changePicture error', error);
            });
        });
      }
    }
      if(this.datePicker) {
        var dateFormatr = new Date(this.datePicker.year, this.datePicker.month - 1, this.datePicker.day,
          this.time.hour,this.time.minute,this.time.second);
        }
    if (this.job_type === 1) {
      this.location = null;
    } 
    const getDate = new Date(dateFormatr);
    var endDate = new Date(getDate).getTime();
    var startingDate = new Date(this.currentDate).getTime();
     var diffMs = endDate - startingDate;
     let diffHrs = diffMs / 3600000; // hou
 
       if(diffHrs <= 3) {
         this.toaster.error("Please select three hours later of current time");
         this.loader = false;
       }
     
      else {
        this.loader = false;
   
    if(!dateFormatr) {
      this.post_job = {
        title: this.job_title,
        description: this.description,
        job_type: this.job_type,
        budget: this.budget,
        service_id: this.serviceId,
        location: this.location,
        image_ids: this.imageArray,
        video_ids: this.videoArray,
        doc_ids: this.docArray,
        status: this.job_ststus,
        currency:this.selectedCurrency,
        allow_partial_payment:this.partial_payment

    }
  }
  if(dateFormatr) {
   this.post_job = {
  title: this.job_title,
  description: this.description,
  job_type: this.job_type,
  schedule: dateFormatr.toISOString(),
  budget: this.budget,
  service_id: this.serviceId,
  location: this.location,
  image_ids: this.imageArray,
  video_ids: this.videoArray,
  doc_ids: this.docArray,
  status: this.job_ststus,
  currency:this.selectedCurrency,
  allow_partial_payment:this.partial_payment


};
}
    this.jobService.updateJob(this.post_job, this.jobId).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader = false;
        this.toaster.success('Job has been Updated!');
        setTimeout(() => {
          this.router.navigate(['/my-jobs']);
        }, 2000);

      }, error => {
        if(error.error.code == 3) {
          this.router.navigate(['/edit-profile']);
          this.loader = false;
        }
        if(error.error.code == 4) {
          this.modalService.open(IdBadgesModalComponent,this.ngbModalOptions);
          this.loader = false;
        }
        if(error.error.code == 7) {
          this.router.navigate(['/edit-profile'])
        }
        this.toaster.error(error.error.errors);
      });
  }
}
 async createJob() {
  gtag('event', 'post', {
    'event_category' : 'Job',
    'event_label' : 'job_post'
  });
  this.loader = true;
  this.job_ststus = 1;

    for (const docupload of this.docUploader.queue) {
      if (docupload.file) {
        const file: any = docupload.file.rawFile;
        await new Promise((resolve, reject) => {
          this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.docArray.push(res.id);
              resolve();
            }, error => {
              console.log('changePicture error', error);
            });
        });
      }
    }
    for (const videoUpload of this.videoUploader.queue) {
      if (videoUpload.file) {
        const file: any = videoUpload.file.rawFile;
        await new Promise((resolve, reject) => {
          this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.videoArray.push(res.id);
              resolve();
            }, error => {
              console.log('changePicture error', error);
            });
        });
      }
    }
    for (const imgUpload of this.imgUploader.queue) {
      if (imgUpload.file) {
        const file: any = imgUpload.file.rawFile;
        await new Promise((resolve, reject) => {
          this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.imageArray.push(res.id);
              resolve();
            }, error => {
              console.log('changePicture error', error);
            });
        });
      }
    }

    if(this.datePicker) {
      var dateFormatr = new Date(this.datePicker.year, this.datePicker.month - 1, this.datePicker.day,
        this.time.hour,this.time.minute,this.time.second);
      }
    if (this.job_type === 1) {
      this.location = null;
    } 
    const getDate = new Date(dateFormatr);
    var endDate = new Date(getDate).getTime();
    var startingDate = new Date(this.currentDate).getTime();
     var diffMs = endDate - startingDate;
    // let diffHrs = Math.floor((diffMs % 86400000) / 3600000); 
    let diffHrs = diffMs / 3600000; // hou

    

      if(diffHrs <= 3) {
        this.toaster.error("Please select three hours later of current time");
        this.loader = false;
      }
      // else if(this.emirates_id_status != 2 && this.job_ststus == 1) {
      //   this.toaster.error("Please verify your `National Id` before posting a task");
      //   this.modalService.open(IdBadgesModalComponent,this.ngbModalOptions);
        
      //   this.loader = false;
      // }
      // else if(this.userData.profile.post_jobs == false && this.job_ststus == 1) {
      //   this.toaster.error("Make Sure You Selected `Post Task` option before posting a task");
      //   this.router.navigate(['/edit-profile'])

      //   this.loader = false;
      // }
      else {
        this.loader = false;
        if(!dateFormatr) {
          this.post_job = {
            title: this.job_title,
            description: this.description,
            job_type: this.job_type,
            budget: this.budget,
            service_id: this.serviceId,
            location: this.location,
            image_ids: this.imageArray,
            video_ids: this.videoArray,
            doc_ids: this.docArray,
            status: this.job_ststus,
            currency:this.selectedCurrency,
            allow_partial_payment:this.partial_payment
            

        }
      }
      if(dateFormatr) {
       this.post_job = {
      title: this.job_title,
      description: this.description,
      job_type: this.job_type,
      schedule: dateFormatr.toISOString(),
      budget: this.budget,
      service_id: this.serviceId,
      location: this.location,
      image_ids: this.imageArray,
      video_ids: this.videoArray,
      doc_ids: this.docArray,
      status: this.job_ststus,
      currency:this.selectedCurrency,
  allow_partial_payment:this.partial_payment


    };
  }
    this.jobService.postJob(this.post_job).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader = false;
        if(this.job_ststus == 0) {
          this.toaster.success('Job has been drafted');
          setTimeout(() => {
            this.router.navigate(['/my-jobs']);
          }, 1000);
        }
        if(this.job_ststus == 1) {
        this.cancelModal(this.modalContent);
        }

      }, error => {
        if(error.error.code == 3) {
          this.router.navigate(['/edit-profile']);
          this.loader = false;
        }
        if(error.error.code == 4) {
          this.modalService.open(IdBadgesModalComponent,this.ngbModalOptions);
          this.loader = false;
        }
        if(error.error.code == 7) {
          this.router.navigate(['/edit-profile'])
        }
        this.toaster.error(error.error.errors);
      });
  }
}

cancelModal(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
   
  }, (reason) => {
    setTimeout(() => {
      this.router.navigate(['/my-jobs']);
    }, 1000);
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'job-modal-questions');

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
