import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { WalletService } from 'src/app/services/wallet.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { WalletMethodComponent } from '../wallet-method/wallet-method.component';
import { QuestionModalService } from 'src/app/services/question-modal.service';
import { TransactionHistoryComponent } from '../transaction-history/transaction-history.component';

@Component({
  selector: 'app-request-with-drawal',
  templateUrl: './request-with-drawal.component.html',
  styleUrls: ['./request-with-drawal.component.css']
})
export class RequestWithDrawalComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  methodList: any[] = [];
  loader = false;
  withDrawalDetail:any;
  amount:any;
  amountLimit = false;
  selectMethod: any;
  modal1 = false;
  modal2 = false;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  methodLength = false;
  textDir: string = 'ltr';
  constructor(private renderer: Renderer2, private walletService: WalletService, private toastr: ToastrService,
     public activeModal: NgbActiveModal,private modalService:NgbModal,private questionModalService:QuestionModalService) {
      if(localStorage.getItem('aydoLang')) {
     
        if(localStorage.getItem('aydoLang') == 'ar') {
          this.textDir = 'rtl'
        }
        else {
          this.textDir = 'ltr'
        }
      }
      this.renderer.addClass(document.body, 'portfolio');
      this.questionModalService.getMessage().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        if (data === 'success') {
          this.get_withdrawl_methods('','');
          this.methodList = [];
        }
      });
      }

  ngOnInit() {
    this.getRequest();
    this.get_withdrawl_methods('','');
    this.modal1 = true;
  }
  getRequest() {
    this.loader = true;
    this.walletService.getWithDrawalRequest().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.withDrawalDetail = data;
      this.loader = false;
    },
    error => {
      this.toastr.error(error.error.errors);
    }
    );
  }
get_withdrawl_methods(page, time) {
    this.loader = true;
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.walletService.getWithDrawalMethods(page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.methodList = this.methodList.concat(data['withdrawal_methods']);
      this.loader = false;
      if(this.methodList.length == 0) {
        this.methodLength = true;
      }
      else if(this.methodList.length > 0) {
        this.methodLength = false;
      }

    }, error => {
      this.toastr.error(error.error.errors);
    }
    );
  }
  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + 10 + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.get_withdrawl_methods(this.currentPage, this.timeStamp);
      }
    }
  }
  checkLimit(maxValue,minValue) {
   
    const max = (maxValue | 0);
    const min = (minValue | 0);
    if(this.amount < min || this.amount > max) {
      this.amountLimit = true;
    }
    else {
      this.amountLimit = false;
    }
  }
  select_method(list) {
    this.selectMethod = list;
  }
  withdrawFunds()  {
   
    const info = {
      amount:this.amount,
      withdrawal_id: this.selectMethod.id
    };
   
    this.walletService.withDraw(info).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        
        this.modal1 = false;
        this.modal2 = true;
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );

  }
  closeModal() {
    this.activeModal.dismiss("Cross Click");
  }
  goToModal() {
    const modalRef = this.modalService.open(WalletMethodComponent,this.ngbModalOptions);
  }
  withdrawRequest() {
    this.activeModal.dismiss('Cross Click');
    const modalRef= this.modalService.open(TransactionHistoryComponent,this.ngbModalOptions);
    modalRef.componentInstance.requestStatus = '1';
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'portfolio');
  }
}
