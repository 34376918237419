import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-support-articles',
  templateUrl: './support-articles.component.html',
  styleUrls: ['./support-articles.component.css']
})
export class SupportArticlesComponent implements OnInit {
  catId:any;
  private ngUnsubscribe: Subject<any> = new Subject();
  faqsDetail:any;
  query:any;
  constructor(private router:ActivatedRoute,private sharedService:SharedService,private renderer: Renderer2) {
    this.router.params.subscribe((params) => 
    {
      this.catId = params['id'];
    });
   
   this.renderer.addClass(document.body, 'support-center');
   }

   ngOnInit() {
    this.getSupportDetail();
  }
  getSupportDetail() {
    let params ={
      query: this.query
    }
   this.sharedService.getFaqsCategoryDetail(this.catId,params).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
     (data:any) => {
   
       this.faqsDetail = data;
       if(this.faqsDetail) {
       this.router.fragment.pipe(takeUntil(this.ngUnsubscribe),debounceTime(500)).subscribe((fragment: string) => {
        if (fragment && document.getElementById(fragment) != null) {
          document.getElementById(fragment).scrollIntoView({ behavior: 'smooth' });
        }
      });
     }
    }
   )
  }
  scrollToElement(id): void {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior: "smooth"});
    
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'support-center');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
