import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {NgbActiveModal, NgbTabsetConfig, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {Subject} from "rxjs/index";
import {ToastrService} from "ngx-toastr";
import {AuthLoginService} from "../auth.service";
import {takeUntil} from "rxjs/internal/operators";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  countries: any;
  selectCountry: any;
  phone: number;
  code: any;
  email: any;
  modal1 = false;
  modal2 = false;
  show = false;
  newPassword: any;
  pin: any;
  resendPinEnable: boolean = false;
  resendPinDisabled: boolean = false;
  timer: number = 60;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  constructor(config: NgbTabsetConfig, private renderer: Renderer2, private authService: AuthLoginService,
              private toastr: ToastrService, public activeModal: NgbActiveModal) {
    config.justify = 'center';
    config.type = 'pills';
    this.renderer.addClass(document.body, 'forgot-password');
  }

  ngOnInit() {
    this.fetchCountries();
    this.modal1 = true;
  }

  fetchCountries() {
    this.authService.getCountries().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      res => {
        this.countries = res;
        for( let country of this.countries){
           if(country.country_code == "971" ){
            this.selectCountry = country;
           }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  sendRequestPhone(query) {
    const code = this.selectCountry.country_code;
    const params = {
      auth: query,
      country_code: code
    };
    this.authService.restPasswordMsg(params).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res: any) => {
        localStorage.setItem('forget-request-obj', JSON.stringify(params));
        this.toastr.success('', res.message,{ timeOut: 30000 });
        this.modal1 = false;
        this.modal2 = true;
        this.resendPinEnable = true;
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  sendRequestEmail(query) {
    const params = {
      auth: query
    };
    this.authService.restPasswordMsg(params).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res: any) => {
        this.toastr.success('', res.message, { timeOut: 30000 });
        this.modal1 = false;
        this.modal2 = true;
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  sendRequestPin() {
    this.resendPinDisabled = true;
    const params = localStorage.getItem('forget-request-obj');
    this.authService.restPasswordMsg(params).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res: any) => {
        this.toastr.success('', res.message, { timeOut: 30000 });
        this.modal1 = false;
        this.modal2 = true;
        const intervalId = setInterval(() => {
          if (this.timer > 0) {
            this.timer--;
          } else {
            clearInterval(intervalId); // Stop the timer
            this.resendPinDisabled = false; 
          }
        }, 1000); 
      },
      error => {
        this.toastr.error(error.error.errors);
        this.resendPinDisabled = false; 
      }
    );
  }
  

  resetPassword() {
    const resetPassword = {
      pin: this.pin,
      password: this.newPassword
    };
    this.authService.restPassword(resetPassword).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res: any) => {
        this.toastr.success(res.message);
        localStorage.removeItem('forget-request-obj');
        this.resendPinEnable = false;
        this.activeModal.dismiss('Cross Click');
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  nPassword() {
    this.show = !this.show;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'forgot-password');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
