import {Component, OnInit} from '@angular/core';
import {AuthLoginService} from "../../auth/auth.service";
import {NationalityService} from "../../services/nationality.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-nationality',
  templateUrl: './nationality.component.html',
  styleUrls: ['./nationality.component.css']
})
export class NationalityComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  textDir: string = 'ltr';

  countries: any;
  country: any;

  constructor(private authService: AuthLoginService, private countryService: NationalityService) {
    if(localStorage.getItem('aydoLang')) {
     
      if(localStorage.getItem('aydoLang') == 'ar') {
        this.textDir = 'rtl'
      }
      else {
        this.textDir = 'ltr'
      }
    }
    this.countryService.getCountry().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.country = data;
        });
  }

  ngOnInit() {
    this.fetchCountries();
  }

  fetchCountries() {
    this.authService.getNationalities().subscribe(
      res => {
        this.countries = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  sendCountry() {
    this.countryService.sendCountry(this.country);
  }

}
