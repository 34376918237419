import {Component, EventEmitter, HostListener, OnDestroy, OnInit, Renderer2, Input} from '@angular/core';
import {Subject} from "rxjs/index";
import {JobService} from "../../services/job.service";
import {ToastrService} from "ngx-toastr";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {takeUntil} from "rxjs/internal/operators";
import {Lightbox} from 'ngx-lightbox';
import {FileUploader} from "ng2-file-upload";
import {DocumentService} from "../../services/document.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-disputes-modal',
  templateUrl: './disputes-modal.component.html',
  styleUrls: ['./disputes-modal.component.css']
})
export class DisputesModalComponent implements OnInit, OnDestroy {
  textDir: string = 'ltr';
  @Input() disputeNotification:any;
  modal1 = false;
  modal2 = false;
  modal3 = false;
  modal4 = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  status = '0';
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  loader = false;
  disputesList: any[] = [];
  userInfo: any;
  is_mine: any;
  userId: any;
  disputeInfo: any;
  _album: any[] = [];
  _responseAlbum: any[] = [];
  noAvailable = false;
  docuploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['pdf', 'image']

  });
  content: any;
  docArray: any[] = [];
  responseList: any[] = [];
  noDisputes = false;
  lang:any;

  constructor(private jobService: JobService, private toaster: ToastrService, public activeModal: NgbActiveModal,
              private renderer: Renderer2, private _lightbox: Lightbox, private documentService: DocumentService,
              private translate:TranslateService) {
                if(localStorage.getItem('aydoLang')) {
     
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                  }
                  else {
                    this.textDir = 'ltr'
                  }
                }
    this.renderer.addClass(document.body, 'job-modal-questions');
  }

  ngOnInit() {
    if(localStorage.getItem('aydoLang')) {
      this.lang = localStorage.getItem('aydoLang');
       
     }
    if(this.disputeNotification) {
      this.getSingleDispute(this.disputeNotification);
      
    }
    else {
    this.modal1 = true;
    this.getDisputes('', '', this.status);
    
    }
    this.userInfo = localStorage.getItem('aydo-app-user');
    const user_info = JSON.parse(this.userInfo);
    this.userId = user_info.id;
  }

  getDisputes(page, time, status) {
    this.jobService.getDisputes(page, time, status)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.disputesList = this.disputesList.concat(data['disputes']);
      this.loader = false;
      if (this.disputesList.length === 0) {
        this.noDisputes = true;
      }
      if (this.disputesList.length > 0) {
        this.noDisputes = false;
      }

    });
  }
getSingleDispute(id) {
  this.jobService.getDisputeDetail(id).subscribe((data:any) => {
    this.detailDispute(data);
  })
}
  sendStatus() {
    this.disputesList = [];
    this.getDisputes('', '', this.status);
  }

  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.getDisputes(this.currentPage, this.timeStamp, this.status);
      }
    }
  }
  backList() {
    this.modal1 = true;
    this.modal2 = false;
    this.disputesList = [];
    this.getDisputes('', '', this.status);
  }
  detailDispute(info) {
    this.modal1 = false;
    this.modal2 = true;
    this.disputeInfo = '';
    this._album = [];
    this.disputeInfo = info;
    for (const img of this.disputeInfo.docs) {
      if (img.content_type !== 'application/pdf') {
        const src = img.url;
        const caption = '';
        const thumb = img.url;
        const album = {
          src: src,
          caption: caption,
          thumb: thumb
        };

        this._album.push(album);
      }
    }
    if (this._album.length === 0) {
      this.noAvailable = true;
    }
    this.jobService.getResponses(this.disputeInfo.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.responseList = data;
      }
    );
  }

  getAlbum(list) {
    const _album = [];
    for (const img of list) {
      if (img.content_type !== 'application/pdf') {
        const src = img.url;
        const caption = '';
        const thumb = img.url;
        const album = {
          src: src,
          caption: caption,
          thumb: thumb
        };

        _album.push(album);
      }
    }
    return _album;

  }

  open(index: number): void {
    this._lightbox.open(this._album, index);
  }

  openAlbum(list, index: number): void {
    this._lightbox.open(list, index);
  }


  addResponse() {
    this.modal2 = false;
    this.modal3 = true;
  }

  disputeToAdmin() {
    this.jobService.disputeAdmin(this.disputeInfo.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.translate.get('escalate_to_customer_service').subscribe((res: string) => {
          this.toaster.success(res);
        });
        const info = data;
        this.detailDispute(info);
      }
    );
  }

  public onDocSelected(event: EventEmitter<File[]>) {
  }

  async postResponse() {
    this.loader = true;
    for (const docupload of this.docuploader.queue) {
      const file: any = docupload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.docArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }
    const q = {
      content: this.content,
      doc_ids: this.docArray,
    };
    this.jobService.postResponse(q, this.disputeInfo.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.translate.get('done').subscribe((res: string) => {
          this.toaster.success(res);
        });
        this.loader = false;
        this.modal3 = false;
        this.modal2 = true;
        this.detailDispute(this.disputeInfo);
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }

  disputesEdit() {
    this.modal1 = false;
    this.modal4 = true;
  }

  delDispute(id) {
    this.jobService.deldispute(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.translate.get('done').subscribe((res: string) => {
          this.toaster.success(res);
        });
        this.disputesList = [];
        this.getDisputes('', '', this.status);
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }

  doneEdit() {
    this.modal4 = false;
    this.modal1 = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'job-modal-questions');
  }
}
