import {Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject} from "rxjs/index";
import {JobService} from "../../services/job.service";
import {QuestionModalService} from "../../services/question-modal.service";
import {takeUntil} from "rxjs/internal/operators";
import {ToastrService} from "ngx-toastr";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
declare var gtag

@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.css']
})
export class ReviewModalComponent implements OnInit, OnDestroy {
  textDir: string = 'ltr';
  @Input() rateDetail: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  rating: any;
  content: any;
  userId: any;
  tip:number;
  fieldShow = false;
  minTip = 5;
  amountLimit = false;
  constructor(private jobService: JobService, private questionModalService: QuestionModalService, private renderer: Renderer2,
              private toaster: ToastrService, public activeModal: NgbActiveModal) {
                if(localStorage.getItem('aydoLang')) {
     
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                  }
                  else {
                    this.textDir = 'ltr'
                  }
                }
    this.renderer.addClass(document.body, 'job-modal-questions');
  }

  ngOnInit() {
    if(this.rateDetail.jobDetail.min_tip) {
      this.minTip = this.rateDetail.jobDetail.min_tip
    }
  }

  postReview() {
    gtag('event', 'review', {
      'event_category' : 'Job',
      'event_label' : 'job_review'
    });
    const q = {
      content: this.content,
      rating: this.rating,
      job_id: this.rateDetail.jobDetail.id,
      review_type: this.rateDetail.review_type,
      tip_amount:this.tip
    };
    if (this.rateDetail.review_type === 1) {
      this.userId = this.rateDetail.jobDetail.owner.id;
    }
    if (this.rateDetail.review_type === 0) {
      this.userId = this.rateDetail.jobDetail.assignment.assigned_to.id;
    }
    this.jobService.postReview(q, this.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Review has been submitted');
        this.sendService();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  
  checkLimit() {
    if(this.tip < this.minTip) {
      this.amountLimit = true;
    }
    else {
      this.amountLimit = false;
    }
  }

  showField() {
    this.fieldShow = true;
    this.tip = null;
  }
  hideField() {
    this.fieldShow = false;
    this.tip = null;
  }
  sendService(): void {
    this.questionModalService.sendMessage('success');
    this.activeModal.dismiss('Cross click');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'job-modal-questions');
  }
}
