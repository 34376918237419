import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/internal/operators";
import { Subject } from "rxjs/index";
import { JobService } from "../../../services/job.service";
import { ToastrService } from "ngx-toastr";
import { Lightbox } from 'ngx-lightbox';
import { ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { QuestionModalComponent } from "../question-modal/question-modal.component";
import { QuestionModalService } from "../../../services/question-modal.service";
import { ReportModalComponent } from "../../report-modal/report-modal.component";
import { OffersModalComponent } from "../offers-modal/offers-modal.component";
import { PaymentMethodComponent } from "../../payment-method/payment-method.component";
import { PaymentModalService } from "../../../services/payment-modal.service";
import { CertificationModalComponent } from "../certification-modal/certification-modal.component";
import { CreateDisputeModalComponent } from "../create-dispute-modal/create-dispute-modal.component";
import { ReviewModalComponent } from "../../review-modal/review-modal.component";
import { FavouriteService } from 'src/app/services/favourite.service';
import { IdBadgeService } from 'src/app/services/id-badge.service';
import { SkillsComponent } from '../../skills/skills.component';
import { IdBadgesModalComponent } from '../../id-badges-modal/id-badges-modal.component';
import { PortfolioModalComponent } from '../../portfolio-modal/portfolio-modal.component';
declare var gtag

@Component({
  selector: 'app-detail-job',
  templateUrl: './detail-job.component.html',
  styleUrls: ['./detail-job.component.css']
})
export class DetailJobComponent implements OnInit, OnDestroy {
  questionDelId: any;
  modalOpen = false;
  jobId: any;
  jobDetail: any;
  userDetail: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  loader = false;
  _album: any[] = [];
  questionsList: any[] = [];
  questionCurrentPage: any;
  questionTimeStamp: any;
  questionPagination: any;
  moreQuestionsAvailable: boolean;
  offersList: any[] = [];
  offerCurrentPage: any;
  offerTimeStamp: any;
  offerPagination: any;
  moreOffersAvailable: boolean;
  noAvailable = false;
  modal1 = false;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  noQuestions = false;
  noOffers = false;
  userInfo: any;
  skillsList: any[] = [];
  isMatched = false;
  closeResult: string;
  completePercentage: any;
  currentDate: any;
  leftTime: any;
  completedJobMins: any;
  completedJobHrs: any;
  badges: any;
  emirates_id_status: any;
  userData: any;
  schedule: any;
  jobAcceptRate: any;
  assignmentUserId: any;
  attachLength: any;
  cancellationFeeRate: any;
  lang: any;
  amountPartial:any;
  max_job_budget:any;
  budgetLimit:boolean = false;
  replyId:any;
  attachmentsModal:any[] = [];
  constructor(private route: ActivatedRoute, private jobService: JobService, private toaster: ToastrService,
    private _lightbox: Lightbox, private modalService: NgbModal, private questionModalService: QuestionModalService,
    private paymentModalService: PaymentModalService, private router: Router, private renderer: Renderer2,
    public activeModal: NgbActiveModal, private routerR: Router, private favService: FavouriteService,
    private badgeService: IdBadgeService) {
    this.renderer.addClass(document.body, 'job-modal-questions');
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      params => this.jobId = params['id']
    );
    this.routerR.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.questionModalService.getMessage().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      if (data === 'success-job' || data === 'success') {
        this.ngOnInit();
      }
    });
    this.paymentModalService.getMessage().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      if (data === 'success') {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    if (localStorage.getItem('aydoLang')) {
      this.lang = localStorage.getItem('aydoLang');

    }
    this.offersList = [];
    this.questionsList = [];
    this._album = [];
    this.getDetail();
    this.getQuestions('', '');
    this.getOffers('', '');
    this.userDetail = localStorage.getItem('Authorization');
    this.userInfo = localStorage.getItem('aydo-app-user');
    this.userData = JSON.parse(this.userInfo);
    if (this.userInfo) {
      this.get_skills();
    this.getIdBadges();
    this.jobService.getAppSettings().subscribe((data: any) => {
      this.cancellationFeeRate = data['cancellation_fee_rate'];
    })

    }
    this.currentDate = new Date();
   
   

  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  getDetail() {
    this.loader = true;
    this.jobService.getDetailJob(this.jobId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.jobDetail = data;
        if(this.jobDetail.images) {
       
        }   for (const img of this.jobDetail.images) {
             this.attachmentsModal.push(img);
          }
        if(this.jobDetail.videos) {
          for(const video of this.jobDetail.videos) {
            this.attachmentsModal.push(video);
          }
        }
        if(this.jobDetail.assignment) {
          if(this.jobDetail.assignment.paid_amount > 0) {
            let budget = Math.floor(this.jobDetail.assignment.paid_amount);
            this.max_job_budget = Math.floor(this.jobDetail.price) - budget
          }
          else {
          this.max_job_budget = Math.floor(this.jobDetail.price);
          }
        }
        if (this.jobDetail.assignment) {
          this.assignmentUserId = this.jobDetail.assignment.assigned_to.id;
        }
        const imgLength = this.jobDetail.images.length;
        const videoLength = this.jobDetail.videos.length;
        const docLength = this.jobDetail.docs.length;
        this.attachLength = imgLength + videoLength + docLength;

        this.schedule = new Date(this.jobDetail.schedule);
        if (this.jobDetail.assignment != null) {
          const getDate = new Date(this.jobDetail.assignment.created_at);
          var endDate = new Date(getDate).getTime();
          var startingDate = new Date(this.currentDate).getTime();
          var newVar = 60 * 60 * 1000 + endDate;
          var diffMs = newVar - startingDate;
          let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
          this.leftTime = diffMins;
          if (this.leftTime < 0) {
            this.leftTime = 0;
          }

        }
        if (this.jobDetail.completed_on != null) {
          const getDate = new Date(this.jobDetail.completed_on);
          var endDate = new Date(getDate).getTime();
          var startingDate = new Date(this.currentDate).getTime();
          var newVar = 1440 * 60 * 1000 + endDate;
          var diffMs = newVar - startingDate;
          let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
          let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
          this.completedJobMins = diffMins;
          this.completedJobHrs = diffHrs;
          if (this.completedJobMins < 0) {
            this.completedJobMins = 0;
          }
          if (this.completedJobHrs < 0) {
            this.completedJobHrs = 0;
          }
        }
        this.completePercentage = Math.ceil(this.jobDetail.owner.completion_percentage);

        if (this.jobDetail.images.length === 0 && this.jobDetail.videos.length === 0 && this.jobDetail.docs.length === 0) {
          this.noAvailable = true;
        }
        this.loader = false;
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }

  getOffers(page, time) {
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.jobService.getOffers(this.jobId, page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.offerPagination = data['page_info'];
      this.offerCurrentPage = this.offerPagination.page;
      this.offerTimeStamp = this.offerPagination.timestamp;
      this.moreOffersAvailable = this.offerPagination.more_available;
      this.offersList = this.offersList.concat(data['offers']);
      if (this.offersList.length === 0) {
        this.noOffers = true;
      }
      if (this.offersList.length > 0) {
        this.noOffers = false;
      }

    }, error => {
      this.toaster.error(error.error.errors);
    }
    );
  }
  getIdBadges() {
    this.badgeService.getIdBadges().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.badges = res;
      this.emirates_id_status = this.badges['emirates_id_status'];
    }
    );
  }
  getQuestions(page, time) {
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.jobService.getQuestions(this.jobId, page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.questionPagination = data['page_info'];
      this.questionCurrentPage = this.questionPagination.page;
      this.questionTimeStamp = this.questionPagination.timestamp;
      this.moreQuestionsAvailable = this.questionPagination.more_available;
      this.questionsList = this.questionsList.concat(data['questions']);
      if (this.questionsList.length === 0) {
        this.noQuestions = true;
      }
      if (this.questionsList.length > 0) {
        this.noQuestions = false;
      }

    }, error => {
      this.toaster.error(error.error.errors);
    }
    );
  }

  get_skills() {
    this.jobService.getSkills().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.skillsList = data['skills'];
    }, error => {
      this.toaster.error(error.error.errors);
    }
    );
  }

  acceptJob() {
    this.loader = true;
    gtag('event', 'accept_offer', {
      'event_category': 'Job',
      'event_label': 'job_accept_offer'
    });
    this.jobService.acceptJob(this.jobDetail.assignment.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Success');
        this.modalService.dismissAll('Cross click');
        this.loader = false;
        this.ngOnInit();
      },
      error => {
        this.loader = false;

        this.toaster.error(error.error.errors);
      }
    );
  }

  completeJob() {
    gtag('event', 'mark_as_complete', {
      'event_category': 'Job',
      'event_label': 'job_mark_as_complete'
    });
    this.jobService.completeJob(this.jobDetail.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Success');
        this.ngOnInit();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }

  payReleased() {
    this.jobService.payReleased(this.jobDetail.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Success');
        const reviewType = 0;
        const reviewInfo = {
          review_type: reviewType,
          jobDetail: this.jobDetail
        };
        const modalRef = this.modalService.open(ReviewModalComponent, this.ngbModalOptions);
        modalRef.componentInstance.rateDetail = reviewInfo;
        this.ngOnInit();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }

  disputes() {
    const modalRef = this.modalService.open(CreateDisputeModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.jobInfo = this.jobDetail;
  }
  checkLimit() {
    if(this.amountPartial < 1 || this.amountPartial > this.max_job_budget) {
      this.budgetLimit = true;
    }
    else {
      this.budgetLimit = false;
    }
  }
  declineJob() {
    gtag('event', 'reject_job', {
      'event_category': 'Job',
      'event_label': 'job_reject_job'
    });
    this.jobService.declineJob(this.jobDetail.assignment.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Success');
        this.modalService.dismissAll('Cross click');

        this.ngOnInit();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }

  openMap() {
    window.open('https://www.google.com/maps/search/?api=1&query='
      + this.jobDetail.location.lat + ',' + this.jobDetail.location.lng, '_blank');
  }

  openModal1() {
    const modalRef = this.modalService.open(QuestionModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.jobId = this.jobId;
  }

  rate(int) {
    const reviewType = int;
    const reviewInfo = {
      review_type: reviewType,
      jobDetail: this.jobDetail
    };
    const modalRef = this.modalService.open(ReviewModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.rateDetail = reviewInfo;
  }
  openChatBox(id) {
    localStorage.setItem('chatId', id);
    this.router.navigate(['/messages']);
  }
  aydoJob() {

    //  let isSkilledFound = false;
    //     for (const list of this.skillsList) {

    //       if (list.service.id == this.jobDetail.service.id) {


    //         isSkilledFound = true;
    //         if (this.jobDetail.service.certification_required === true && list.is_certified && list.certification_status === 1){
    //           this.isMatched = true;
    //         } else if(this.jobDetail.service.certification_required == false){
    //           this.isMatched = true
    //         }
    //       }
    //     }
    //     if (isSkilledFound == true  && this.isMatched == false) {
    //       this.toaster.error('The jobs require certified professionals only.')
    //       const modelRef = this.modalService.open(SkillsComponent,this.ngbModalOptions);
    //       modelRef.componentInstance.detailJob = 'detail-job';
    //       // this.modalService.open(CertificationModalComponent, this.ngbModalOptions);
    //     }
    //     else if (isSkilledFound == false){
    //       this.toaster.error('Skill is not found, Please add this skill to apply for this job');
    //       const modelRef = this.modalService.open(SkillsComponent,this.ngbModalOptions);
    //       modelRef.componentInstance.detailJob = 'detail-job';
    //     }

    //   else if ( this.isMatched == true && isSkilledFound) {
    //     if(this.emirates_id_status != 2) {
    //       this.toaster.error("Please verify your `National Id` before apply for this job");
    //       this.modalService.open(IdBadgesModalComponent,this.ngbModalOptions);
    //       this.loader = false;
    //     }
    //     else if(this.userData.profile.make_money == false) {
    //       this.toaster.error("Make Money is not selected in profile");
    //        this.router.navigate(['/edit-profile'])
    //     }
    //     else {
    //     const modalRef = this.modalService.open(OffersModalComponent, this.ngbModalOptions);
    //     modalRef.componentInstance.jobId = this.jobId;
    //     }
    //   }
    const jobData = {
      jobId: this.jobId,
      currency: this.jobDetail.currency

    }
    const modalRef = this.modalService.open(OffersModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.jobId = jobData;
  }

  replyModal(id) {
    const replyInfo = {
      jobID: this.jobId,
      q_id: id
    };
    const modalRef = this.modalService.open(QuestionModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.replyInfo = replyInfo;
  }
  editQuestionModal(id, item: any) {
    const editInfo = {
      jobID: this.jobId,
      q_id: id,
      data: item
    };
    const modalRef = this.modalService.open(QuestionModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.editInfo = editInfo;
  }
  editReplyModal(id, item: any) {
    const editInfo = {
      jobID: this.jobId,
      q_id: id,
      data: item
    };
    const modalRef = this.modalService.open(QuestionModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.replyEditInfo = editInfo;
  }
  editOfferReplyModal(id, item: any) {
    const editInfo = {
      jobID: this.jobId,
      offer_id: id,
      data: item
    };
    const modalRef = this.modalService.open(OffersModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.replyEditInfo = editInfo;
  }
  openModal(list, index) {
    const info = {
      list: list,
      index: index,
      userId: this.userData.id
    };
    const modelRef = this.modalService.open(PortfolioModalComponent, this.ngbModalOptions);
    modelRef.componentInstance.jobAttachments = info;
  }
  openAttachmentModal(list, index) {
    const info = {
      list: list,
      index: index,
      userId: this.userData.id
    };
    const modelRef = this.modalService.open(PortfolioModalComponent, this.ngbModalOptions);
    modelRef.componentInstance.jobAttachments = info;
  }
  reportQuestionModal(id) {
    const repoInfo = {
      resourceType: 3,
      q_id: id
    };
    const modalRef = this.modalService.open(ReportModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.repoInfo = repoInfo;
  }

  reportReplyModal(id) {
    const repoInfo = {
      resourceType: 5,
      q_id: id
    };
    const modalRef = this.modalService.open(ReportModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.repoInfo = repoInfo;
  }

  jobReport(id) {
    const repoInfo = {
      resourceType: 0,
      q_id: id
    };
    const modalRef = this.modalService.open(ReportModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.repoInfo = repoInfo;
  }

  reportOfferModal(id) {
    const repoInfo = {
      resourceType: 2,
      q_id: id
    };
    const modalRef = this.modalService.open(ReportModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.repoInfo = repoInfo;
  }

  reportOfferReplyModal(id) {
    const repoInfo = {
      resourceType: 4,
      q_id: id
    };
    const modalRef = this.modalService.open(ReportModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.repoInfo = repoInfo;
  }

  replyOfferModal(id) {
    const replyInfo = {
      jobID: this.jobId,
      offer_id: id
    };
    const modalRef = this.modalService.open(OffersModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.replyInfo = replyInfo;
  }

  offerEditModal(list: any) {
    const editInfo = {
      info: list,
      jobId: this.jobId,
      currency: this.jobDetail.currency
    };
    const modalRef = this.modalService.open(OffersModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.editInfo = editInfo;
  }

  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreQuestionsAvailable === true) {
        this.questionCurrentPage++;
        this.getQuestions(this.questionCurrentPage, this.questionTimeStamp);
      }
    }
  }

  navigateOffer(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreOffersAvailable === true) {
        this.offerCurrentPage++;
        this.getOffers(this.offerCurrentPage, this.offerTimeStamp);
      }
    }
  }

  hasId(u_id: any) {
    return this.myClass(u_id);
  }

  myClass(u_id) {
    if (this.userInfo) {
      const user_info = JSON.parse(this.userInfo);
      if (u_id === user_info.id) {
        return true;
      }
    }
  }

  assign_job(id) {
    const jobInfo = {
      jobID: this.jobId,
      offer_id: id
    };
    const modalRef = this.modalService.open(PaymentMethodComponent, this.ngbModalOptions);
    modalRef.componentInstance.fromDetail = jobInfo;
  }

  deleteJob() {
    gtag('event', 'delete', {
      'event_category': 'Job',
      'event_label': 'job_delete'
    });
    this.jobService.deleteJob(this.jobId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Job has been deleted');
        setTimeout(() => {
          this.router.navigate(['/my-jobs']);
        }, 2000);
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  payPartial() {
    const params = {
    amount:this.amountPartial
    }
    this.jobService.partialPay(params,this.jobDetail.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Success');
        this.modalService.dismissAll('Cross click');
        this.amountPartial = '';
        this.ngOnInit();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  partialModal(content) {
    this.modalService.open(content,this.ngbModalOptions);
  }
  cancelModal(content) {
    this.modalService.open(content,this.ngbModalOptions);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  deleteModal(content, qId) {
    this.questionDelId = qId;
    this.modalService.open(content,this.ngbModalOptions);
  }
  deleteQuestionJob() {

    this.jobService.deleteQuestion(this.jobId, this.questionDelId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.modalService.dismissAll('Cross click');
        this.toaster.success('Deleted');
        this.ngOnInit();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  deleteModalReply(content,qId,rId) {
    this.replyId = rId;
    this.questionDelId = qId;
    this.modalService.open(content,this.ngbModalOptions);
  }
  deleteReplyJob() {

    this.jobService.deleteReply(this.jobId, this.questionDelId,this.replyId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.modalService.dismissAll('Cross click');
        this.toaster.success('Deleted');
        this.ngOnInit();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  cancelJob() {
    gtag('event', 'cancel', {
      'event_category': 'Job',
      'event_label': 'job_cancel'
    });
    this.jobService.cancelJob(this.jobId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.modalService.dismissAll('Cross click');
        this.toaster.success('Job has been cancelled');
        setTimeout(() => {
          this.router.navigate(['/all-jobs']);
        }, 2000);
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  declineModal(content) {
    this.modalService.open(content,this.ngbModalOptions);
  }

  private getDismissReasonDecline(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  acceptModal(content) {
    this.loader = true;
    this.jobService.getAppSettings().subscribe((data: any) => {
      this.loader = false;
      this.jobAcceptRate = data['aydo_share_completed'];
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReasonAccept(reason)}`;
      });
    })

  }

  private getDismissReasonAccept(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  addFavourite() {
    this.jobService.favJob(this.jobId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.modalService.dismissAll('Cross click');
        this.toaster.success('Job added to your favourites');
        this.ngOnInit();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  removeFavourite() {
    this.favService.delJob(this.jobId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.toaster.success('Favourite job has been deleted');
        this.ngOnInit();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  likes(item: any) {
   if(item.liked_by_me == true) {
     this.favService.unLikeQuestion(this.jobId,item.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.toaster.success('Success');
       this.questionsList = [];
        this.getQuestions('','');
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
   }
   if(item.liked_by_me == false) {
    this.favService.likeQuestion(this.jobId,item.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
     (data: any) => {
       this.toaster.success('Success');
       this.questionsList = [];
       this.getQuestions('','');

     },
     error => {
       this.toaster.error(error.error.errors);
     }
   );
  }
  }
  likesReplay(item:any,qId:any) {
    if(item.liked_by_me == true) {
      this.favService.unLikeReply(this.jobId,qId,item.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
       (data: any) => {
         this.toaster.success('Success');
        this.questionsList = [];
         this.getQuestions('','');
       },
       error => {
         this.toaster.error(error.error.errors);
       }
     );
    }
    if(item.liked_by_me == false) {
     this.favService.likeReply(this.jobId,qId,item.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.toaster.success('Success');
        this.questionsList = [];
        this.getQuestions('','');
 
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
   }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'job-modal-questions');
  }
}
