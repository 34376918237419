import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FavouriteService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getFavouriteUsers(page, time) {
    return this.http.get(this.baseUrl + 'users/favorites' + '?page=' + page + '&timestamp=' + time, {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),
        'locale': localStorage.getItem('aydoLang')
      })
    });
  }

  getFavouriteJobs(page, time) {
    return this.http.get(this.baseUrl + 'jobs/favourites' + '?page=' + page + '&timestamp=' + time, {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),
        'locale': localStorage.getItem('aydoLang')
      })
    });
  }
  delJob(id: any) {
    return this.http.delete(this.baseUrl + 'jobs/' + id + '/remove_favourite', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),
        'locale': localStorage.getItem('aydoLang')
      })
    });
  }
  delUser(id: any) {
    return this.http.delete(this.baseUrl + 'users/' + id + '/remove_favorite', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),
        'locale': localStorage.getItem('aydoLang')
      })
    });
  }
  likeQuestion(jobId: any, id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + jobId + '/questions/' + id + '/like','', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),
        'locale': localStorage.getItem('aydoLang')
      })
    });
  }
  unLikeQuestion(jobId: any, id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + jobId + '/questions/' + id + '/unlike', '',{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),
        'locale': localStorage.getItem('aydoLang')
      })
    });
  }
  likeReply(jobId: any, qid: any, id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + jobId + '/questions/' + qid + '/replies/' + id + '/like', '',{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),
        'locale': localStorage.getItem('aydoLang')
      })
    });
  }
  unLikeReply(jobId: any, qid: any, id: any) {
    return this.http.put(this.baseUrl + 'jobs/' + jobId + '/questions/' + qid + '/replies/' + id + '/unlike', '',{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),
        'locale': localStorage.getItem('aydoLang')
      })
    });
  }
  likePortfolio(id: any) {
    return this.http.put(this.baseUrl + 'portfolios/' + id + '/like', '',{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),
        'locale': localStorage.getItem('aydoLang')
      })
    });
  }
  unLikePortfolio(id: any) {
    return this.http.put(this.baseUrl + 'portfolios/' + id + '/unlike', '',{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),
        'locale': localStorage.getItem('aydoLang')
      })
    });
  }
}
