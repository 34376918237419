import { Component, OnInit, Renderer2, OnDestroy, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { JobService } from 'src/app/services/job.service';
import { takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalOptions, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationModalComponent } from 'src/app/dashboard/location-modal/location-modal.component';
import { LocationService } from 'src/app/services/location.service';
import { DocumentService } from 'src/app/services/document.service';
import { JobDataService } from 'src/app/services/jobData.service';
import { SigninComponent } from 'src/app/auth/signin/signin.component';

@Component({
  selector: 'app-post-task',
  templateUrl: './post-task.component.html',
  styleUrls: ['./post-task.component.css']
})
export class PostTaskComponent implements OnInit,OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild("content") modalContent: TemplateRef<any>;
  uploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',

  });
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    windowClass:'job-modal-questions'
  };
  modal_1 = false;
  modal_2 = false;
  services: any[] = [];
  catServices:any[] = [];
  selectedCategory: any;
  selectedService: any;
  categories: any[] = [];
  loader = false;
  showModal = false;
  serviceId: any;
  category: any;
  checkStatus: any;
  notAvaliable = false;
  location: any;
  address: any;
  datePicker: any;
  minDate: any;
  videoArray: any[] = [];
  imageArray: any[] = [];
  file_name:any;
  fileId:any;
  job_title:any;
  description:any;
  job_type = 0;
  budget:any;
  file:any;
  user_info:any;
  constructor(private renderer: Renderer2,private jobService: JobService,private modalService: NgbModal,private locationService: LocationService,
    private activeModal:NgbActiveModal) { 
    this.renderer.addClass(document.body, 'job-modal-questions');
    this.minDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
    this.locationService.getLocation().pipe(takeUntil(this.ngUnsubscribe)).subscribe(location => {
      this.location = location;
      this.address = location.address;
    });

  }

  ngOnInit() {
    this.modal_1 = true;
    this.user_info = localStorage.getItem('aydo-app-user');
    this.get_categories();
    this.get_services();


  }
  openLocation(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(LocationModalComponent, this.ngbModalOptions);
  }
  initialStep() {
   this.modal_1 = false;
    //  this.modal_2 = true;
    //  this.modalService.dismissAll('Cross Click');
    const modalRef = this.modalService.open(this.modalContent,this.ngbModalOptions);
   
  }
  get_services() {
    this.jobService.getAllServices().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.services = data;
        if (this.services.length === 0) {
          this.notAvaliable = true;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  get_categories() {
    this.loader = true;
    this.jobService.getCategories().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.categories = data;
        this.loader = false;
      },
      error => {
        console.log(error);
      }
    );
  }
  findService(cat) {
    this.catServices = this.services.filter(x => x.category_id === cat.id);   
  }
    public onImageSelected(event: EventEmitter<File[]>) {
      // this.loader = true;
      const file: File = event[0];
      this.file_name = file.name;
      this.file = file;
      // if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
      //   this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
      //     .subscribe(res => {
      //       this.fileId = res['id'];
      //       this.loader = false;
  
      //     }, error => {
      //       this.loader = false;
      //       console.log('changePicture error', error);
      //     });
      // } else {
      //   this.loader = false;
      // }
    
  }
  cancelModal(content) {
    this.modalService.open(content,this.ngbModalOptions);
  }
  confirmCancel() {
    this.modalService.dismissAll('Corss Click');
  }
  postJob() {
    const dateString = new Date(this.datePicker.year, this.datePicker.month - 1, this.datePicker.day + 1);

    const post_job = {
      title: this.job_title,
      description: this.description,
      job_type: this.job_type,
      schedule: dateString.toISOString(),
      budget: this.budget,
      service: this.selectedService,
      category:this.selectedCategory,
      location: this.location,
      address:this.address,

      // file:this.file
    };
    // this.postJobService.sendJobData(post_job);
    this.modalService.dismissAll('Corss Click');


    const modalRef = this.modalService.open(SigninComponent);
    modalRef.componentInstance.signup = post_job;
    //  this.activeModal.close("cross click");


    
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'job-modal-questions');
  }
}
