import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { environment } from 'src/environments/environment';
import * as firebase from "firebase";
import {MessagingService} from './services/messaging.service'
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import {AppComponent} from './app.component';
import {AngularFontAwesomeModule} from "angular-font-awesome";
import {SigninComponent} from './auth/signin/signin.component';
import {AppRoutingModule} from "./app-routing.module";
import {SignupComponent} from './auth/signup/signup.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from "@angular/forms";
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClient} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {DashboardComponent} from './dashboard/dashboard.component';
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NavbarComponent} from './dashboard/navbar/navbar.component';
import {UserDashboardComponent} from './dashboard/user-dashboard/user-dashboard.component';
import {EditProfileComponent} from './dashboard/profile/edit-profile/edit-profile.component';
import {FileUploadModule} from "ng2-file-upload";
import {AgmCoreModule} from '@agm/core';
import {ModalComponent} from './dashboard/modal/modal.component';
import {LocationModalComponent} from "./dashboard/location-modal/location-modal.component";
import {NationalityComponent} from './dashboard/nationality/nationality.component';
import {LanguagesModalComponent} from './dashboard/languages-modal/languages-modal.component';
import {SecretQuestionsComponent} from './dashboard/secret-questions/secret-questions.component';
import {UpdatePhoneComponent} from './dashboard/update-phone/update-phone.component';
import {UpdateEmailComponent} from './dashboard/update-email/update-email.component';
import {ChangePasswordComponent} from './dashboard/change-password/change-password.component';
import {NotificationSettingsComponent} from './dashboard/notification-settings/notification-settings.component';
import {JobNotificationModalComponent} from './dashboard/notification-settings/job-notification-modal/job-notification-modal.component';
import {PaymentNotificationModalComponent} from './dashboard/notification-settings/payment-notification-modal/payment-notification-modal.component';
import {DisputeNotificationModalComponent} from './dashboard/notification-settings/dispute-notification-modal/dispute-notification-modal.component';
import {PolicyNotificationModalComponent} from './dashboard/notification-settings/policy-notification-modal/policy-notification-modal.component';
import {GeneralNotificationModalComponent} from './dashboard/notification-settings/general-notification-modal/general-notification-modal.component';
import {PortfolioComponent} from './dashboard/portfolio/portfolio.component';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {DragDropListModule} from "ng-drag-drop-list";
import {SkillsComponent} from './dashboard/skills/skills.component';
import {MediaPreviewDirective} from "./dashboard/Directives/media-preview.directive";
import {PostJobComponent} from './dashboard/jobs/post-job/post-job.component';
import {CategoryModalComponent} from './dashboard/jobs/category-modal/category-modal.component';
import {MyJobsComponent} from './dashboard/jobs/my-jobs/my-jobs.component';
import {AllJobsComponent} from './dashboard/jobs/all-jobs/all-jobs.component';
import {Ng5SliderModule} from "ng5-slider";
import {DetailJobComponent} from './dashboard/jobs/detail-job/detail-job.component';
import {DateAgoPipe} from './pipes/date-ago.pipe';
import {LightboxModule} from 'ngx-lightbox';
import {QuestionModalComponent} from './dashboard/jobs/question-modal/question-modal.component';
import {HomeComponent} from './pages/home/home.component';
import {ReportModalComponent} from './dashboard/report-modal/report-modal.component';
import {OffersModalComponent} from './dashboard/jobs/offers-modal/offers-modal.component';
import {PaymentMethodComponent} from './dashboard/payment-method/payment-method.component';
import {CertificationModalComponent} from './dashboard/jobs/certification-modal/certification-modal.component';
import {CreateDisputeModalComponent} from './dashboard/jobs/create-dispute-modal/create-dispute-modal.component';
import {ReviewModalComponent} from './dashboard/review-modal/review-modal.component';
import {EditJobComponent} from './dashboard/jobs/edit-job/edit-job.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {TermsAndConditionComponent} from './pages/terms-and-condition/terms-and-condition.component';
import {DisputesModalComponent} from './dashboard/disputes-modal/disputes-modal.component';
import {JobsAlertModalComponent} from './dashboard/jobs/jobs-alert-modal/jobs-alert-modal.component';
import {ProfileViewComponent} from './dashboard/profile/profile-view/profile-view.component';
import {PortfolioModalComponent} from './dashboard/portfolio-modal/portfolio-modal.component';
import {FavouriteModalComponent} from './dashboard/favourite-modal/favourite-modal.component';
import {IdBadgesModalComponent} from './dashboard/id-badges-modal/id-badges-modal.component';
import {MessagesListComponent} from './dashboard/chat/messages/messages-list/messages-list.component';
import {MessageDetailComponent} from './dashboard/chat/messages/message-detail/message-detail.component';
import {Ng2CableModule} from "ng2-cable";
import {ActionCableService} from "angular2-actioncable";
import { MessagesComponent } from './dashboard/chat/messages/messages.component';
import { StartChatComponent } from './dashboard/chat/messages/start-chat/start-chat.component';
import { NumberOnlyDirective } from './dashboard/Directives/number-only.directive';
import { ScrollToBottomDirective } from './dashboard/Directives/scroll-to-bottom.directive';
import { VerificationComponent } from './dashboard/verification/verification.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { WalletMethodComponent } from './dashboard/wallet-method/wallet-method.component';
import { ErrorInterceptor } from './http.interceptor';
import { RequestWithDrawalComponent } from './dashboard/request-with-drawal/request-with-drawal.component';
import { TransactionHistoryComponent } from './dashboard/transaction-history/transaction-history.component';
import { AboutComponent } from './pages/about/about.component';
import { RoundPipe } from './pipes/number.pipe';
import { ContactComponent } from './dashboard/contact/contact.component';
import { CommunityGuidelinesComponent } from './pages/community-guidelines/community-guidelines.component';
import { AydoerComponent } from './pages/aydoer/aydoer.component';
import { BlogsListComponent } from './pages/blogs/blogs-list/blogs-list.component';
import { BlogsDetailComponent } from './pages/blogs/blogs-detail/blogs-detail.component';
import { SupportListComponent } from './pages/aydo-support/support-list/support-list.component';
import { SupportDetailComponent } from './pages/aydo-support/support-detail/support-detail.component';
import { SupportArticlesComponent } from './pages/aydo-support/support-articles/support-articles.component';
import { MailchimpFormComponent } from './pages/mailchimp-form/mailchimp-form.component';
import { PostTaskComponent } from './pages/post-task/post-task.component';
import { ArchwizardModule } from 'ng2-archwizard';
import { ShortNamePipe } from './pipes/name.pipe';
import { MenuDirective } from './dashboard/Directives/menu.directive';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { VerifyPhoneModalComponent } from './dashboard/verify-phone-modal/verify-phone-modal.component';
import { LocalizedDatePipe } from './pipes/locale-date.pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { AydoersComponent } from './dashboard/aydoers/aydoers.component';
import { AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule } from 'angular-6-social-login';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { StripeModalComponent } from './dashboard/stripe-modal/stripe-modal.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
firebase.initializeApp(environment.firebase);
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
      [
        {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("720665841709645")
      },
        {
          id: GoogleLoginProvider.PROVIDER_ID,
	      provider: new GoogleLoginProvider("966471662835-5ic6ii64q5ou5bi37idn7bjt3m9t022k.apps.googleusercontent.com")
        }
      ]
  );
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SignupComponent,
    DashboardComponent,
    NavbarComponent,
    UserDashboardComponent,
    EditProfileComponent,
    ModalComponent,
    LocationModalComponent,
    NationalityComponent,
    LanguagesModalComponent,
    SecretQuestionsComponent,
    UpdatePhoneComponent,
    UpdateEmailComponent,
    ChangePasswordComponent,
    NotificationSettingsComponent,
    JobNotificationModalComponent,
    PaymentNotificationModalComponent,
    DisputeNotificationModalComponent,
    PolicyNotificationModalComponent,
    GeneralNotificationModalComponent,
    PortfolioComponent,
    ForgotPasswordComponent,
    SkillsComponent,
    MediaPreviewDirective,
    PostJobComponent,
    CategoryModalComponent,
    MyJobsComponent,
    AllJobsComponent,
    DetailJobComponent,
    DateAgoPipe,
    RoundPipe,
    ShortNamePipe,
    LocalizedDatePipe,
    QuestionModalComponent,
    HomeComponent,
    ReportModalComponent,
    OffersModalComponent,
    PaymentMethodComponent,
    CertificationModalComponent,
    CreateDisputeModalComponent,
    ReviewModalComponent,
    EditJobComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    DisputesModalComponent,
    JobsAlertModalComponent,
    ProfileViewComponent,
    PortfolioModalComponent,
    FavouriteModalComponent,
    IdBadgesModalComponent,
    MessagesListComponent,
    MessageDetailComponent,
    MessagesComponent,
    StartChatComponent,
    NumberOnlyDirective,
    ScrollToBottomDirective,
    VerificationComponent,
    WalletMethodComponent,
    RequestWithDrawalComponent,
    TransactionHistoryComponent,
    AboutComponent,
    ContactComponent,
    CommunityGuidelinesComponent,
    AydoerComponent,
    BlogsListComponent,
    BlogsDetailComponent,
    SupportListComponent,
    SupportDetailComponent,
    SupportArticlesComponent,
    MailchimpFormComponent,
    PostTaskComponent,
    MenuDirective,
    VerifyPhoneModalComponent,
    AydoersComponent,
    StripeModalComponent,

  ],
  imports: [
    HttpClientJsonpModule,
    FormsModule,
    AppRoutingModule,
    BrowserModule,
    AngularFontAwesomeModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    NgbModule,
    FileUploadModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDK82ExOmKdK5J87MB8KJqGHSo51JAf6Rw',
      libraries: ['places']
    }),
    NgSelectModule,
    Ng5SliderModule,
    DragDropListModule,
    LightboxModule,
    Ng2CableModule,
    PerfectScrollbarModule,
    ArchwizardModule,
    
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    ImageCropperModule,
    NgxLinkifyjsModule.forRoot(),
    SocialLoginModule

  ],
  providers: [{provide: 'googleTagManagerId', useValue: 'GTM-TZDB9XK'},  {
    provide: AuthServiceConfig,
    useFactory: getAuthServiceConfigs
  },
  NgbActiveModal, AngularStripeService,ActionCableService,MessagingService,{provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,}],
  bootstrap: [AppComponent],
  entryComponents: [
    VerifyPhoneModalComponent,
    PostTaskComponent,
    LocationModalComponent,
    NationalityComponent,
    LanguagesModalComponent,
    SecretQuestionsComponent,
    UpdatePhoneComponent,
    UpdateEmailComponent,
    ChangePasswordComponent,
    JobNotificationModalComponent,
    PaymentNotificationModalComponent,
    DisputeNotificationModalComponent,
    PolicyNotificationModalComponent,
    GeneralNotificationModalComponent,
    PortfolioComponent,
    ForgotPasswordComponent,
    SkillsComponent,
    CategoryModalComponent,
    QuestionModalComponent,
    SigninComponent,
    ReportModalComponent,
    OffersModalComponent,
    PaymentMethodComponent,
    CertificationModalComponent,
    CreateDisputeModalComponent,
    ReviewModalComponent,
    DisputesModalComponent,
    JobsAlertModalComponent,
    PortfolioModalComponent,
    FavouriteModalComponent,
    IdBadgesModalComponent,
    WalletMethodComponent,
    RequestWithDrawalComponent,
    TransactionHistoryComponent,
    ContactComponent,
    StripeModalComponent

  ]
})
export class AppModule {
}
