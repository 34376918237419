import {Component, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject} from "rxjs/index";
import {ToastrService} from "ngx-toastr";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {takeUntil} from "rxjs/internal/operators";
import {FavouriteService} from "../../services/favourite.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-favourite-modal',
  templateUrl: './favourite-modal.component.html',
  styleUrls: ['./favourite-modal.component.css']
})
export class FavouriteModalComponent implements OnInit, OnDestroy {
  status = 0;
  modal1 = false;
  modal2 = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  pagination1: any;
  total1;
  totalPages1: any;
  currentPage1: any;
  timeStamp1: any;
  moreAvailable1: boolean;
  loader = false;
  jobsList: any[] = [];
  usersList: any[] = [];
  noJobs = false;
  noUsers = false;
  currentDate: any;
  lang:any;
  constructor(private toaster: ToastrService, public activeModal: NgbActiveModal,
              private renderer: Renderer2, private favService: FavouriteService,private translate:TranslateService) {
    this.renderer.addClass(document.body, 'job-modal-questions');
  }

  ngOnInit() {
    if(localStorage.getItem('aydoLang')) {
      this.lang = localStorage.getItem('aydoLang');
       
     }
    const now = new Date;
    this.currentDate = now.toISOString();
    this.modal1 = true;
    this.getJobs('', '');
  }

  getUser(page, time) {
    this.loader = true;
    this.favService.getFavouriteUsers(page, time)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.usersList = this.usersList.concat(data['users']);
      this.loader = false;
      if (this.usersList.length === 0) {
        this.noUsers = true;
      }
      if (this.usersList.length > 0) {
        this.noUsers = false;
      }

    });
  }

  getJobs(page, time) {
    this.loader = true;
    this.favService.getFavouriteJobs(page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.pagination1 = data['page_info'];
        this.currentPage1 = this.pagination1.page;
        this.timeStamp1 = this.pagination1.timestamp;
        this.total1 = new Array(this.pagination1.total_pages);
        this.totalPages1 = this.pagination1.total_pages;
        this.moreAvailable1 = this.pagination1.more_available;
        this.jobsList = this.jobsList.concat(data['jobs']);
        this.loader = false;
        if (this.jobsList.length === 0) {
          this.noJobs = true;
        }
        if (this.jobsList.length > 0) {
          this.noJobs = false;
        }
      }
    );
  }

  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.getUser(this.currentPage, this.timeStamp);
      }
    }
  }

  navigateList(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable1 === true) {
        this.currentPage1++;
        this.getJobs(this.currentPage1, this.timeStamp1);
      }
    }
  }

  sendStatus() {
    if (this.status === 0) {
      this.jobsList = [];
      this.getJobs('', '');
      this.noJobs = false;
    }
    if (this.status === 1) {
      this.usersList = [];
      this.noUsers = false;
      this.getUser('', '');
    }
  }

  favEdit() {
    this.modal1 = false;
    this.modal2 = true;
  }
  favDone() {
    this.modal2 = false;
    this.modal1 = true;
  }
  delJob(id) {
    this.favService.delJob(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.translate.get('done').subscribe((res: string) => {
          this.toaster.success(res);
        });
        this.jobsList = [];
        this.getJobs('', '');
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  delUser(id) {
    this.favService.delUser(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.translate.get('done').subscribe((res: string) => {
          this.toaster.success(res);
        });
        this.usersList = [];
        this.getUser('', '');
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  modalClose() {
    this.activeModal.dismiss('Cross Click');
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'job-modal-questions');
  }
}
