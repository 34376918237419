import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SkillsService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getSkills(page, time) {
    return this.http.get(this.baseUrl + 'skills' + '?page=' + page + '&timestamp=' + time,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
        })
      });
  }

  saveSkills(fields: any) {
    return this.http.post(this.baseUrl + 'skills', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  updateSkill(fields: any, id: any) {
    return this.http.put(this.baseUrl + 'skills/' + id, fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  deleteSkill(id: any) {
    return this.http.delete(this.baseUrl + 'skills/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getSkill(id: any) {
    return this.http.get(this.baseUrl + 'skills/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
}
