import {Component, EventEmitter, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FileUploader} from "ng2-file-upload";
import {takeUntil} from "rxjs/internal/operators";
import {Subject} from "rxjs/index";
import {DocumentService} from "../../services/document.service";
import {PortfolioService} from "../../services/portfolio.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit, OnDestroy {
  modal1 = false;
  modal2 = false;
  modal3 = false;
  modal4 = false;
  portfolioList: any[] = [];
  orderList: any[] = [];
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  uploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 1,

    allowedMimeType: ['video/mp4']


  });
  img_url: any;
  file_name: any;
  avatar_id: any;
  avatar_id_2: any;
  file_error: any;
  description: any;
  portfolio_id: any;
  loader = false;
  updatePortfolio: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  textDir: string = 'ltr';

  constructor(private renderer: Renderer2, private documentService: DocumentService, private portfolio: PortfolioService,
              private toastr: ToastrService) {
                if(localStorage.getItem('aydoLang')) {
     
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                  }
                  else {
                    this.textDir = 'ltr'
                  }
                }
    this.renderer.addClass(document.body, 'portfolio');
  }

  ngOnInit() {
    this.modal1 = true;
    this.getPortfolios(this.currentPage, this.timeStamp);
  }

  getPortfolios(page, time) {
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.portfolio.getPortfolio(page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.pagination = data['page_info'];
        this.currentPage = this.pagination.page;
        this.timeStamp = this.pagination.timestamp;
        this.total = new Array(this.pagination.total_pages);
        this.totalPages = this.pagination.total_pages;
        this.moreAvailable = this.pagination.more_available;
        this.portfolioList = this.portfolioList.concat(data['portfolios']);

      }, error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.getPortfolios(this.currentPage, this.timeStamp);
      }
    }
  }

  portfolioSave() {
    const portfolio = {
      image_id: this.avatar_id,
      description: this.description
    };
    this.portfolio.portfolioSave(portfolio).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.toastr.success('Portfolio has been created Successfully!');
        this.modal2 = false;
        this.modal1 = true;
        this.portfolioList = [];
        this.getPortfolios('', '');
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  addPortfolio() {
    this.modal1 = false;
    this.modal2 = true;
    this.img_url = '';
    this.description = '';
  }

  dragList() {
    this.modal4 = true;
    this.modal1 = false;
  }

  getSinglePortfolio(id: any) {
    this.portfolio_id = id;
    this.img_url = '';
    this.description = '';
    this.portfolio.getSinglePortfolio(this.portfolio_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        if (data.image !== null) {
          this.img_url = data.image;

        }
        this.avatar_id = data.image_id;
        this.description = data.description;
        this.modal1 = false;
        this.modal3 = true;
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  portfolioUpdate() {
    if (this.avatar_id_2 !== null) {
      this.updatePortfolio = {
        image_id: this.avatar_id_2,
        description: this.description
      };
    }
    else {
      this.updatePortfolio = {
        description: this.description
      };
    }
    this.portfolio.portfolioUpdate(this.updatePortfolio, this.portfolio_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.toastr.success('Portfolio has been updated Successfully!');
        this.avatar_id_2 = '';
        this.modal3 = false;
        this.modal1 = true;
        this.portfolioList = [];
        this.getPortfolios(1, this.timeStamp);
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  loadList() {
    this.orderList = [];
    for (let i = 0; i < this.portfolioList.length; i++) {
      this.orderList.push(
        {
          portfolio_id: this.portfolioList[i].id,
          sort_order: i
        }
      );
    }
    const order = {
      portfolios: this.orderList
    };
    this.portfolio.portfolioOrder(order).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.toastr.success('Portfolio has been updated Successfully!');
        this.modal4 = false;
        this.modal1 = true;
        this.portfolioList = [];
        this.getPortfolios(1, this.timeStamp);
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    this.loader = true;
    const file: File = event[0];
    if (file.type == 'video/mp4' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
    this.file_name = file.name;
      this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          this.loader = false;
          this.avatar_id = res['id'];
          this.img_url = res;

        }, error => {
        this.toastr.error(error.error.errors);
        });
      }
      else {
        this.loader = false;
        this.toastr.error('Please Upload Only Document in video/mp4 and image format');
      }
  }

  public onFileSelected2(event: EventEmitter<File[]>) {
    this.loader = true;
    const file: File = event[0];
    if (file.type == 'video/mp4' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {

    this.file_name = file.name;
      this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          this.loader = false;
          this.avatar_id_2 = res['id'];
          this.img_url = res;

        }, error => {
          console.log('changePicture error', error);
        });
      }
      else {
        this.loader = false;
        this.toastr.error('Please Upload Only Document in video/mp4 and image format');
      }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'portfolio');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
