import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {SigninComponent} from "./auth/signin/signin.component";
import {SignupComponent} from "./auth/signup/signup.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AuthGuardService} from "./auth/auth-guard.service";
import {UserDashboardComponent} from "./dashboard/user-dashboard/user-dashboard.component";
import {EditProfileComponent} from "./dashboard/profile/edit-profile/edit-profile.component";
import {ForgotPasswordComponent} from "./auth/forgot-password/forgot-password.component";
import {PostJobComponent} from "./dashboard/jobs/post-job/post-job.component";
import {MyJobsComponent} from "./dashboard/jobs/my-jobs/my-jobs.component";
import {AllJobsComponent} from "./dashboard/jobs/all-jobs/all-jobs.component";
import {DetailJobComponent} from "./dashboard/jobs/detail-job/detail-job.component";
import {HomeComponent} from "./pages/home/home.component";
import {EditJobComponent} from "./dashboard/jobs/edit-job/edit-job.component";
import {PrivacyPolicyComponent} from "./pages/privacy-policy/privacy-policy.component";
import {TermsAndConditionComponent} from "./pages/terms-and-condition/terms-and-condition.component";
import {ProfileViewComponent} from "./dashboard/profile/profile-view/profile-view.component";
import {MessagesListComponent} from "./dashboard/chat/messages/messages-list/messages-list.component";
import {MessagesComponent} from "./dashboard/chat/messages/messages.component";
import {StartChatComponent} from "./dashboard/chat/messages/start-chat/start-chat.component";
import {MessageDetailComponent} from "./dashboard/chat/messages/message-detail/message-detail.component";
import { VerificationComponent } from './dashboard/verification/verification.component';
import { AboutComponent } from './pages/about/about.component';
import { CommunityGuidelinesComponent } from './pages/community-guidelines/community-guidelines.component';
import { AydoerComponent } from './pages/aydoer/aydoer.component';
import { BlogsListComponent } from './pages/blogs/blogs-list/blogs-list.component';
import { SupportListComponent } from './pages/aydo-support/support-list/support-list.component';
import { SupportDetailComponent } from './pages/aydo-support/support-detail/support-detail.component';
import { SupportArticlesComponent } from './pages/aydo-support/support-articles/support-articles.component';
import { BlogsDetailComponent } from './pages/blogs/blogs-detail/blogs-detail.component';
import { MailchimpFormComponent } from './pages/mailchimp-form/mailchimp-form.component';
import { AydoersComponent } from './dashboard/aydoers/aydoers.component';


const appRoutes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'sign-up', component: SigninComponent},
  {path: 'about-us', component: AboutComponent},
  {path: 'guidelines', component: CommunityGuidelinesComponent},
  {path: 'aydoer', component: AydoerComponent},
  {path: 'blogs', component: BlogsListComponent},
  {path:'blog/:id', component:BlogsDetailComponent},
  {path:'aydo-support',component:SupportListComponent},
  {path:'support-detail/:id',component:SupportDetailComponent},
  {path:'articles/:id',component:SupportArticlesComponent},
  {path:'subscribe',component:MailchimpFormComponent},
  {
    path: '', component: DashboardComponent, children: [
    {path: 'ad', component: UserDashboardComponent, canActivate: [AuthGuardService]},
    {path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthGuardService]},
    {path: 'post-job', component: PostJobComponent, canActivate: [AuthGuardService]},
    {path: 'my-jobs', component: MyJobsComponent, canActivate: [AuthGuardService]},
    {path: 'all-jobs', component: AllJobsComponent},
    {path: 'discover', component: AydoersComponent},

    {path: 'detail/:id', component: DetailJobComponent},
    {path: 'job-edit/:id', component: EditJobComponent, canActivate: [AuthGuardService]},
    {path: 'job/:new/:id', component: EditJobComponent, canActivate: [AuthGuardService]},
    {
      path: 'messages', component: MessagesComponent, canActivate: [AuthGuardService]
    //   children: [
    //   {path: '', component: StartChatComponent},
    //   {path: ':id', component: MessageDetailComponent}
    // ], canActivate: [AuthGuardService]
    },
    {path: 'profile/:id', component: ProfileViewComponent},
    
    {path: 'verification', component: VerificationComponent},
    {path: 'verification/:code', component: VerificationComponent}

  ]
  },
  {path: 'pp', component: PrivacyPolicyComponent},
    {path: 't&c', component: TermsAndConditionComponent},
  {
           path: '**',
        redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: false,onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
