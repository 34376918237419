import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs/index";
import {AuthLoginService} from "../../auth/auth.service";
import {takeUntil} from "rxjs/internal/operators";
import {ToastrService} from "ngx-toastr";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  textDir: string = 'ltr';
  currentPassword: any;
  newPassword: any;
  confirmedPassword: any;
  show: boolean;
  show2: boolean;
  show3: boolean;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private authService: AuthLoginService, private toastr: ToastrService, public activeModal: NgbActiveModal) {
    this.show = false;
    this.show2 = false;
    this.show3 = false;
    if(localStorage.getItem('aydoLang')) {
     
      if(localStorage.getItem('aydoLang') == 'ar') {
        this.textDir = 'rtl'
      }
      else {
        this.textDir = 'ltr'
      }
    }
  }

  ngOnInit() {
  }

  cPassword() {
    this.show = !this.show;
  }

  nPassword() {
    this.show2 = !this.show2;
  }

  confirmPassword() {
    this.show3 = !this.show3;
  }

  savePassword() {
    const changePassword = {
      old_password: this.currentPassword,
      new_password: this.newPassword,
      confirm_new_password: this.confirmedPassword
    };
    this.authService.updatePassword(changePassword).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.activeModal.dismiss('Cross Click');
        this.toastr.success(data.message);
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
