import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Options } from 'ng5-slider';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthLoginService } from 'src/app/auth/auth.service';
import { JobService } from 'src/app/services/job.service';
import { LocationService } from 'src/app/services/location.service';
import { LocationModalComponent } from '../location-modal/location-modal.component';

@Component({
  selector: 'app-aydoers',
  templateUrl: './aydoers.component.html',
  styleUrls: ['./aydoers.component.css']
})
export class AydoersComponent implements OnInit {
  filterArray = [
    {
      id: 0,
      name: 'Distance: Nearest First',
      ar_name: 'تاريخ الإعلان: من الأحدث للأقدم'
    },
    {
      id: 1,
      name: 'Distance: Farthest First',
      ar_name: 'تاريخ الإعلان: من الأقدم للأحدث'
    },
    {
      id: 3,
      name: 'Rating: Low to High',
      ar_name:'الميزانية: من الأقل للأكثر'
    },
    {
      id: 2,
      name: 'Rating: High to Low',
      ar_name: 'الميزانية: من الأكثر للأقل'
    }
  ];
  selectedFilter: any;
  enLang = true;

 usersList:any[] = [];
 loader = false;
 noUsers = false;
 pagination: any;
 total;
 totalPages: any;
 currentPage: any;
 timeStamp: any;
 moreAvailable: boolean;
 categories: any[] = [];
 selectedCategory:any;
 location: any;
 address: any;
 openBox = false;
 distance = 0;
 options: Options = {
  floor: 0,
  ceil: 50,
};
ratingOptions: Options = {
  floor: 0,
  ceil: 5,
};
rating_low = 0;
rating_high = 5;
lat: any;
lng: any;
sort_by: any;
ngbModalOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false
};
 private ngUnsubscribe: Subject<any> = new Subject();
 @ViewChild('scrollMe') private myScrollContainer: ElementRef;


  constructor(private jobService:JobService,private locationService: LocationService,
    private translate: TranslateService,private modalService: NgbModal) { 
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang == 'en') {
        this.enLang = true;
      }
      else {
        this.enLang = false;
      }
    });
this.locationService.getLocation().pipe(takeUntil(this.ngUnsubscribe)).subscribe(location => {
this.location = location;
this.address = location.address;
});
  }

  ngOnInit() {
    this.getAllUsers();
    this.get_categories();
  }
  get_categories() {
    this.jobService.getCategories().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.categories = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  getAllUsers() {
    let categoryId:any;
    let minRating:any;
    let maxRating:any;
    if(this.selectedCategory) {
      categoryId = this.selectedCategory.id;
    }
    if (this.location != null) {
      this.lat = this.location.lat;
      this.lng = this.location.lng;
    }
    if (this.selectedFilter) {
      this.sort_by = this.selectedFilter.id;
    }
    if(this.rating_low == 0 && this.rating_high == 5) {
      minRating = null;
      maxRating = null;
 
     }
     else {
       minRating = this.rating_low;
       maxRating = this.rating_high;
     }
    this.loader = true;
    let params = {
      page: this.currentPage,
      timestamp: this.timeStamp,
      category_id:categoryId,
      lat:this.lat,
      lng:this.lng,
      sort_by:this.sort_by,
      min_rating:minRating,
      max_rating:maxRating,
      distance:this.distance

    }
    this.jobService.getAydoers(params)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.pagination = data.page_info;
        this.timeStamp = this.pagination.timestamp;
        this.total = new Array(this.pagination.total_pages);
        this.currentPage = this.pagination.page;
        this.totalPages = this.pagination.total_pages;
        this.moreAvailable = this.pagination.more_available;
      this.usersList = this.usersList.concat(data['users']);
    
      this.loader = false;
      if(this.usersList.length === 0) {
          this.noUsers = true;
      }
      else {
          this.noUsers = false
      }
    });
  }
  applyFilters() {
    this.usersList = [];
    this.currentPage = null;
    this.timeStamp = null;
     this.getAllUsers();
    this.openBox = false;
  }
  clearFilters() {
    this.usersList = [];
    this.currentPage = null;
    this.timeStamp = null;
    this.selectedCategory = null;
    this.location = null;
    this.selectedFilter = null;
    this.sort_by = null;
    this.address = null;
    this.lat = null;
    this.lng = null;
    this.rating_low = 0;
    this.rating_high = 5;
    this.distance = 0;
     this.getAllUsers();
  }
  navigate(event) {

    if(event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true && !this.loader) {
        this.loader = true;
        setTimeout(() => {
        
          this.currentPage++;
          this.getAllUsers();

        }, 2000);
      }
    }
  
  }
  openLocation(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(LocationModalComponent, this.ngbModalOptions);
  }
}
