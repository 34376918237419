import {Component, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {JobService} from "../../../services/job.service";
import {Subject} from 'rxjs/index';
import {takeUntil} from "rxjs/internal/operators";
import {MapsAPILoader} from "@agm/core";
import {LocationModalComponent} from "../../location-modal/location-modal.component";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {LocationService} from "../../../services/location.service";
import {Options} from "ng5-slider";
import {JobsAlertModalComponent} from "../jobs-alert-modal/jobs-alert-modal.component";
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-all-jobs',
  templateUrl: './all-jobs.component.html',
  styleUrls: ['./all-jobs.component.css']
})
export class AllJobsComponent implements OnInit, OnDestroy {
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  noJob = false;
  styles: any[] = [
    {
      "featureType": "all",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "saturation": 36
          },
          {
              "color": "#4d4747"
          },
          {
              "lightness": 40
          }
      ]
  },
  {
      "featureType": "all",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#ffffff"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "featureType": "all",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fafaf8"
          },
          {
              "lightness": 20
          },
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#fafaf8"
          },
          {
              "lightness": 17
          },
          {
              "weight": 1.2
          }
      ]
  },
  {
      "featureType": "administrative.country",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.country",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#e4cbcd"
          },
          {
              "weight": "2.00"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#909daa"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.province",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#d8b3b6"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.province",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#909daa"
          }
      ]
  },
  {
      "featureType": "administrative.locality",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#909daa"
          }
      ]
  },
  {
      "featureType": "administrative.neighborhood",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.land_parcel",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#000000"
          },
          {
              "lightness": "74"
          },
          {
              "saturation": "46"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fbfbfb"
          }
      ]
  },
  {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fafaf8"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "landscape.natural.landcover",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          },
          {
              "color": "#ff0000"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#ebebeb"
          },
          {
              "lightness": "0"
          },
          {
              "gamma": "1"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#ebebeb"
          },
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "weight": "1"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#909daa"
          },
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#dedede"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "road.highway.controlled_access",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          },
          {
              "saturation": "-100"
          },
          {
              "lightness": "100"
          },
          {
              "color": "#252121"
          }
      ]
  },
  {
      "featureType": "transit.line",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#dedede"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#bfcbcf"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "labels",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  }
  ]
  latitude = 25.276987;
  longitude = 55.296249;
  zoom = 10;
  filterArray = [
    {
      id: 0,
      name: 'Posted Data: Newest to Oldest',
      ar_name: 'تاريخ الإعلان: من الأحدث للأقدم'
    },
    {
      id: 1,
      name: 'Posted Date: Oldest to Newest',
      ar_name: 'تاريخ الإعلان: من الأقدم للأحدث'
    },
    {
      id: 2,
      name: 'Price: Low to High',
      ar_name:'الميزانية: من الأقل للأكثر'
    },
    {
      id: 3,
      name: 'Price: High to Low',
      ar_name: 'الميزانية: من الأكثر للأقل'
    }
  ];
  jobs: any[] = [];
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  loader = false;
  currentDate: any;
  openBox = false;
  selectedFilter: any;
  query: any;
  presence: any;
  location: any;
  address: any;
  mapJobInfo: any;
  previous;
  distance = 0;
  price_low = 0;
  price_high = 40000;
  options: Options = {
    floor: 0,
    ceil: 50,
  };
  priceOptions: Options = {
    floor: 0,
    ceil: 40000,
  };
  done_before: any;
  done_client: any;
  lat: any;
  lng: any;
  sort_by: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  userInfo: any;
  enLang = true;
  lang:any;
  iconsArray = ['assets/images/1.png','assets/images/2.png','assets/images/3.png','assets/images/4.png','assets/images/5.png'];
  constructor(private jobService: JobService, private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone, private modalService: NgbModal, private locationService: LocationService,
              private translate: TranslateService) {
                this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                    if(event.lang == 'en') {
                      this.enLang = true;
                    }
                    else {
                      this.enLang = false;
                    }
                  });
    this.locationService.getLocation().pipe(takeUntil(this.ngUnsubscribe)).subscribe(location => {
      this.location = location;
      this.address = location.address;
    });
  }

  ngOnInit() {
    if(localStorage.getItem('aydoLang')) {
        this.lang = localStorage.getItem('aydoLang');
         
       }
    if(localStorage.getItem('aydoLang') == 'en') {
        this.enLang = true;
    }
    else {
      this.enLang = false;
    }
    this.userInfo = localStorage.getItem('aydo-app-user');
    const now = new Date;
    this.currentDate = now.toISOString();
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
    });
    this.getAllJobs(this.currentPage, this.timeStamp, this.lat, this.lng, this.distance,
      this.sort_by, this.done_before, this.done_client, this.price_low, this.price_high, this.presence, this.query);
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;

      });
    }
  }

  openLocation(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(LocationModalComponent, this.ngbModalOptions);
  }

  clickedMarker(item: any, index: number) {
    this.mapJobInfo = index;
    if (this.previous) {
      this.previous.close();
    }
    this.previous = item;
  }

  getAllJobs(page, time, lat, lng, distance, sort_by, done_before, done_client, price_low, price_high, presence, query) {
    this.loader = true;
    if (!page && !time && !lat && !lng && !distance && !sort_by
      && !done_before && !done_client && !price_low && !price_high && !presence && !query) {
      page = '';
      time = '';
      lat = '';
      lng = '';
      distance = '';
      sort_by = '';
      done_before = '';
      done_client = '';
      price_low = '';
      price_high = '';
      presence = '';
      query = '';
    }

    this.jobService.getAllJobs(page, time, lat, lng, distance, sort_by, done_before, done_client, presence, query)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.jobs = this.jobs.concat(data['jobs']);
      for(var i = 0; i < this.jobs.length; i++) {
          if(this.jobs[i].location) {
              this.jobs[i].locationImg = this.iconsArray[Math.floor(Math.random()*this.iconsArray.length)];
          }
        
      }
      this.loader = false;
      if(this.jobs.length === 0) {
          this.noJob = true;
      }
      else {
          this.noJob = false
      }
    });
  }
  
  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.getAllJobs(this.currentPage, this.timeStamp, this.lat, this.lng, this.distance,
          this.sort_by, this.done_before, this.done_client, this.price_low, this.price_high, this.presence, this.query);
      }
    }
  }

  applyFilters() {
    if (this.location != null) {
      this.lat = this.location.lat;
      this.lng = this.location.lng;
    }
    if (this.selectedFilter) {
      this.sort_by = this.selectedFilter.id;
    }
    this.jobs = [];
    this.jobService.getAllJobs('', '', this.lat, this.lng, this.distance,
      this.sort_by, this.done_before, this.done_client, this.presence, this.query)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.jobs = data['jobs'];
      this.loader = false;
      if(this.jobs.length === 0) {
        this.noJob = true;
    }
    else {
        this.noJob = false
    }
    });
    this.openBox = false;
  }

  SearchData(query: string) {
    this.query = query;
    this.applyFilters();
    if (this.query.length === 0) {
      this.query = null;
      this.applyFilters();
    }
  }

  alertModal() {
    this.modalService.open(JobsAlertModalComponent, this.ngbModalOptions);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
