import {Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(localStorage.getItem('aydoLang') == 'en') {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        // 'year': 31536000,
        // 'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'min': 60,
        'sec': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
         if(i == 'week' && counter > 3) {
          var datePipe = new DatePipe("en-US");
          value = datePipe.transform(value, 'mediumDate');
           return value;
         }
         else if (counter === 1) {
           
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } 
          else {

             return counter + ' ' + i + ' ago';  //plural (2 days ago)
          }
      }
    }
    return value;
  }
  if(localStorage.getItem('aydoLang') == 'ar') {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'الآن';
      const intervals = {
        // 'year': 31536000,
        // 'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
         if(i == 'week' && counter > 3) {
          var datePipe = new DatePipe("ar");
          value = datePipe.transform(value, 'mediumDate');
           return value;
         }
         else if (counter === 1) {
           if(i == 'week') {
            return counter + ' ' + 'قبل أسبوع'

           }
           if(i == 'day') {
             return counter + ' ' + 'منذ يوم'
           }
           if(i == 'hour') {
            return counter + ' ' + 'منذ ساعة'
          }
          if(i == 'minute') {
            return counter + ' ' + 'منذ دقيقة'
          }
          if(i == 'second') {
            return counter + ' ' + 'قبل ثانية'
          }
          } 
          else {
            if(i == 'week') {
              return counter + ' ' + 'منذ أسابيع'
  
             }
             if(i == 'day') {
               return counter + ' ' + 'أيام مضت'
             }
             if(i == 'hour') {
              return counter + ' ' + 'منذ ساعات'
            }
            if(i == 'minute') {
              return counter + ' ' + 'دقائق مضت'
            }
            if(i == 'second') {
              return counter + ' ' + 'منذ ثوانى'
            }
          }
      }
    }
    return value;
  }
  }

}
