import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {AuthLoginService} from "./auth/auth.service";
import { MessagingService } from './services/messaging.service';
import en from '@angular/common/locales/en';
import ar from '@angular/common/locales/ar';
import { registerLocaleData } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as SmartBanner from "../../node_modules/smart-app-banner/dist/smart-app-banner.js";
declare let gtag:Function;
declare let fbq:Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  userInfo: any;
  message;
  constructor(private authService: AuthLoginService, private router: Router,private msgService: MessagingService,
    private gtmService: GoogleTagManagerService,
    ) {
      router.events.subscribe((y: NavigationEnd) => {
        if(y instanceof NavigationEnd){
          gtag('config','UA-124164943-31',{'page_path' : y.url});
          fbq('track', 'PageView');
        }
      })
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          
          const gtmTag = {
            event: 'page',
            pageName: item.url
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
      new SmartBanner({
        daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
        title: 'aydo',
        author: 'aydoCompany LLC',
        button: 'VIEW',
        store: {
            ios: 'On the App Store',
            android: 'In Google Play',
            windows: 'In Windows store'
        },
        price: {
            ios: 'FREE',
            android: 'FREE',
            windows: 'FREE'
        }
        // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        // , icon: '' // full path to icon image if not using website icon image
        // , force: 'ios' // Uncomment for platform emulation
    });
  }

  ngOnInit() {
    registerLocaleData(en, 'en');
    registerLocaleData(ar, 'ar');
    localStorage.removeItem('aydoJobInfo');
    this.authService.validateToken().subscribe(
      data => {

        this.userInfo = JSON.stringify(data);
        localStorage.setItem('aydo-app-user', this.userInfo);
      },
      error => {
        if (error.status === 401) {

          localStorage.removeItem('Authorization');
          this.router.navigate(['']);
        }

      }
    );
   
  }
}
