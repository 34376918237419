import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getPaymentMethods(page, time) {
    return this.http.get(this.baseUrl + 'payment_methods' + '?page=' + page + '&timestamp=' + time, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  getTransactionHistory(page, time,dateFrom,dateTo) {
    return this.http.get(this.baseUrl + 'transaction_history' + 
    '?page=' + page + '&timestamp=' + time + '&date_from=' + dateFrom + '&date_to=' + dateTo, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  getWithDrawalList(page, time) {
    return this.http.get(this.baseUrl + 'withdrawal_requests' + '?page=' + page + '&timestamp=' + time, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  savePayPal(fields: any) {
    return this.http.post(this.baseUrl + 'payment_methods', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  updatePayPal(fields: any, id: any) {
    return this.http.put(this.baseUrl + 'payment_methods/' + id, fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  updateAccount(fields: any, id: any) {
    return this.http.put(this.baseUrl + 'withdrawal_methods/' + id, fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  deleteMethod(id: any) {
    return this.http.delete(this.baseUrl + 'payment_methods/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  deleteWithDrawalMethod(id:any) {
    return this.http.delete(this.baseUrl + 'withdrawal_methods/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  getWithDrawalMethods(page, time) {
    return this.http.get(this.baseUrl + 'withdrawal_methods' + '?page=' + page + '&timestamp=' + time, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  saveAccountMethod(fields:any) {
    return this.http.post(this.baseUrl + 'withdrawal_methods', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  getWithDrawalRequest() {
    return this.http.get(this.baseUrl + 'wallet', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  getBraintreeToken() {
    return this.http.get(this.baseUrl + 'paypal_client_token', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  withDraw(fields:any) {
    return this.http.post(this.baseUrl + 'withdrawal_requests', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  creatPaymentMethod(fields: any) {
    return this.http.post(this.baseUrl + 'payment_methods', fields,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  PayTabs() {
    return this.http.get(this.baseUrl + 'pay_page', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  getPublicKey() {
    return this.http.get(this.baseUrl + 'payment_methods/setup_intent', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
}
