import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentModalService {

  constructor() { }
  private subject = new Subject<any>();
  private messageCardSubject = new Subject<any>();


  sendMessage(message: any) {
    this.subject.next(message);
  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  sendMessageCard(message: any) {
    this.messageCardSubject.next(message);
  }
  getMessageCard(): Observable<any> {
    return this.messageCardSubject.asObservable();
  }
}
