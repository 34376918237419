import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthLoginService} from "../auth.service";
import {Router} from "@angular/router";
import {User} from "./user-create";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs/index";
import {takeUntil} from "rxjs/internal/operators";
import {SigninComponent} from "../signin/signin.component";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit, OnDestroy {
  data = new User();
  authToken: any;
  userInfo: any;
  error: any;
  countries: any;
  selectCountry: any;
  terms: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(private authService: AuthLoginService, private router: Router, private toastr: ToastrService,
              private renderer: Renderer2,public activeModal: NgbActiveModal,private modalService: NgbModal) {
    this.renderer.addClass(document.body, 'home-aydoo-signup');
  }

  ngOnInit() {
    this.fetchCountries();
  }

  onSignup() {
    if (this.selectCountry) {
      this.data.country_code = this.selectCountry.country_code;
    }
    this.authService.createUser(this.data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.authToken = data['token'];
        this.userInfo = JSON.stringify(data['user']);
        localStorage.setItem('Authorization', this.authToken);
        localStorage.setItem('aydo-app-user', this.userInfo);
        this.router.navigate(['/']);
      },
      error => {
        this.toastr.error(error.error.errors);

      }
    );
  }

  fetchCountries() {
    this.authService.getCountries().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      res => {
        this.countries = res;
      },
      error => {
        console.log(error);
      }
    );
  }
  signInModal() {
    this.activeModal.dismiss('Cross click');
    const modalRef = this.modalService.open(SigninComponent);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'home-aydoo-signup');
  }
}
