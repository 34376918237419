import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthLoginService} from "../../../auth/auth.service";
import {Subject} from "rxjs/index";
import {takeUntil} from "rxjs/internal/operators";
import {ToastrService} from "ngx-toastr";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../notification.service";

@Component({
  selector: 'app-policy-notification-modal',
  templateUrl: './policy-notification-modal.component.html',
  styleUrls: ['./policy-notification-modal.component.css']
})
export class PolicyNotificationModalComponent implements OnInit, OnDestroy {
  email = true;
  push = true;
  sms = true;
  settings: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  textDir: string = 'ltr';


  constructor(private authService: AuthLoginService, private toastr: ToastrService, public activeModal: NgbActiveModal,
              private notificationService: NotificationService,private renderer: Renderer2) {
                if(localStorage.getItem('aydoLang')) {
     
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                  }
                  else {
                    this.textDir = 'ltr'
                  }
                }
    this.renderer.addClass(document.body, 'portfolio');

  }

  ngOnInit() {
    this.getJobs();
  }

  getJobs() {
    this.authService.getNotificatinSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.settings = data;
        for (const setting of this.settings) {
          if (setting.notification_category === 3) {
            this.email = setting.email;
            this.push = setting.push;
            this.sms = setting.sms;
          }
        }
      },
      error => {
        this.toastr.error(error.error.errors);

      }
    );
  }

  saveJobs() {
    const notification = {
      email: this.email,
      push: this.push,
      sms: this.sms,
      notification_category: 3
    };
    this.authService.postNotificationSettings(notification).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.toastr.success('Setting Updated');
        this.activeModal.dismiss('Cross click');
        this.sendJobNotification();
      },
      error => {
        this.toastr.error(error.error.errors);

      }
    );
  }

  sendJobNotification() {
    this.notificationService.sendJob();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'portfolio');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
