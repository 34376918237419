import {Component, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {JobService} from "../../../services/job.service";
import {Subject} from 'rxjs/index';
import {takeUntil} from "rxjs/internal/operators";
import {MapsAPILoader} from "@agm/core";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {DisputesModalComponent} from "../../disputes-modal/disputes-modal.component";
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-jobs',
  templateUrl: './my-jobs.component.html',
  styleUrls: ['./my-jobs.component.css']
})
export class MyJobsComponent implements OnInit, OnDestroy {
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  noJob = false;
  latitude = 25.276987;
  longitude = 55.296249;
  zoom = 10;
  filterArray = [
    {
      id: 5,
      name: 'All Tasks',
      ar_name: 'جميع المهام'
    },
    {
      id: 0,
      name: 'Posted',
      ar_name:'مُعلن عنه'
    },
    {
      id: 1,
      name: 'Applied - as aydoer',
      ar_name: 'تم تقديم عرض - کما آیدور'
    },
    {
      id: 2,
      name: 'Assigned - as aydoer',
      ar_name:'تم تكليفه - کما آیدور'
    },
    {
      id: 3,
      name: 'Drafts',
      ar_name:'المسودات'
    },
    {
      id: 4,
      name: 'Completed/Cancelled',
      ar_name:'مُنتهية/مُلغاة'
    }
  ];
  jobs: any[] = [];
  styles: any[] = [
    {
      "featureType": "all",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "saturation": 36
          },
          {
              "color": "#4d4747"
          },
          {
              "lightness": 40
          }
      ]
  },
  {
      "featureType": "all",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#ffffff"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "featureType": "all",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fafaf8"
          },
          {
              "lightness": 20
          },
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#fafaf8"
          },
          {
              "lightness": 17
          },
          {
              "weight": 1.2
          }
      ]
  },
  {
      "featureType": "administrative.country",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.country",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#e4cbcd"
          },
          {
              "weight": "2.00"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#909daa"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.province",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#d8b3b6"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.province",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#909daa"
          }
      ]
  },
  {
      "featureType": "administrative.locality",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#909daa"
          }
      ]
  },
  {
      "featureType": "administrative.neighborhood",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.land_parcel",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#000000"
          },
          {
              "lightness": "74"
          },
          {
              "saturation": "46"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fbfbfb"
          }
      ]
  },
  {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fafaf8"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "landscape.natural.landcover",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          },
          {
              "color": "#ff0000"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#ebebeb"
          },
          {
              "lightness": "0"
          },
          {
              "gamma": "1"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#ebebeb"
          },
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "weight": "1"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#909daa"
          },
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#dedede"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "road.highway.controlled_access",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          },
          {
              "saturation": "-100"
          },
          {
              "lightness": "100"
          },
          {
              "color": "#252121"
          }
      ]
  },
  {
      "featureType": "transit.line",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#dedede"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#bfcbcf"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "labels",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  }
  ]
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  loader = false;
  currentDate: any;
  openBox = false;
  selectedFilter: any;
  query: any;
  mapJobInfo: any;
  previous;
  enLang = true;
  lang:any;
  private ngUnsubscribe: Subject<any> = new Subject();
  iconsArray = ['assets/images/1.png','assets/images/2.png','assets/images/3.png','assets/images/4.png','assets/images/5.png'];

  


  constructor(private jobService: JobService, private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone, private modalService: NgbModal,private translate: TranslateService) {
                this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                    if(event.lang == 'en') {
                      this.enLang = true;
                    }
                    else {
                      this.enLang = false;
                    }
                  });
  }

  ngOnInit() {
    if(localStorage.getItem('aydoLang')) {
        this.lang = localStorage.getItem('aydoLang');
         
       }
      if(localStorage.getItem('aydoLang') == 'en') {
        this.enLang = true;
    }
    else {
      this.enLang = false;
    }
    const now = new Date;
    this.currentDate = now.toISOString();
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
    });
    this.getMyJobs(this.currentPage, this.timeStamp, this.query);
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;

      });
    }
  }

  clickedMarker(item: any, index: number) {
    this.mapJobInfo = index;
    if (this.previous) {
      this.previous.close();
    }
    this.previous = item;
  }

  getMyJobs(page, time, query) {
    this.loader = true;
    if (!page && !time && !query && query !== 0) {
      page = '';
      time = '';
      query = '';
    }
    this.jobService.getMyJobs(page, time, query).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.jobs = this.jobs.concat(data['jobs']);
      for(var i = 0; i < this.jobs.length; i++) {
        if(this.jobs[i].location) {
            this.jobs[i].locationImg = this.iconsArray[Math.floor(Math.random()*this.iconsArray.length)];
        }
        
    }
      this.loader = false;
      if(this.jobs.length === 0) {
        this.noJob = true;
    }
    else {
        this.noJob = false
    }
    });
  }

  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + 10 + event.target.scrollTop >= event.target.scrollHeight) {
      
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.getMyJobs(this.currentPage, this.timeStamp, this.query);
      }
    }
  }

  filterApply() {
    this.query = this.selectedFilter.id;
    if (this.query === 5) {
      this.query = '';
      this.jobs = [];
      this.getMyJobs('', '', this.query);
    }
    else {
      this.jobs = [];
      this.getMyJobs('', '', this.query);
    }
    this.openBox = false;
  }

  openModal() {
    const modalRef = this.modalService.open(DisputesModalComponent, this.ngbModalOptions);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
