import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {JobService} from "../../services/job.service";
import {Subject} from "rxjs/index";
import {takeUntil} from "rxjs/internal/operators";
import {DashboardService} from "../../services/dashboard.service";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DisputesModalComponent } from '../disputes-modal/disputes-modal.component';
import { TransactionHistoryComponent } from '../transaction-history/transaction-history.component';
import { SkillsComponent } from '../skills/skills.component';
import { ToastrService } from 'ngx-toastr';
import { IdBadgesModalComponent } from '../id-badges-modal/id-badges-modal.component';
import { PaymentMethodComponent } from '../payment-method/payment-method.component';


@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit, OnDestroy {
  jobs: any[] = [];
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  loader = false;
  currentDate: any;
  query: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  user_type = 0;
  allData:any;
  totalAydoerCount:any;
  totalPosterCount:any;
  noJob = false;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  lang:any;


  constructor(private jobService: JobService, private dashBoardService: DashboardService,private route:ActivatedRoute,
    private modalService: NgbModal,private router:Router,private toaster:ToastrService) {
    const now = new Date;
    this.currentDate = now.toISOString();
    this.route.queryParams.subscribe(params => {
      const responseCode = params['notification_type'];
      const resource_id =  params['resource_id'];
      const responseCodePayTabs = params['code'];
      const twitter_status = params['twitter_status'];
      if(responseCodePayTabs == 1) {
        this.toaster.error('Card Saved Unsuccessfully');
        this.paymentMethod();
      }
     if(responseCodePayTabs == 0) {
        this.toaster.success('Card Saved Successfully');
        this.paymentMethod();
      }
      if(twitter_status == 0) {
         this.toaster.success("Twitter has been connected");
        this.openIdBadgeModal();

      }
      if(twitter_status == 1) {
        this.toaster.error("Twitter has not been connected");
        
      }
    
    if((responseCode >=12 && responseCode <= 16) || responseCode == 22) {
      const modalRef = this.modalService.open(DisputesModalComponent,this.ngbModalOptions);
      modalRef.componentInstance.disputeNotification = resource_id;
    }
    else if(responseCode == 9) {
      this.modalService.open(TransactionHistoryComponent,this.ngbModalOptions);
    }
   
    else if(responseCode == 11 || responseCode == 28 || responseCode == 34) {
      this.modalService.open(SkillsComponent,this.ngbModalOptions);
    }
    else if(responseCode == 29 || responseCode == 32 || responseCode == 33) {
      const modalRef= this.modalService.open(TransactionHistoryComponent,this.ngbModalOptions);
      modalRef.componentInstance.requestStatus = '1';
    }
    else if (responseCode == 17) {
      localStorage.removeItem('chatId');
      localStorage.setItem('chatId',resource_id);
  this.router.navigate([`/messages`]);

    }
    else if (responseCode == 39){
  this.router.navigate([`/ad`]);

    }
    });
  }
  openIdBadgeModal() {
    this.modalService.open(IdBadgesModalComponent, this.ngbModalOptions);
  }
  ngOnInit() {
    if(localStorage.getItem('aydoLang')) {
      this.lang = localStorage.getItem('aydoLang');
       
     }
    this.getAllJobs(this.currentPage, this.timeStamp, this.query);
    this.getDashboardService();
  }
  paymentMethod() {
    this.modalService.open(PaymentMethodComponent,this.ngbModalOptions);
  }
  getAllJobs(page, time, query) {
    this.loader = true;
    this.jobService.getDashboardJobs(page, time, query)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.jobs = this.jobs.concat(data['jobs']);
      this.loader = false;
      if(this.jobs.length === 0) {
        this.noJob = true;
    }
    else {
        this.noJob = false
    }
    });
  }

  getDashboardService() {
    this.dashBoardService.getDashboardService().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.allData = data;
      this.totalAydoerCount =  this.allData.as_aydoer.total_jobs_count;
      this.totalPosterCount = this.allData.as_poster.total_jobs_count;
    });
  }
  getPercentage(value) {
    return Math.floor(value/this.totalAydoerCount * 100);
  }
  getPercentagePoster(value) {
    return Math.floor(value/this.totalPosterCount * 100);
  }

  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.getAllJobs(this.currentPage, this.timeStamp, this.query);
      }
    }
  }

  SearchData(query: string) {
    this.query = query;
    this.applyFilters();
    if (this.query.length === 0) {
      this.query = null;
      this.applyFilters();
    }
  }

  applyFilters() {
    this.jobs = [];
    this.jobService.getDashboardJobs('', '', this.query)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.jobs = data['jobs'];
      this.loader = false;
      if(this.jobs.length === 0) {
        this.noJob = true;
    }
    else {
        this.noJob = false
    }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
