import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {md5} from 'md5'

@Injectable({
  providedIn: 'root'
})
export class AuthLoginService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  createUser(fields: any) {
    return this.http.post(this.baseUrl + 'auth/sign_up', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd'
      })
    });
  }

  signInUser(fields: any) {
    return this.http.post(this.baseUrl + 'auth/sign_in', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
  googleSignInUser(fields: any) {
    return this.http.post(this.baseUrl + 'auth/google_login', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
  fbSignInUser(fields: any) {
    return this.http.post(this.baseUrl + 'auth/facebook_login', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
  deleteUser(id: any) {
    return this.http.delete(this.baseUrl + 'users/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')

      })
    });
  }
  // signInUser(fields: any) {
  //   console.log(fields);
  //   return this.http.post(`${this.baseUrl}/auth/sign_in`, Object.assign(fields, { password: md5(fields.password) }), {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'ApiKey': '099280a3038f6abd',
  //     })
  //   });
  // }

  getCountries() {
    return this.http.get(this.baseUrl + 'countries', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }

  getLanguages() {
    if(localStorage.getItem('aydoLang')) {
    return this.http.get(this.baseUrl + 'languages', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'locale': localStorage.getItem('aydoLang')

      })
    });
  }
  else {
    return this.http.get(this.baseUrl + 'languages', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
  }

  getNationalities() {
    return this.http.get(this.baseUrl + 'nationalities', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }

  logOut() {
    return this.http.delete(this.baseUrl + 'auth/sign_out', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  validateToken() {
    return this.http.get(this.baseUrl + 'auth/validate_token', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  resendEmail() {
    return this.http.get(this.baseUrl + 'resend_verification_email', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getQuestions() {
    return this.http.get(this.baseUrl + 'secret_questions', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  postQuestions(fields: any) {
    return this.http.post(this.baseUrl + 'secret_securities', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  updateQuestions(fields: any) {
    return this.http.put(this.baseUrl + 'secret_securities/update', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  fetchSecrets() {
    return this.http.get(this.baseUrl + 'secret_securities', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  resendVerificationSms() {
    return this.http.get(this.baseUrl + 'resend_verification_sms', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  sendMethod(field: any) {
    return this.http.post(this.baseUrl + 'verifications/secrets_update', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getPhonePin(field: any) {
    return this.http.post(this.baseUrl + 'verifications/phone_update', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getEmailPin(field: any) {
    return this.http.post(this.baseUrl + 'verifications/email_update', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  updatePhone(field: any) {
    return this.http.put(this.baseUrl + 'users/update_phone', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  verifyPhone(field: any) {
    return this.http.put(this.baseUrl + 'auth/verify_phone', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  updateEmail(field: any) {
    return this.http.put(this.baseUrl + 'users/update_email', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  updatePassword(field: any) {
    return this.http.put(this.baseUrl + 'auth/change_password', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  postNotificationSettings(field: any) {
    return this.http.put(this.baseUrl + 'notification_settings/update_settings', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getNotificatinSettings() {
    return this.http.get(this.baseUrl + 'notification_settings', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
  getServices() {
    return this.http.get(this.baseUrl + 'services', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  restPasswordMsg(field: any) {
    return this.http.post(this.baseUrl + 'auth/forgot_password', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }

  restPassword(field: any) {
    return this.http.put(this.baseUrl + 'auth/reset_password', field, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
      })
    });
  }
}
