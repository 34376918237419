import {Component, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject} from "rxjs/index";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/internal/operators";
import {ProfileService} from "../../../services/profile.service";
import {User} from "../../user-profile";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {PortfolioModalComponent} from "../../portfolio-modal/portfolio-modal.component";
import {QuestionModalService} from "../../../services/question-modal.service";
import {ToastrService} from "ngx-toastr";
import {ReportModalComponent} from "../../report-modal/report-modal.component";
import { FavouriteService } from 'src/app/services/favourite.service';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.css']
})
export class ProfileViewComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  userId: any;
  data = new User();
  loader: any;
  userInfo: any;
  localUserId: any;
  user_type = 0;
  badges: any;
  reviewsList: any[] = [];
  reviewCurrentPage: any;
  reviewTimeStamp: any;
  reviewPagination: any;
  moreReviewsAvailable: boolean;
  noReviews = false;
  skillsList: any[] = [];
  skillCurrentPage: any;
  skillTimeStamp: any;
  skillPagination: any;
  moreSkillsAvailable: boolean;
  noSkills = false;
  portfolioList: any[] = [];
  CurrentPage: any;
  TimeStamp: any;
  Pagination: any;
  moreAvailable: boolean;
  noPortfolios = false;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  enLang:boolean;
  lang:any;
  constructor(private route: ActivatedRoute, private profileService: ProfileService, private renderer: Renderer2,
              private modalService: NgbModal, private questionModalService: QuestionModalService,
              private toaster: ToastrService, private routerR: Router,private favService:FavouriteService) {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      params => this.userId = params['id']
    );
    this.routerR.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.questionModalService.getMessage().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      if (data === 'success') {
        this.ngOnInit();
      }
    });
    if(localStorage.getItem('aydoLang') == 'en') {
      this.enLang = true;
    }
    else {
      this.enLang = false;
    }
  }

  ngOnInit() {
    if(localStorage.getItem('aydoLang')) {
      this.lang = localStorage.getItem('aydoLang');
       
     }
    this.reviewsList = [];
    this.skillsList = [];
    this.getUserDetail();
    this.getUserReviews('', '');
    this.getUserSkills('', '');
    this.getUserPortfolio();

    this.userInfo = localStorage.getItem('aydo-app-user');
    if (this.userInfo != null) {
      const user_info = JSON.parse(this.userInfo);
      this.localUserId = user_info.id;
    }
    if (this.userInfo != null) {
      this.getIdBadges();
    }
  }
  removeFavourite() {
    this.favService.delUser(this.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.toaster.success('User has been removed from your favorites list');
        this.ngOnInit();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  getUserDetail() {
    this.loader = true;
    this.profileService.getUserProfile(this.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        this.data = res;
        this.loader = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  getIdBadges() {
    this.loader = true;
    this.profileService.getIdBadges(this.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        this.badges = res;
        this.loader = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  userChange() {
    this.reviewsList = [];
    this.getUserReviews('', '');
  }

  openCv(url) {
    window.open(url, '_blank');
  }

  openModal(list, index) {
    const info = {
      list: list,
      index: index,
      userId: this.userId
    };
    const modelRef = this.modalService.open(PortfolioModalComponent, this.ngbModalOptions);
    modelRef.componentInstance.portfolioInfo = info;
  }

  getUserReviews(page, time) {
    this.loader = true;
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.profileService.getUserReviews(this.userId, this.user_type, page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.reviewPagination = data['page_info'];
        this.reviewCurrentPage = this.reviewPagination.page;
        this.reviewTimeStamp = this.reviewPagination.timestamp;
        this.moreReviewsAvailable = this.reviewPagination.more_available;
        this.reviewsList = this.reviewsList.concat(data['reviews']);
        this.loader = false;
        if (this.reviewsList.length === 0) {
          this.noReviews = true;
        }
        if (this.reviewsList.length > 0) {
          this.noReviews = false;
        }

      }
    );
  }

  getUserSkills(page, time) {
    this.loader = true;
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.profileService.getUserSkills(this.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.skillPagination = data['page_info'];
        this.skillCurrentPage = this.skillPagination.page;
        this.skillTimeStamp = this.skillPagination.timestamp;
        this.moreSkillsAvailable = this.skillPagination.more_available;
        this.skillsList = data['skills'];
        this.loader = false;
        if (this.skillsList.length === 0) {
          this.noSkills = true;
        }
        if (this.skillsList.length > 0) {
          this.noSkills = false;
        }

      }
    );
  }

  getUserPortfolio() {
    this.loader = true;
    this.profileService.getUserPortfolio(this.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.Pagination = data['page_info'];
        this.portfolioList = data['portfolios'];
        this.loader = false;
        if (this.portfolioList.length === 0) {
          this.noPortfolios = true;
        }
        if (this.portfolioList.length > 0) {
          this.noPortfolios = false;
        }

      }
    );
  }

  userReport() {
    const repoInfo = {
      resourceType: 1,
      q_id: this.userId
    };
    const modalRef = this.modalService.open(ReportModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.repoInfo = repoInfo;
  }

  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreReviewsAvailable === true) {
        this.reviewCurrentPage++;
        this.getUserReviews(this.reviewCurrentPage, this.reviewTimeStamp);
      }
    }
  }

  navigateSkills(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreSkillsAvailable === true) {
        this.skillCurrentPage++;
        this.getUserSkills(this.skillCurrentPage, this.skillTimeStamp);
      }
    }
  }

  addFavourite() {
    this.profileService.favUser(this.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('User added to your favourites');
        this.ngOnInit();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  like(item:any) {
    if(item.liked_by_me == true) {
      this.favService.unLikePortfolio(item.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
       (data: any) => {
         this.toaster.success('Success');
         this.portfolioList = [];
       this.getUserPortfolio();
       },
       error => {
         this.toaster.error(error.error.errors);
       }
     );
    }
    if(item.liked_by_me == false) {
     this.favService.likePortfolio(item.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.toaster.success('Success');
        this.portfolioList = [];
        this.getUserPortfolio();

 
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
   }
   }

   requestAQuote() {
     this.routerR.navigate(['/post-job',{aydoer:this.userId}]);
   }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
