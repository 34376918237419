import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { SigninComponent } from "../../auth/signin/signin.component";
import { ContactComponent } from 'src/app/dashboard/contact/contact.component';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PostTaskComponent } from '../post-task/post-task.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit, OnDestroy {
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  private ngUnsubscribe: Subject<any> = new Subject();
  isSticky = false;
  toggleNavbar: any;
  user_info: any;
  name: any;
  subject: any;
  email: any;
  success = false;
  @ViewChild('element')
  element: ElementRef;
  constructor(private modalService: NgbModal, private renderer: Renderer2, private route: ActivatedRoute, private sharedService: SharedService,
    private toaster: ToastrService) {
    this.renderer.addClass(document.body, 'about-index');
  }

  ngOnInit() {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && document.getElementById(fragment) != null) {
        document.getElementById(fragment).scrollIntoView({ behavior: 'smooth' });
      }
    });
    this.user_info = localStorage.getItem('aydo-app-user');
  }
  getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      window.open( 
        "https://apps.apple.com/pk/app/aydo/id1548434919", "_blank");
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      window.open( 
        "https://apps.apple.com/pk/app/aydo/id1548434919", "_blank");
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      // os = 'Windows';
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    } else if (/Android/.test(userAgent)) {
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    } else  {
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    }
  
  }
  @HostListener('scroll', ['$event'])
  checkScroll(event) {
    if (event.target.scrollTop >= 50) {
      this.isSticky = true;
    }
    else {
      this.isSticky = false;
    }
  }
  openContactModal() {
    const modalRef = this.modalService.open(ContactComponent);
  }
  onClickContact(): void {
    this.element.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  signInModal() {
    const modalRef = this.modalService.open(SigninComponent);
    modalRef.componentInstance.signin = 'signin';
  }
  taskModal() {
    const modalRef = this.modalService.open(PostTaskComponent,this.ngbModalOptions);
  }
  signUpModal() {
    const modalRef = this.modalService.open(SigninComponent);
    modalRef.componentInstance.signup = 'signup';
  }
  submit(f: any) {
    this.success = true;
    const info = {
      name: this.name,
      i_want_to: this.subject,
      email: this.email
    }
    this.sharedService.contactUs(info).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success(data['message']);
        setTimeout(() => {
          this.success = false;
        }, 2000)
      },
      error => {

        this.toaster.error('Error');
      }
    )

  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'about-index');
  }
}
