import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AuthLoginService} from "../auth.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs/index";
import {takeUntil} from "rxjs/internal/operators";
import {ForgotPasswordComponent} from "../forgot-password/forgot-password.component";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {User} from "../signup/user-create";
import { JobDataService } from 'src/app/services/jobData.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { VerifyPhoneModalComponent } from 'src/app/dashboard/verify-phone-modal/verify-phone-modal.component';
import { GoogleLoginProvider,AuthService, FacebookLoginProvider } from 'angular-6-social-login';
declare var gtag
declare var FB;
declare var window: any;
declare var gapi: any;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit, OnDestroy {
   clientId: string = "966471662835-5ic6ii64q5ou5bi37idn7bjt3m9t022k.apps.googleusercontent.com";
  scope = [
    'profile',
    'email',
    // 'https://www.googleapis.com/auth/plus.me',
    // 'https://www.googleapis.com/auth/contacts.readonly',
    // 'https://www.googleapis.com/auth/admin.directory.user.readonly'
  ].join(' ');
  public auth2: any;
  @ViewChild("googleBtn") googleBtn: ElementRef;
  fbtoken: any = {'token': ''};
  @Input() signin: any;
  @Input() signup: any;
  data: any = {};
  error: any;
  authToken: any;
  userInfo: any;
  userData = new User();
  countries: any[] = [];
  selectCountry: any;
  terms: any;
  modal1 = false;
  modal2 = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  digitLength:any;
  jobInfo:any;
  hide: boolean = true;
  message;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  SocialLoginContent:any;
  googleLoginAccessToken:any;
  fbLoginAccessToken:any;

  constructor(private authService: AuthLoginService, private router: Router, private toastr: ToastrService, private modalService: NgbModal,
              private renderer: Renderer2, public activeModal: NgbActiveModal,
              private postJobService:JobDataService,private msgService:MessagingService,private ngZone: NgZone,
              private socialAuthService: AuthService) {
    this.renderer.addClass(document.body, 'home-aydoo');
  }

  ngOnInit() {
    if (this.signin === 'signin') {
      this.modal1 = true;
    }
    else if (this.signup === 'signup') {
      this.modal2 = true;
    }
    else {
      this.modal1 = true
      this.jobInfo = this.signup;
    }
    this.fetchCountries();
    this.fbInit();
  }
fbInit() {
  (window as any).fbAsyncInit = function () {

    FB.init({
      appId: '720665841709645',
      cookie: true,
      xfbml: true,
      version: 'v4.0'
    });
    FB.AppEvents.logPageView();
  };

  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
}



  onSignin() {
    gtag('event', 'signin', {
      'event_category' : 'User',
      'event_label' : 'user_signin'
    });
    this.authService.signInUser(this.data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        const userData = data['user'];
        const userPhone = userData['phone_confirmed'];
        this.authToken = data['token'];
        localStorage.setItem('Authorization', this.authToken);
        if(userPhone == false) {
          this.modalService.open(VerifyPhoneModalComponent,this.ngbModalOptions);
        }
        else {
        this.userInfo = JSON.stringify(data['user']);
         localStorage.setItem('aydo-app-user', this.userInfo);
         this.msgService.getPermission();
         this.msgService.receiveMessage();
         this.message = this.msgService.currentMessage;
         if(this.jobInfo) {
            this.router.navigate(['post-job']);
           localStorage.setItem('aydoJobInfo',JSON.stringify(this.jobInfo));
         }
         if(!this.jobInfo) {
           this.router.navigate(['ad']);
         }
        }
       
        this.activeModal.dismiss('Cross click');
        
        

      },
      error => {
        this.toastr.error(error.error.errors);

      }
    );
  }
  toggleField() {
    this.hide = !this.hide;
  }
  checkLength() {
    if(this.userData.phone != null) {
    this.userData.phone = null;
    }
    if (this.selectCountry) {
      this.userData.country_code = this.selectCountry.country_code;
      this.digitLength = 12 - this.userData.country_code.toString().length;
    }
  }
  onSignup() {
    gtag('event', 'signup', {
      'event_category' : 'User',
      'event_label' : 'user_signup'
    });
    if (this.selectCountry) {
      this.userData.country_code = this.selectCountry.country_code;
    }
    this.authService.createUser(this.userData).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.authToken = data['token'];
        // this.userInfo = JSON.stringify(data['user']);
        localStorage.setItem('Authorization', this.authToken);
        // localStorage.setItem('aydo-app-user', this.userInfo);
      //   if(this.jobInfo) {
      //     this.router.navigate(['post-job']);
      //    localStorage.setItem('aydoJobInfo',JSON.stringify(this.jobInfo));
      //  }
      //  if(!this.jobInfo) {
      //    this.router.navigate(['ad']);
      //  }
        this.activeModal.dismiss('Cross click');
        this.modalService.open(VerifyPhoneModalComponent,this.ngbModalOptions);
      },
      error => {
        this.toastr.error(error.error.errors);

      }
    );
  }

  skipModal() {
    this.activeModal.dismiss('Cross click');
  }

  fetchCountries() {
    this.authService.getCountries().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res:any) => {
        this.countries = res;
        for( let country of this.countries){
          if(country.country_code == "971" ){
           this.selectCountry = country;
          }
       }
      },
      error => {
        console.log(error);
      }
    );
  }

  open_modal() {
    this.activeModal.dismiss('Cross click');
    this.modalService.open(ForgotPasswordComponent,this.ngbModalOptions);
  }

  signInModal() {
    this.modal1 = true;
    this.modal2 = false;
  }

  signUpModal() {
    this.modal1 = false;
    this.modal2 = true;
  }
  closeModal() {
    this.activeModal.dismiss('Cross click');

  }
  submitFb() {
    FB.login((response) => {
      if (response.authResponse) {
        const { userID } = response.authResponse;
        this.fbtoken.token = response.authResponse.accessToken;
        // this.authService.fbLogin(this.fbtoken).subscribe(
        //   data => {

        //     this.authToken = data['authorization'];
        //     this.userInfo = JSON.stringify(data['user']);
           
        //     // console.log(data);
        //     this.ngZone.run(() => {
        //       this.router.navigate(['/']);
        //     });
        //   },
        //   error => {
        //     console.log(error);
        //   }
        // );
        FB.api(`/${userID}/?fields=name,first_name,last_name,email,address,birthday,gender,picture`, this.fbResponse);
      }
      else {
        console.log('User login failed');
      }
    });
  }
  fbResponse(response) {
    console.log(response);
  }
  public signinWithGoogle (content:any) {
    this.SocialLoginContent = content;
    let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
 
    this.socialAuthService.signIn(socialPlatformProvider)
    .then((userData) => {
       this.sendToRestApiMethod(userData.idToken);
    });
 }
 sendToRestApiMethod(token: string) : void {
   this.googleLoginAccessToken = token;
  const params = {
    access_token: token
    
  }
  this.authService.googleSignInUser(params).subscribe((data: any) => {
    const userData = data['user'];
    const userPhone = userData['phone_confirmed'];
    this.authToken = data['token'];
    localStorage.setItem('Authorization', this.authToken);
    if(userPhone == false) {
      this.modalService.open(VerifyPhoneModalComponent,this.ngbModalOptions);
    }
    else {
    this.userInfo = JSON.stringify(data['user']);
     localStorage.setItem('aydo-app-user', this.userInfo);
     this.msgService.getPermission();
     this.msgService.receiveMessage();
     this.message = this.msgService.currentMessage;
     if(this.jobInfo) {
        this.router.navigate(['post-job']);
       localStorage.setItem('aydoJobInfo',JSON.stringify(this.jobInfo));
     }
     if(!this.jobInfo) {
       this.router.navigate(['ad']);
     }
    }
   
    this.activeModal.dismiss('Cross click');

  }, error => {
    if (error.error.code === 1) {
      this.modalService.open(this.SocialLoginContent,this.ngbModalOptions);
    }
    else {
    this.toastr.error(error.error.errors);

    }
  })
}
onSubmitGoogle() {
  if (this.selectCountry) {
    this.userData.country_code = this.selectCountry.country_code;
  }
  const params = {
    access_token: this.googleLoginAccessToken,
    phone:this.userData.phone,
    country_code:this.userData.country_code

  }
  this.authService.googleSignInUser(params).subscribe((res: any) => {
    this.authToken = res['token'];
    localStorage.setItem('Authorization', this.authToken);
    this.modalService.dismissAll('Cross click');
    this.modalService.open(VerifyPhoneModalComponent,this.ngbModalOptions);

  }, error => {
   
    this.toastr.error(error.error.errors);

  })
}




public submitFbLogin (content:any) {
  this.SocialLoginContent = content;
  let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;

  this.socialAuthService.signIn(socialPlatformProvider)
  .then((userData) => {
     this.sendToRestApiFbMethod(userData.token);
  });
}
sendToRestApiFbMethod(token: string) : void {
 this.fbLoginAccessToken = token;
const params = {
  access_token: token
  
}
this.authService.fbSignInUser(params).subscribe((data: any) => {
  const userData = data['user'];
  const userPhone = userData['phone_confirmed'];
  this.authToken = data['token'];
  localStorage.setItem('Authorization', this.authToken);
  if(userPhone == false) {
    this.modalService.open(VerifyPhoneModalComponent,this.ngbModalOptions);
  }
  else {
  this.userInfo = JSON.stringify(data['user']);
   localStorage.setItem('aydo-app-user', this.userInfo);
   this.msgService.getPermission();
   this.msgService.receiveMessage();
   this.message = this.msgService.currentMessage;
   if(this.jobInfo) {
      this.router.navigate(['post-job']);
     localStorage.setItem('aydoJobInfo',JSON.stringify(this.jobInfo));
   }
   if(!this.jobInfo) {
     this.router.navigate(['ad']);
   }
  }
 
  this.activeModal.dismiss('Cross click');

}, error => {
  if (error.error.code === 1) {
    this.modalService.open(this.SocialLoginContent,this.ngbModalOptions);
  }
  else {
  this.toastr.error(error.error.errors);

  }
})
}
onSubmitFb() {
if (this.selectCountry) {
  this.userData.country_code = this.selectCountry.country_code;
}
const params = {
  access_token: this.googleLoginAccessToken,
  phone:this.userData.phone,
  country_code:this.userData.country_code

}
this.authService.fbSignInUser(params).subscribe((res: any) => {
  this.authToken = res['token'];
  localStorage.setItem('Authorization', this.authToken);
  this.modalService.dismissAll('Cross click');
  this.modalService.open(VerifyPhoneModalComponent,this.ngbModalOptions);

}, error => {
 
  this.toastr.error(error.error.errors);

})
}
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'home-aydoo');
  }
}
