import {Component, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject} from "rxjs/index";
import {JobService} from "../../../services/job.service";
import {ToastrService} from "ngx-toastr";
import {takeUntil} from "rxjs/internal/operators";
import {AuthLoginService} from "../../../auth/auth.service";
import {LocationService} from "../../../services/location.service";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {LocationModalComponent} from "../../location-modal/location-modal.component";
import {Options} from "ng5-slider";

@Component({
  selector: 'app-jobs-alert-modal',
  templateUrl: './jobs-alert-modal.component.html',
  styleUrls: ['./jobs-alert-modal.component.css']
})
export class JobsAlertModalComponent implements OnInit, OnDestroy {
  textDir: string = 'ltr';
  modal1 = false;
  modal2 = false;
  modal3 = false;
  modal4 = false;
  alertsList: any[] = [];
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  loader = false;
  services: any;
  selectService: any;
  serviceArray: any[] = [];
  serviceIds: any[] = [];
  private ngUnsubscribe: Subject<any> = new Subject();
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  location: any;
  address: any;
  distance = 0;
  options: Options = {
    floor: 0,
    ceil: 50,
  };
  presence = 2;
  isEnabled = true;
  alertId: any;

  constructor(private jobService: JobService, private renderer: Renderer2, private toastr: ToastrService,
              private authService: AuthLoginService, private modalService: NgbModal, private locationService: LocationService) {
                if(localStorage.getItem('aydoLang')) {
     
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                  }
                  else {
                    this.textDir = 'ltr'
                  }
                }
    this.renderer.addClass(document.body, 'skills');
    this.locationService.getLocation().pipe(takeUntil(this.ngUnsubscribe)).subscribe(location => {
      this.location = location;
      this.address = location.address;
    });
  }

  ngOnInit() {
    this.modal1 = true;
    this.get_alerts('', '');
    this.get_services();
  }

  get_alerts(page, time) {
    this.loader = true;
    if (!page && !time) {
      page = '';
      time = '';
    }
    this.jobService.getAlerts(page, time).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.pagination = data['page_info'];
        this.currentPage = this.pagination.page;
        this.timeStamp = this.pagination.timestamp;
        this.total = new Array(this.pagination.total_pages);
        this.totalPages = this.pagination.total_pages;
        this.moreAvailable = this.pagination.more_available;
        this.alertsList = this.alertsList.concat(data['alerts']);
        this.loader = false;

      }, error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  open(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(LocationModalComponent, this.ngbModalOptions);
  }

  get_services() {
    this.authService.getServices().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.services = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.get_alerts(this.currentPage, this.timeStamp);
      }
    }
  }

  newAlert() {
    this.modal1 = false;
    this.modal3 = false;
    this.modal2 = true;
    this.serviceArray.length = 0;
    this.serviceIds.length = 0;
    this.distance = 0;
    this.location = null;
    this.presence = 2;
    this.address = null;
    this.selectService = null;
    this.isEnabled = true;

  }

  getService() {
    var index = this.serviceArray.findIndex(item => item.id === this.selectService.id);
    if(index > -1) {
      return true;
    }
    else {
      this.serviceArray.push(this.selectService);
    }
    
  }

  deleteLinks(item) {
    const index = this.serviceArray.indexOf(item);
    this.serviceArray.splice(index, 1);
  }

  saveAlert() {
    if(this.presence == 1) {
      this.distance = null;
      this.location = null;
    }
    for (const item of this.serviceArray) {
      this.serviceIds.push(item.id);
    }
    const alertInfo = {
      service_ids: this.serviceIds,
      location: this.location,
      distance: this.distance,
      type_of_presence: this.presence,
      is_enabled: this.isEnabled
    };
    this.jobService.postAlert(alertInfo).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.toastr.success('Alert has been created');
        this.alertsList = [];
        this.get_alerts('', '');
        this.modal2 = false;
        this.modal1 = true;
        this.serviceArray.length = 0;
        this.serviceIds.length = 0;
        this.distance = 0;
        this.location = null;
        this.presence = 2;
        this.address = null;
        this.selectService = null;

      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );

  }

  updateAlert() {
    if(this.presence == 1) {
      this.distance = null;
      this.location = null;
    }
    for (const item of this.serviceArray) {
      this.serviceIds.push(item.id);
    }
    const alertInfo = {
      service_ids: this.serviceIds,
      location: this.location,
      distance: this.distance,
      type_of_presence: this.presence,
      is_enabled: this.isEnabled
    };
    this.jobService.updateAlert(alertInfo, this.alertId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.toastr.success('Alert has been updated');
        this.alertsList = [];
        this.get_alerts('', '');
        this.modal4 = false;
        this.modal1 = true;
        this.serviceArray.length = 0;
        this.serviceIds.length = 0;
        this.distance = 0;
        this.location = null;
        this.presence = 2;
        this.address = null;
        this.selectService = null;

      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );

  }

  detailAlert(detail) {
    this.serviceArray =[];
    this.serviceIds = [];
    this.modal3 = false;
    this.modal4 = true;
    this.alertId = detail.id;
    for (const item of detail.services) {
      this.serviceArray.push(item);
      // this.serviceIds.push(item.id);
    }
    this.location = detail.location;
    if (this.location != null) {
      this.address = detail.location.address;
    }
    this.distance = detail.distance;
    this.presence = detail.type_of_presence;
    this.isEnabled = detail.is_enabled;
  }

  backList() {
    this.modal4 = false;
    this.modal3 = true;
    this.serviceArray.length = 0;
    this.serviceIds.length = 0;
    this.distance = 0;
    this.location = null;
    this.presence = 2;
    this.address = null;
    this.selectService = null;

  }

  toggleClicked(list) {
    if (list.is_enabled === true) {
      this.isEnabled = false;
    }
    else if (list.is_enabled === false) {
      this.isEnabled = true;
    }
    const checkEnabled = {
      is_enabled: this.isEnabled
    };
    this.jobService.updateAlert(checkEnabled, list.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.toastr.success('Alert has been updated');
        // this.alertsList = [];
        // this.get_alerts('', '');
        this.isEnabled = true;

      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  editList() {
    this.modal1 = false;
    this.modal3 = true;
  }

  delAlert(id) {
    this.jobService.delAlert(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.toastr.success('Alert has been deleted');
        this.alertsList = [];
        this.get_alerts('', '');
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }

  doneList() {
    this.modal3 = false;
    this.modal1 = true;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'skills');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
