import {Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ElementRef} from '@angular/core';
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {SigninComponent} from "../../auth/signin/signin.component";
import { ContactComponent } from 'src/app/dashboard/contact/contact.component';
import { ActivatedRoute } from '@angular/router';
import { PostTaskComponent } from '../post-task/post-task.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  isSticky = false;
  toggleNavbar: any;
  user_info:any;
  @ViewChild('element')
  element: ElementRef;
  constructor(private modalService: NgbModal, private renderer: Renderer2,private route:ActivatedRoute) {
    this.renderer.addClass(document.body, 'home-index');
  }

  ngOnInit() {
    this.user_info = localStorage.getItem('aydo-app-user');
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && document.getElementById(fragment) != null) {
        document.getElementById(fragment).scrollIntoView({behavior: 'smooth'});
      }
    });


    
  }

  @HostListener('scroll', ['$event'])
  checkScroll(event) {
    if (event.target.scrollTop >= 2) {
      this.isSticky = true;
    }
    else {
      this.isSticky = false;
    }
  }
  getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      window.open( 
        "https://apps.apple.com/pk/app/aydo/id1548434919", "_blank");
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      window.open( 
        "https://apps.apple.com/pk/app/aydo/id1548434919", "_blank");
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      // os = 'Windows';
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    } else if (/Android/.test(userAgent)) {
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    } else  {
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    }
  
  }
  openContactModal() {
    const modalRef = this.modalService.open(ContactComponent);
  }
  taskModal() {
    const modalRef = this.modalService.open(PostTaskComponent,this.ngbModalOptions)
  }
  signInModal() {
    const modalRef = this.modalService.open(SigninComponent);
    modalRef.componentInstance.signin = 'signin';
  }

  signUpModal() {
    const modalRef = this.modalService.open(SigninComponent);
    modalRef.componentInstance.signup = 'signup';
  }
  onClickWork(): void {
    this.element.nativeElement.scrollIntoView({behavior: 'smooth'}); 
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'home-index');
  }
}
