import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  saveProfile(fields: any) {
    return this.http.put(this.baseUrl + 'users/edit_profile', fields, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getProfile() {
    return this.http.get(this.baseUrl + 'users/my_profile', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getUserProfile(id: any) {
    if(localStorage.getItem('aydoLang') && localStorage.getItem('Authorization')) {
      return this.http.get(this.baseUrl +  'users/' + id + '/profile', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': localStorage.getItem('aydoLang'),
          'Authorization': localStorage.getItem('Authorization'), 
  
          
        })
      });
    }
    else {
      return this.http.get(this.baseUrl +  'users/' + id + '/profile', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': 'en'
  
          
        })
      });
    }
  }

  getIdBadges(id: any) {
    return this.http.get(this.baseUrl + 'users/' + id + '/id_badges', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }

  getUserReviews(id: any, type: any, page: any, time: any) {
   
    if(localStorage.getItem('aydoLang')) {
      return this.http.get(this.baseUrl + 'users/' + id + '/reviews/?page=' + page + '&timestamp=' + time + '&review_type=' + type, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': localStorage.getItem('aydoLang')
  
          
        })
      });
    }
    else {
      return this.http.get(this.baseUrl + 'users/' + id + '/reviews/?page=' + page + '&timestamp=' + time + '&review_type=' + type, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': 'en'
  
          
        })
      });
    }
  }

  getUserSkills(id: any) {
    if(localStorage.getItem('aydoLang')) {
      return this.http.get(this.baseUrl + 'users/' + id + '/skills/?limit=100', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': localStorage.getItem('aydoLang')
  
          
        })
      });
    }
    else {
      return this.http.get(this.baseUrl + 'users/' + id + '/skills/?limit=100', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': 'en'
  
          
        })
      });
    }
  }
  getUserPortfolio(id: any) {
    if(localStorage.getItem('aydoLang') && localStorage.getItem('Authorization')) {
      return this.http.get(this.baseUrl + 'users/' + id + '/portfolios/?limit=100', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': localStorage.getItem('aydoLang'),
        'Authorization': localStorage.getItem('Authorization'),                           

  
          
        })
      });
    }
    else {
      return this.http.get(this.baseUrl + 'users/' + id + '/portfolios/?limit=100', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ApiKey': '099280a3038f6abd',
          'locale': 'en'
  
          
        })
      });
    }
  }
  favUser(id: any) {
    return this.http.put(this.baseUrl + 'users/' + id + '/mark_favorite', '', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': '099280a3038f6abd',
        'Authorization': localStorage.getItem('Authorization'),                           
'locale':localStorage.getItem('aydoLang')
      })
    });
  }
}
