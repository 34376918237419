import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router) {
  }

  canActivate() {
    if (localStorage.getItem('Authorization')) {
      return true;
    } else {
      this.router.navigate(['signin']);
      return false;
    }

  }
}
