import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthLoginService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-verify-phone-modal',
  templateUrl: './verify-phone-modal.component.html',
  styleUrls: ['./verify-phone-modal.component.css']
})
export class VerifyPhoneModalComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();

pin:any;
resendPinDisabled: boolean = false;
timer: number = 60;

  constructor(private renderer: Renderer2,private authService: AuthLoginService,private toastr: ToastrService,
    public activeModal: NgbActiveModal) { 
    this.renderer.addClass(document.body, 'forgot-password');
  }

  ngOnInit() {
  }
  resendCode() {
    this.resendPinDisabled = true;
    this.authService.resendVerificationSms().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res: any) => {
        this.toastr.success('',res.message,{ timeOut: 30000 });
        const intervalId = setInterval(() => {
          if (this.timer > 0) {
            this.timer--;
          } else {
            clearInterval(intervalId); // Stop the timer
            this.resendPinDisabled = false; 
          }
        }, 1000); 
      },
      error => {
        this.toastr.error(error.error.errors);
        this.resendPinDisabled = false;
      }
    );
  }
  
  verifyCode() {
    const resetPassword = {
      pin: this.pin,
    };
    this.authService.verifyPhone(resetPassword).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res: any) => {
        this.toastr.success( res.message );
        this.activeModal.dismiss('Cross Click');
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'forgot-password');
   
  }
}
// tesingphone@gmail.com
// Abcd@1234567