import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FileUploader} from "ng2-file-upload";
import {DocumentService} from "../../../services/document.service";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {LocationModalComponent} from "../../location-modal/location-modal.component";
import {Subject, Subscription} from "rxjs";
import {LocationService} from "../../../services/location.service";
import {NgForm} from "@angular/forms";
import {NationalityComponent} from "../../nationality/nationality.component";
import {NationalityService} from "../../../services/nationality.service";
import {LanguagesModalComponent} from "../../languages-modal/languages-modal.component";
import {LanguagesService} from "../../../services/languages.service";
import {ProfileService} from "../../../services/profile.service";
import {ToastrService} from "ngx-toastr";
import {User} from "../../user-profile";
import {AuthLoginService} from "../../../auth/auth.service";
import {takeUntil} from "rxjs/internal/operators";
import { IdBadgeService } from 'src/app/services/id-badge.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Router } from '@angular/router';
declare var gtag


@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit, OnDestroy {
  @ViewChild("image") imageContent: TemplateRef<any>;
  @ViewChild('fileeInput')
myInputVariable: ElementRef;
  loader = false;
  img_url: any;
  file_name: any;
  avatar_id: any;
  file_error: any;
  gender: any;
  company_registered = 0;
  company_name:any;
  datePicker: any;
  minDate: any;
  trn:any;
  trade_license_number:any
  license_img_url:any;
  license_id:any;
  trn_img_url:any;
  trn_id:any;
  company_img_url:any;
  company_id:any;
  data = new User();
  uploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',

  });
  licenseImgUploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',

  });
  trnImgUploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',

  });
  companyImgUploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',

  });
  address: any;
  first_name: any;
  last_name: any;
  birthday: any;
  nationality: any;
  tagline: any;
  introduction: any;
  postJob: any;
  makeMoney: any;
  transport = [
    {name: 'Bike',ar_name:'دراجة'},
    {name: 'Car',ar_name:'سيارة'},
    {name: 'Truck',ar_name:'شاحنة نقل'},
    {name: 'Public Transp',ar_name:'مواصلات عامة'},
    {name: 'Online',ar_name:'عبر الإنترنت (أونلاين)'},
    {name: 'Other',ar_name:'وسائل أخرى'}

  ];
  checkedList:any[] = [];
  location: any;
  languages: any;
  displayLan: any;
  updateUser: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  ngbModalOptions_1: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    windowClass:'job-modal-questions'
  };
  badges:any[] = [];
  emirates_id_status:any;
  countries: any;
  selectCountry: any;
  enLang = true;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn:any;
  userId:any;
  loading:boolean = false;
  constructor(private documentService: DocumentService, private modalService: NgbModal, private locationService: LocationService,
              private countryService: NationalityService, private LangService: LanguagesService, private profileService: ProfileService,
              private toastr: ToastrService, private authService: AuthLoginService,private badgeService:IdBadgeService,public activeModal: NgbActiveModal,
              private router:Router) {
                this.minDate = {
                  year: new Date().getFullYear() - 18,
                  month: new Date().getMonth() + 1,
                  day: new Date().getDate()
                };
    this.locationService.getLocation().pipe(takeUntil(this.ngUnsubscribe)).subscribe(location => {
      this.location = location;
      this.address = location.address;
    });
    this.countryService.getCountry().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.nationality = data;
    });
    this.LangService.getLanguage().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.languages = data;
      this.displayLan = this.languages.join();
    });
      if(localStorage.getItem('aydoLang') == 'en') {
        this.enLang = true;
      }
      else {
        this.enLang = false;
      }
  }

  ngOnInit() {
    this.get_profile();
    this.getIdBadges();
    this.fetchCountries();
    this.postJob = true;

  }
  fetchCountries() {
    this.authService.getNationalities().subscribe(
      res => {
        this.countries = res;
      },
      error => {
        console.log(error);
      }
    );
  }
  getIdBadges() {
    this.badgeService.getIdBadges().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.badges = res;
      this.emirates_id_status = this.badges['emirates_id_status'];
    }
    );
  }
  open(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modalRef = this.modalService.open(LocationModalComponent, this.ngbModalOptions);
  }
 openModal(content) {
   this.modalService.open(content,this.ngbModalOptions);
 }
  nationality_modal(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modal_ref = this.modalService.open(NationalityComponent, this.ngbModalOptions);
    if(this.nationality) {
      this.countryService.sendCountry(this.nationality);
    }
  }

  open_language_modal(event) {
    event.srcElement.blur();
    event.preventDefault();
    const modal_ref = this.modalService.open(LanguagesModalComponent, this.ngbModalOptions);
    if(this.displayLan) {
      this.LangService.sendLanguage(this.data.profile.languages);
    }
  }

  public onFileSelected(event:any) {
    this.imageChangedEvent = event;
    this.modalService.open(this.imageContent);
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    )
    return this.fileToReturn;
  }
  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  CropImage() {
      this.loader = true;
    const file = this.fileToReturn;
    this.file_name = file.name;
    if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
      this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          this.avatar_id = res['id'];
          this.img_url = res['url'];
          this.loader = false;
          this.modalService.dismissAll('Cross click');
          this.myInputVariable.nativeElement.value = "";
        }, error => {
          this.loader = false;
          console.log('changePicture error', error);
        });
    } else {
      this.loader = false;
      this.file_error = 'Please Upload Only Images in jpeg and png format';
    }
  }
  public onLicenseImageSelected(event: EventEmitter<File[]>) {
    this.loader = true;
    const file: File = event[0];
    if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
      this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          this.license_id = res['id'];
          this.license_img_url = res['url'];
          this.loader = false;

        }, error => {
          this.loader = false;
          console.log('changePicture error', error);
        });
    } else {
      this.loader = false;
      this.toastr.error('Please Upload Only Images in jpeg and png format');
    }
  }
  public onTrnImageSelected(event: EventEmitter<File[]>) {
    this.loader = true;
    const file: File = event[0];
    if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
      this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          this.trn_id = res['id'];
          this.trn_img_url = res['url'];
          this.loader = false;

        }, error => {
          this.loader = false;
          console.log('changePicture error', error);
        });
    } else {
      this.loader = false;
      this.toastr.error('Please Upload Only Images in jpeg and png format');
    }
  }
  public onCompanyImageSelected(event: EventEmitter<File[]>) {
    this.loader = true;
    const file: File = event[0];
    if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
      this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          this.company_id = res['id'];
          this.company_img_url = res['url'];
          this.loader = false;

        }, error => {
          this.loader = false;
          console.log('changePicture error', error);
        });
    } else {
      this.loader = false;
      this.toastr.error('Please Upload Only Images in jpeg and png format');
    }
  }
  toggleClicked(item) {
    const index = this.checkedList.indexOf(item.name);
    if (index === -1) {
      this.checkedList.push(item.name);
    } else {
      this.checkedList.splice(index, 1);
    }
  }

  get_profile() {
    this.profileService.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.userId = data.id;
        this.data = data;
        this.first_name = this.data.first_name;
        this.last_name = this.data.last_name;
        if (this.data.profile !== null) {
          const date = new Date(this.data.profile.dob);
          const expiryDate = new Date(this.data.profile.trade_license_expiry);
          this.birthday = {day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear()};
          this.nationality = this.data.profile.nationality;
          if(this.data.location) {
            this.address = this.data.location.address;

          }
          this.gender = this.data.profile.gender;
          this.displayLan = this.data.profile.languages.join();
          this.tagline = this.data.profile.tagline;
          this.introduction = this.data.profile.bio;
          if(this.data.profile.transportation == null) {
            this.checkedList = []
          }
          else {
            this.checkedList = this.data.profile.transportation;

          }
          
          this.makeMoney = this.data.profile.make_money;
           this.postJob = this.data.profile.post_jobs;
          this.datePicker = {day: expiryDate.getUTCDate(), month: expiryDate.getUTCMonth() + 1, year: expiryDate.getUTCFullYear()};
          this.company_registered = this.data.profile.company_registered;
          if(this.company_registered == 1) {
          this.company_name = this.data.profile.company_name;
          this.trn = this.data.profile.trn;
          this.trade_license_number = this.data.profile.trade_license_number;
          if(this.data.profile.company_profile) {
          this.company_img_url = this.data.profile.company_profile.url;
          }
          if(this.data.profile.trn_certificate) {
          this.trn_img_url = this.data.profile.trn_certificate.url;
          }
          if(this.data.profile.license_certificate) {
          this.license_img_url = this.data.profile.license_certificate.url;

          }
        }
     
        }
        if (this.data.avatar !== null) {
          this.img_url = this.data.avatar.url;
        }
      },
      error => {
        
        this.toastr.error(error.error.errors);
      }
    );
  }
  removeImg(type) {
    if(type == 'trn') {
      this.trn_img_url = '';
      this.trn_id = -1;
    }
    if(type == 'license') {
      this.license_img_url = '';
      this.license_id = -1;
    }
    if(type == 'company') {
      this.company_img_url = '';
      this.company_id = -1;
    }
  }
  removeProfileImg() {
    this.img_url = '';
    this.avatar_id = -1;
    this.save_profile();
  }
  save_profile() {
    gtag('event', 'update_profile', {
      'event_category' : 'User',
      'event_label' : 'user_update_profile'
    });
    this.loading = true;
    const dateString = new Date(this.birthday.year, this.birthday.month - 1, this.birthday.day + 1);
    if(this.datePicker) {
     var dateExpiry = new Date(this.datePicker.year, this.datePicker.month - 1, this.datePicker.day + 1);
    }
    const saveProfile = {
      dob: dateString,
      gender: this.gender,
      nationality: this.nationality.name,
      tagline: this.tagline,
      bio: this.introduction,
      post_jobs: this.postJob,
      transportation: this.checkedList,
      languages: this.languages,
      location: this.location,
      first_name: this.first_name,
      last_name: this.last_name,
      avatar_id: this.avatar_id,
      make_money: this.makeMoney,
      trade_license_expiry:dateExpiry,
      company_registered:this.company_registered,
      company_name:this.company_name,
      trn:this.trn,
      trade_license_number:this.trade_license_number,
      license_certificate_id:this.license_id,
      trn_certificate_id:this.trn_id,
      company_profile_id:this.company_id
    };
    // console.log(saveProfile);
    this.profileService.saveProfile(saveProfile).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.updateUser = JSON.stringify(data);
        localStorage.setItem('aydo-app-user', this.updateUser);
        this.toastr.success('Profile has been Saved!');
        this.loading = false;
        this.ngOnInit();
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );

  }
  licenseImgError() {
    this.toastr.error("Please Attach License Image ")
  }
  resend_link() {
    this.authService.resendEmail().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.toastr.success('Email has been sent to your email account.');
    }, error => {
      console.log(error);
    });
  }
  delete(content) {
    this.modalService.open(content,this.ngbModalOptions_1);
  }
  deleteAccount() {

    this.authService.deleteUser(this.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.modalService.dismissAll('Cross click');
        this.toastr.success('Deleted');
        this.router.navigate(['/all-jobs']);
        
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
