import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'app-certification-modal',
  templateUrl: './certification-modal.component.html',
  styleUrls: ['./certification-modal.component.css']
})
export class CertificationModalComponent implements OnInit, OnDestroy {

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'cert-modal');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'cert-modal');
  }
}
