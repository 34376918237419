import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';
import { mergeMapTo } from 'rxjs/operators';
import 'rxjs/add/operator/take';
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationListService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  baseUrl = environment.baseUrl;

    messaging = firebase.messaging();
    currentMessage = new BehaviorSubject(null);
  
    constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth,private http: HttpClient,
      private notificationService:NotificationListService) {}
  
    updateToken(token) {
      this.afAuth.authState.take(1).subscribe(user => {
        if (!user) {
          return;
        }
        const data = { [user.uid]: token };
        this.db.object('fcmTokens/').update(data);
      });
    }
  
    getPermission() {
      this.messaging
        .requestPermission()
        .then(() => {
          return this.messaging.getToken();
        })
        .then(token => {
          this.updateToken(token);
          this.sendTokenToServer(token);
        })
        .catch(err => {
          console.log('Unable to get permission to notify.', err);
        });
    }
  sendTokenToServer(token) {
    const sendToken = {
        fcm_token:token,
        device_type:2
      }
    this.notificationService.pushNotificationToken(sendToken).subscribe(data => {
    })
   
  }
    // receiveMessage() {
    //   this.messaging.onMessage(payload => {
    //     console.log('Message received. ', payload);
    //     const body = payload['notification']['body'];
    //     var options = {
    //       body:payload['notification']['body'],
    //       click_action:payload['notification']['click_action']
    //     }
    //     if(payload['notification']['click_action']) {
    //       console.log('click');
    //     }
    //     var n = new Notification('Notification says',options);
    // setTimeout(n.close.bind(n), 5000);
    //     this.currentMessage.next(payload);
    //   });
    // }
    receiveMessage() {
      this.messaging.onMessage(payload => {
        this.currentMessage.next(payload);
        
      });
    }
    
}