import {Component, EventEmitter, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject} from "rxjs/index";
import {ToastrService} from "ngx-toastr";
import {JobService} from "../../../services/job.service";
import {takeUntil} from "rxjs/internal/operators";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {QuestionModalService} from "../../../services/question-modal.service";
import {FileUploader} from "ng2-file-upload";
import {DocumentService} from "../../../services/document.service";
declare var gtag

@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.css']
})
export class QuestionModalComponent implements OnInit, OnDestroy {
  textDir: string = 'ltr';
  @Input() jobId: any;
  @Input() replyInfo: any;
  @Input() editInfo:any;
  @Input() replyEditInfo:any;

  content: any;
  reply_content: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  docuploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['pdf', 'image']

  });
  docArray: any[] = [];
  modal1 = false;
  modal2 = false;
  modal3 = false;
  loader = false;

  constructor(private jobService: JobService, private toaster: ToastrService, public activeModal: NgbActiveModal,
              private questionModalService: QuestionModalService, private renderer: Renderer2, private documentService: DocumentService) {
                if(localStorage.getItem('aydoLang')) {
     
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                  }
                  else {
                    this.textDir = 'ltr'
                  }
                }
    this.renderer.addClass(document.body, 'job-modal-questions');
  }

  ngOnInit() {
    if (this.jobId) {
      this.modal1 = true;
    }
    if (this.replyInfo) {
      this.modal2 = true;
    }
    if(this.editInfo) {
      this.content = this.editInfo.data.content;
      this.docuploader.queue = this.editInfo.data.docs;
      for (const docUploader of this.editInfo.data.docs) {
        const docId = docUploader.id;
        this.docArray.push(docId);
      }
      this.modal3 = true;
    }
    if (this.replyEditInfo) {
      this.modal2 = true;
      this.reply_content = this.replyEditInfo.data.content;

    }
  }

  async postQuestion() {
    gtag('event', 'question', {
      'event_category' : 'Job',
      'event_label' : 'job_question'
    });
    this.loader = true;
    for (const docupload of this.docuploader.queue) {
      const file: any = docupload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.docArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }
    const q = {
      content: this.content,
      doc_ids: this.docArray,
    };
    this.jobService.postQuestion(q, this.jobId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Question has been posted!');
        this.loader = false;
        this.sendService();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  removeDoc(value) {
    const index = this.docuploader.queue.indexOf(value.id);
    const index2 = this.docArray.indexOf(value.id);
    this.docuploader.queue.splice(index, 1);
    this.docArray.splice(index2, 1);
  }
  postReply() {
    gtag('event', 'reply', {
      'event_category' : 'Job',
      'event_label' : 'job_reply'
    });
    const q = {
      content: this.reply_content
    };
    const jobId = this.replyInfo.jobID;
    const qId = this.replyInfo.q_id;
    this.jobService.postReply(q, jobId, qId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.sendService();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );

  }
  updateReply() {
    const q = {
      content: this.reply_content
    };
    const jobId = this.replyEditInfo.jobID;
    const qId = this.replyEditInfo.q_id;
    const rId = this.replyEditInfo.data.id;

    this.jobService.updateReply(q, jobId, qId,rId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.sendService();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
 async updateQuestion() {
   
    this.loader = true;
    for (const docupload of this.docuploader.queue) {
      if (docupload.file) {
      const file: any = docupload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.docArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }
    }
    const q = {
      content: this.content,
      doc_ids: this.docArray,
    };
    this.jobService.updateQuestion(q, this.editInfo.jobID,this.editInfo.q_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Question has been updated!');
        this.loader = false;
        this.sendService();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  public onDocSelected(event: EventEmitter<File[]>) {
  }

  sendService(): void {
    this.questionModalService.sendMessage('success');
    this.activeModal.dismiss('Cross click');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'job-modal-questions');
  }
}
