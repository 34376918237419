import {Component, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {SigninComponent} from "../../auth/signin/signin.component";
import { ContactComponent } from 'src/app/dashboard/contact/contact.component';
import { PostTaskComponent } from '../post-task/post-task.component';

@Component({
  selector: 'app-community-guidelines',
  templateUrl: './community-guidelines.component.html',
  styleUrls: ['./community-guidelines.component.css']
})
export class CommunityGuidelinesComponent implements OnInit, OnDestroy {
  isSticky = false;
  toggleNavbar: any;
  user_info:any;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  constructor(private modalService: NgbModal, private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'about-index');
  }

  ngOnInit() {
    this.user_info = localStorage.getItem('aydo-app-user');
  }

  @HostListener('scroll', ['$event'])
  checkScroll(event) {
    if (event.target.scrollTop >= 50) {
      this.isSticky = true;
    }
    else {
      this.isSticky = false;
    }
  }
  getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      window.open( 
        "https://apps.apple.com/pk/app/aydo/id1548434919", "_blank");
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      window.open( 
        "https://apps.apple.com/pk/app/aydo/id1548434919", "_blank");
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      // os = 'Windows';
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    } else if (/Android/.test(userAgent)) {
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    } else  {
      window.open( 
        "https://play.google.com/store/apps/details?id=com.app.aydo", "_blank");
    }
  
  }
  openContactModal() {
    const modalRef = this.modalService.open(ContactComponent);
  }

  signInModal() {
    const modalRef = this.modalService.open(SigninComponent);
    modalRef.componentInstance.signin = 'signin';
  }
  taskModal() {
    const modalRef = this.modalService.open(PostTaskComponent,this.ngbModalOptions);
  }
  signUpModal() {
    const modalRef = this.modalService.open(SigninComponent);
    modalRef.componentInstance.signup = 'signup';
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'about-index');
  }
}
