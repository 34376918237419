// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// https://stg-api.aydo.app/api/
// https://test-api.aydo.app/api/

export const environment = {
  production: false,
  baseUrl: 'https://stg-api.aydo.app/api/',
  wssUrl:'wss://stg-api.aydo.app/cable',
  firebase: {
    apiKey: "AIzaSyB6LTjoTQrhguev36QTl1rHAD2CmCeTfJ0",
    authDomain: "aydo-235118.firebaseapp.com",
    databaseURL: "https://aydo-235118.firebaseio.com",
    projectId: "aydo-235118",
    storageBucket: "aydo-235118.appspot.com",
    messagingSenderId: "966471662835",
    appId: "1:966471662835:web:9c0b2ef6982d2025214b86",
    measurementId: "G-HN5007NSFP"
  }
};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
