import { Component, EventEmitter, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs/index";
import { PortfolioService } from "../../services/portfolio.service";
import { takeUntil } from "rxjs/internal/operators";
import { QuestionModalService } from "../../services/question-modal.service";
import { FileUploader } from "ng2-file-upload";
import { DocumentService } from "../../services/document.service";

@Component({
  selector: 'app-portfolio-modal',
  templateUrl: './portfolio-modal.component.html',
  styleUrls: ['./portfolio-modal.component.css']
})
export class PortfolioModalComponent implements OnInit, OnDestroy {
  @Input() portfolioInfo: any;
  @Input() jobAttachments: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  portfolioList: any[] = [];
  getIndex: any;
  portfolioDetail: any;
  lengthOfArray: any;
  p_id: any;
  userInfo: any;
  userId: any;
  portfolioUserId: any;
  modal1 = false;
  modal2 = false;
  modal3 = false;

  uploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',

  });
  loader = false;
  img_url: any;
  file_name: any;
  avatar_id_2: any;
  file_error: any;
  description: any;
  updatePortfolio: any;
  textDir: string = 'ltr';

  constructor(private renderer: Renderer2, private toaster: ToastrService, public activeModal: NgbActiveModal, private portfolioService: PortfolioService,
    private questionModalService: QuestionModalService, private documentService: DocumentService) {
    if (localStorage.getItem('aydoLang')) {

      if (localStorage.getItem('aydoLang') == 'ar') {
        this.textDir = 'rtl'
      }
      else {
        this.textDir = 'ltr'
      }
    }
    this.renderer.addClass(document.body, 'portfolio');
  }

  ngOnInit() {
    if (this.portfolioInfo) {
      this.modal1 = true;
      this.portfolioList = this.portfolioInfo.list;
      this.getIndex = this.portfolioInfo.index;
      this.portfolioUserId = this.portfolioInfo.userId;



    }
    if (this.jobAttachments) {
      this.modal3 = true;
      this.portfolioList = this.jobAttachments.list;
      this.getIndex = this.jobAttachments.index;
      this.portfolioUserId = this.jobAttachments.userId;

    }
    this.getPortfolio(this.getIndex);
    this.userInfo = localStorage.getItem('aydo-app-user');
    if (this.userInfo != null) {
      const user_info = JSON.parse(this.userInfo);
      this.userId = user_info.id;
    }

  }

  getPortfolio(index) {
    for (let i = 0; i < this.portfolioList.length; i++) {
      if (i === index) {
        this.portfolioDetail = this.portfolioList[i];
        this.p_id = this.portfolioList[i].id;
        this.getIndex = i;
      }
    }
    this.lengthOfArray = this.portfolioList.length;
  }

  delPortfolio() {
    this.portfolioService.delPortfolio(this.p_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Portfolio has been deleted');
        this.sendService();
      }
    );
  }

  editPortfolio(index) {
    this.modal1 = false;
    this.modal2 = true;
    for (let i = 0; i < this.portfolioList.length; i++) {
      if (i === index) {
        this.portfolioDetail = this.portfolioList[i];
        this.p_id = this.portfolioList[i].id;
        this.img_url = this.portfolioDetail.image.url;
        this.description = this.portfolioDetail.description;
        this.avatar_id_2 = this.portfolioDetail.image_id;
      }
    }
  }

  portfolioUpdate() {
    if (this.avatar_id_2 !== null) {
      this.updatePortfolio = {
        image_id: this.avatar_id_2,
        description: this.description
      };
    }
    else {
      this.updatePortfolio = {
        description: this.description
      };
    }
    this.portfolioService.portfolioUpdate(this.updatePortfolio, this.p_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.toaster.success('Portfolio has been updated Successfully!');
      this.sendService();
    },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }

  sendService(): void {
    this.questionModalService.sendMessage('success');
    this.activeModal.dismiss('Cross click');
  }

  public onFileSelected2(event: EventEmitter<File[]>) {
    this.loader = true;
    const file: File = event[0];
    this.file_name = file.name;
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          this.loader = false;
          this.avatar_id_2 = res['id'];
          this.img_url = res['url'];

        }, error => {
          console.log('changePicture error', error);
        });
    } else {
      this.file_error = 'Please Upload Only Images in jpeg and png format';
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'portfolio');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
