import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { Subject } from "rxjs/index";
import { takeUntil } from "rxjs/internal/operators";
import { ChatService } from "../../../../services/chat.service";
import { Subscription } from "rxjs";
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { QuestionModalService } from 'src/app/services/question-modal.service';

@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.css']
})
export class MessagesListComponent implements OnInit,OnDestroy {
  userDetail: any;
  send: any;
  channel: Channel;
  private ngUnsubscribe: Subject<any> = new Subject();
  subscription: Subscription;
  convList: any[] = [];
  pagination: any;
  total;
  totalPages: any;
  currentPage: any;
  timeStamp: any;
  moreAvailable: boolean;
  loader = false;
  wsUrl = environment.wssUrl;
  noMessagesList = false;
  query: any;
  convId:any;
  constructor(private cableService: ActionCableService, private convService: ChatService,private toastr:ToastrService,
    private questionModalService:QuestionModalService) {
    // this.questionModalService.getMessage().pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
    //   this.convId = data;
    // });
  }

  ngOnInit() {
    this.userDetail = localStorage.getItem('Authorization');
    this.channel = this.cableService
      .cable(this.wsUrl +'?token=' + this.userDetail)
      .channel('ConversationChannel');
    this.subscription = this.channel.received().subscribe(message => {
      this.convId = message.conversation_id;
      let msgId = message.conversation_id;
      let updateItem = this.convList.find(item => item.id === msgId);
      
      let index = this.convList.indexOf(updateItem);
      if(index != -1) {
      this.convList[index].last_message = message;
      //this.convList[index].last_message.created_at = message.created_at;
      if(this.convId != msgId) {
      this.convList[index].unread_count +=1;
      }
      let cutOut = this.convList.splice(index,1)[0];
      this.convList.splice(0,0,cutOut);
    }
    else {
      this.convList = [];
    this.getMyConv(null, null, null);

    }
      //  this.convList = [];
      // this.getMyConv('', '', '');
    });
    
    this.getMyConv(this.currentPage, this.timeStamp, this.query);
  }

  getMyConv(page,time,query) {
    this.loader = true;
    this.convService.getMyConv(page,time,query).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.pagination = data['page_info'];
        this.currentPage = this.pagination.page;
        this.timeStamp = this.pagination.timestamp;
        this.total = new Array(this.pagination.total_pages);
        this.totalPages = this.pagination.total_pages;
        this.moreAvailable = this.pagination.more_available;
       
          this.convList = this.convList.concat(data['conversations']);
          if(this.convList.length == 0) {
            this.noMessagesList = true;
          }
    
        this.loader = false;
      },
      error => {
        this.toastr.error(error.error.errors);
      }
    );
  }
  @HostListener('scroll', ['$event'])
  navigate(event) {
    if (event.target.offsetHeight + 10+ event.target.scrollTop >= event.target.scrollHeight) {
      if (this.moreAvailable === true) {
        this.currentPage++;
        this.getMyConv(this.currentPage, this.timeStamp,this.query);
      }
    }
  }
  updateItem(list) {
    let itemIndex = this.convList.findIndex(item => item.id == list.id);
    this.convList[itemIndex].unread_count = 0;
    this.convId = list.id;
    this.sendService(list.id);
  }
  SearchMessages(query: string) {
    this.query = query;
    this.applyFilters();
    if (this.query.length === 0) {
      this.query = null;
      this.applyFilters();
    }
  }
 sendService(id): void {
    this.questionModalService.sendMessage(id);
  }
  applyFilters() {
    
    this.convList = [];
    this.convService.getMyConv('', '', this.query)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.pagination = data['page_info'];
      this.currentPage = this.pagination.page;
      this.timeStamp = this.pagination.timestamp;
      this.total = new Array(this.pagination.total_pages);
      this.totalPages = this.pagination.total_pages;
      this.moreAvailable = this.pagination.more_available;
      this.convList = data['conversations'];
      this.loader = false;
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
