import {Component, EventEmitter, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DocumentService} from "../../../services/document.service";
import {JobService} from "../../../services/job.service";
import {ToastrService} from "ngx-toastr";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {QuestionModalService} from "../../../services/question-modal.service";
import {FileUploader} from "ng2-file-upload";
import {Subject} from "rxjs/index";
import {takeUntil} from "rxjs/internal/operators";
import { Router } from '@angular/router';
import { IdBadgesModalComponent } from '../../id-badges-modal/id-badges-modal.component';
import { SkillsComponent } from '../../skills/skills.component';
declare var gtag

@Component({
  selector: 'app-offers-modal',
  templateUrl: './offers-modal.component.html',
  styleUrls: ['./offers-modal.component.css']
})
export class OffersModalComponent implements OnInit, OnDestroy {
  textDir: string = 'ltr';
  @Input() jobId: any;
  @Input() replyInfo: any;
  @Input() editInfo: any;
  @Input() replyEditInfo:any;

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  content: any;
  reply_content: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  docuploader: FileUploader = new FileUploader({
    disableMultipart: false,
    itemAlias: 'attachment',
    queueLimit: 10,
    allowedFileType: ['pdf', 'image']

  });
  docArray: any[] = [];
  modal1 = false;
  modal2 = false;
  modal3 = false;
  loader = false;
  amount: any;
  min_job_budget:any;
  max_job_budget:any;
  budgetLimit = false;
  constructor(private router:Router,private jobService: JobService, private toaster: ToastrService, public activeModal: NgbActiveModal,
              private questionModalService: QuestionModalService, private renderer: Renderer2, private documentService: DocumentService,
              private modalService:NgbModal) {
                if(localStorage.getItem('aydoLang')) {
     
                  if(localStorage.getItem('aydoLang') == 'ar') {
                    this.textDir = 'rtl'
                  }
                  else {
                    this.textDir = 'ltr'
                  }
                }
    this.renderer.addClass(document.body, 'job-modal-questions');
  }

  ngOnInit() {
    if (this.jobId) {
      this.modal1 = true;
      this.checkCurrency(this.jobId.currency);
    }
    if (this.replyInfo) {
      this.modal2 = true;
    }
    if (this.editInfo) {
      const queue = this.editInfo.info.docs;
      this.docuploader.queue = queue;
      for (const docUploader of queue) {
        const docId = docUploader.id;
        this.docArray.push(docId);
      }
      this.content = this.editInfo.info.content;
      this.amount = this.editInfo.info.amount;
      this.modal3 = true;
      this.checkCurrency(this.editInfo.currency);

    }
    if (this.replyEditInfo) {
      this.modal2 = true;
      this.reply_content = this.replyEditInfo.data.content;

    }
  }
  checkCurrency(currency) {
    this.jobService.getAmmountLimit(currency).subscribe((data:any) => {
     this.min_job_budget = Math.floor(data.min_job_budget);
     this.max_job_budget = Math.floor(data.max_job_budget);
    })
  }
  checkLimit() {
    if(this.amount < this.min_job_budget || this.amount > this.max_job_budget) {
      this.budgetLimit = true;
    }
    else {
      this.budgetLimit = false;
    }
  }
  async postOffer() {
    gtag('event', 'post_offer', {
      'event_category' : 'Job',
      'event_label' : 'job_post_offer'
    });
    this.loader = true;
    for (const docupload of this.docuploader.queue) {
      const file: any = docupload.file.rawFile;
      await new Promise((resolve, reject) => {
        this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.docArray.push(res.id);
            resolve();
          }, error => {
            console.log('changePicture error', error);
          });
      });
    }
    const q = {
      content: this.content,
      doc_ids: this.docArray,
      amount: this.amount
    };
    this.jobService.postOffer(q, this.jobId.jobId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Offer has been posted!');
        this.loader = false;
        this.sendService();
      },
      error => {
        this.loader = false;

        // this.activeModal.dismiss('Cross click');
        if(error.error.code == 6) {
          const modelRef = this.modalService.open(SkillsComponent,this.ngbModalOptions);
           modelRef.componentInstance.detailJob = 'detail-job';
        }
        if(error.error.code == 5) {
          const modelRef = this.modalService.open(SkillsComponent,this.ngbModalOptions);
           modelRef.componentInstance.detailJob = 'detail-job';
        }
        if(error.error.code == 3) {
          this.router.navigate(['/edit-profile']);
        }
        if(error.error.code == 4) {
          const modelRef = this.modalService.open(IdBadgesModalComponent,this.ngbModalOptions);
          modelRef.componentInstance.detailJob = 'detail-job';

        }
        if(error.error.code == 7) {
          this.router.navigate(['/edit-profile'])
        }
        this.toaster.error(error.error.errors);
        
      }
    );
  }

  public onDocSelected(event: EventEmitter<File[]>) {
  }

  removeDoc(value) {
    const index = this.docuploader.queue.indexOf(value.id);
    const index2 = this.docArray.indexOf(value.id);
    this.docuploader.queue.splice(index, 1);
    this.docArray.splice(index2, 1);
  }

  async updateOffer() {
    this.loader = true;
    for (const docupload of this.docuploader.queue) {
      if (docupload.file) {
        const file: any = docupload.file.rawFile;
        await new Promise((resolve, reject) => {
          this.documentService.upload(file).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.docArray.push(res.id);
              resolve();
            }, error => {
              console.log('changePicture error', error);
            });
        });
      }
    }
    const q = {
      content: this.content,
      doc_ids: this.docArray,
      amount: this.amount
    };
    const offerId = this.editInfo.info.id;
    const jobId = this.editInfo.jobId;
    this.jobService.updateOffer(q, jobId, offerId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.toaster.success('Offer has been updated!');
        this.loader = false;
        this.sendService();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }

  postReply() {
    const q = {
      content: this.reply_content
    };
    const jobId = this.replyInfo.jobID;
    const offerId = this.replyInfo.offer_id;
    this.jobService.postOfferReply(q, jobId, offerId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.sendService();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );

  }
  updateReply() {
    const q = {
      content: this.reply_content
    };
    const jobId = this.replyEditInfo.jobID;
    const offerId = this.replyEditInfo.offer_id;
    const rId = this.replyEditInfo.data.id;

    this.jobService.updateOfferReply(q, jobId, offerId,rId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.sendService();
      },
      error => {
        this.toaster.error(error.error.errors);
      }
    );
  }
  sendService(): void {
    this.questionModalService.sendMessage('success-job');
    this.activeModal.dismiss('Cross click');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.renderer.removeClass(document.body, 'job-modal-questions');
  }
}
